import React from 'react';
import { useSelector } from 'react-redux';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import getProject from '@src/APIs/graphQl/Project/getProject';
import ProjectPage from '@src/App/NewLayout/Pages/Projects/ProjectPage/ProjectPage';

import Overlay from '../Overlay/Overlay';
import './ProjectOverlay.css';


interface ProjectOverlayProps
{
    projectId: number;
    children: React.ReactNode;
    disabled?: boolean;
}

/**
 * Renders a project overlay component.
 * 
 * @param {ProjectOverlayProps} props - The props for the project overlay.
 * @returns {JSX.Element} - The rendered project overlay component.
 */
function ProjectOverlay(props: ProjectOverlayProps)
{
    const project = useSelector((state: AppState) => state.projects.find(p => p.id === props.projectId));
    const permissions = useSelector((state: AppState) => state.permissions);
    const isAllowed = checkPermissions('Projects', 'Retrieve', permissions) || checkPermissions('Projects', 'RetrieveOwn', permissions);
    const disabled = !isAllowed || props.disabled;

    return (
        <Overlay
            disabled={disabled}
            triggerItem={props.children}
            loaded={project !== null}
            onFirstOpen={() =>
            {
                getProject(props.projectId);
            }}>
            {project && <ProjectPage
                entity={project}
                inDialog={true}
            />}
        </Overlay>
    );
}

export default ProjectOverlay;
