import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation setClockodoSync( $sync: Boolean!, $email: String!, $apiKey: String!) {
    setClockodoSync(sync: $sync, email: $email, apiKey: $apiKey) 
    ${companySettingsGql}
},
`;

export default async function setClockodoSync(clockodoSyncState: { sync: boolean, email: string, apiKey: string })
{
    const variables: OperationVariables = {
        ...clockodoSyncState
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c))
}