import React, { useContext, useEffect, useRef } from 'react';
import './Dialog.css';
import { Close, FilterNone, Fullscreen, Minimize } from '@mui/icons-material';
import MuiDialog from "@mui/material/Dialog"
import { EHelpId } from '@sharedInterfaces/Language/languageTexts/helpDialog';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
import { IconButton, Slide, Tooltip } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { useSelector } from 'react-redux';
import { DialogManagerContext, } from '@sharedReact/Dialog/DialogManager';
import { TransitionProps } from 'react-transition-group/Transition';

import HelpDialogButton from '../HelpDialog/HelpDialog';
interface DialogProps
{
    id: string
    children: React.ReactNode
    className?: string
    footer?: React.ReactNode
    title: string
    bigWidth?: true
    helpId?: EHelpId
    onClose: () => void
}

function Dialog(props: DialogProps)
{
    const {
        getDialogState,
        setDialogState,
        minimizeDialog,
        maximizeDialog,
        restoreDialog,
        focusDialog,
    } = useContext(DialogManagerContext);

    const dialogState = getDialogState(props.id);

    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).helpDialog;
    const modalBodyRef = useRef<HTMLDivElement>(null);

    useEffect(() =>
    {
        if (!dialogState) return;
        if (dialogState.title === props.title)
        {
            return;
        }
        setDialogState(props.id, { title: props.title })
    }, [dialogState]);

    const onMinimize = () =>
    {
        minimizeDialog(props.id);
    };
    const onMaximize = () =>
    {
        maximizeDialog(props.id);
    }
    const onRestore = () =>
    {
        restoreDialog(props.id);
    }
    const onFocusDialog = () =>
    {
        focusDialog(props.id);
    }
    const maxWidth = dialogState?.windowState === 'full' ? 'xl' : (!props.bigWidth ? 'lg' : 'xl');
    return (
        <MuiDialog
            open={dialogState?.windowState !== 'minimized'}
            maxWidth={maxWidth}
            className={[props.className, dialogState?.windowState === 'full' ? 'fullScreenDialog' : undefined].filter(d => d).join(' ')}
            fullWidth={props.bigWidth || dialogState?.windowState === 'full'}
            hideBackdrop={true}
            // onClose={onMinimize}
            TransitionComponent={Transition}
            style={{
                zIndex: dialogState ? dialogState.zIndex : 1302,
                margin: 0,
                borderRadius: 0,
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                pointerEvents: 'none',
                // backgroundColor: 'var(--var-secondary-color)',
                // boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2)',
            }}
            container={() => document.getElementById('dialogContainer')}
            PaperComponent={dialogState?.windowState === 'full' ? Paper : DraggablePaper}
            PaperProps={{
                style: {
                    margin: 16,
                    boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.4)',
                    border: '1px solid #333'
                    // borderRadius: 7,
                }
            }}
            slotProps={{
                backdrop: {
                    style: {
                        pointerEvents: 'none',
                    }
                },

            }}
            slots={{ backdrop: () => null }}
        >
            <div
                className='outerDialog'
                onMouseDown={onFocusDialog}
            >
                <div className="modal-header">
                    <h2 className="title">{props.title}</h2>
                    <div className='topRightButtons'>
                        {dialogState
                            ? <>
                                <Tooltip title={langStrings.minimize} className='no-drag'>
                                    <IconButton
                                        aria-label={langStrings.minimize}
                                        style={{ color: 'var(--var-on-secondary-color)' }}
                                        onClick={onMinimize}>
                                        <Minimize />
                                    </IconButton>
                                </Tooltip>
                                {dialogState.windowState === 'normal'
                                    ?
                                    <Tooltip title={langStrings.maximize} className='no-drag'>
                                        <IconButton
                                            aria-label={langStrings.maximize}
                                            style={{ color: 'var(--var-on-secondary-color)' }}
                                            onClick={onMaximize}>
                                            <Fullscreen />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title={langStrings.restore} className='no-drag'>
                                        <IconButton
                                            aria-label={langStrings.restore}
                                            style={{ color: 'var(--var-on-secondary-color)' }}
                                            onClick={onRestore}>
                                            <FilterNone />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </>
                            : undefined}
                        <Tooltip title={langStrings.close} className='no-drag'>
                            <IconButton
                                aria-label={langStrings.close}
                                style={{ color: 'var(--var-on-secondary-color)' }}
                                onClick={props.onClose}>
                                <Close />
                            </IconButton>
                        </Tooltip>
                        {props.helpId && (
                            <div className='help'>
                                <HelpDialogButton helpId={props.helpId} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="modal-body no-drag" ref={modalBodyRef}>
                    {props.children}
                </div>
                <div className="modal-footer">
                    <div className='no-drag' style={{ cursor: 'default' }}>
                        {props.footer}
                    </div>
                </div>
            </div>

        </MuiDialog >
    );
}

export default Dialog;

interface DraggablePaperProps extends PaperProps
{
    // focusDialog?: () => void
}
function DraggablePaper(props: DraggablePaperProps)
{
    return (
        <Draggable
            handle=".modal-header, .modal-footer"
            cancel={'.no-drag'}

        >
            <Paper {...props} />
        </Draggable>
    );
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {

        children: React.ReactElement<unknown, never>;
    },
    ref: React.Ref<unknown>,
)
{
    return <Slide direction="up" ref={ref} {...props} />;
});