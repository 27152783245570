import { ELanguage } from "../languageHelper";

import { oftenUsedTranslations } from "./oftenUsed"

export interface INavigationTexts
{
    stage: string;
    companies: string;
    emailSettings: string;
    roles: string;
    edit: string;
    show: string;
    employeeAndOEsLong: string;
    orgUnitsDescription: string;
    employeesDescription: string;
    employeeAndOEs: string;
    levelDefinitions: string;
    bugReport: string;
    businesses: string;
    certificates: string
    competences: string;
    editMyProfile: string;
    employees: string
    login: string;
    logout: string;
    myProfile: string
    newOpportunity: string
    opportunities: string
    orgUnits: string;
    pageNotFound: string;
    pageNotFoundDescription: string;
    products: string;
    projects: string;
    sales: string
    settings: string
    skillCategories: string;
    skills: string
    solutions: string;
}
type ILangData = {
    [lang in ELanguage]: INavigationTexts
}
export const navigationTexts: ILangData = {
    'th': {
        stage: 'เวที',
        emailSettings: 'ตั้งค่าอีเมล',
        employeeAndOEs: 'คน',
        edit: 'แก้ไข',
        show: 'แสดง',
        orgUnitsDescription: 'ตรวจสอบโครงสร้างและหน่วยงาน ที่นี่คุณจะได้รับภาพรวมเกี่ยวกับฝ่ายต่างๆและหน่วยงาน',
        employeesDescription: 'สำรวจโปรไฟล์พนักงานและข้อมูลรายละเอียด ที่นี่คุณจะพบกับรายชื่อพนักงานและรายละเอียดของพวกเขา',
        employeeAndOEsLong: 'บุคคล & หน่วยงาน',
        levelDefinitions: 'ข้อมูลระดับ',
        bugReport: 'รายงานข้อผิดพลาด',
        businesses: oftenUsedTranslations['th'].businesses,
        certificates: oftenUsedTranslations['th'].certificates,
        competences: oftenUsedTranslations['th'].competences,
        editMyProfile: 'แก้ไขโปรไฟล์',
        employees: oftenUsedTranslations['th'].employees,
        login: oftenUsedTranslations['th'].login,
        logout: 'ออกจากระบบ',
        myProfile: 'โปรไฟล์ของฉัน',
        newOpportunity: 'โอกาสใหม่',
        opportunities: oftenUsedTranslations['th'].opportunities,
        orgUnits: oftenUsedTranslations['th'].orgUnits,
        pageNotFound: 'ไม่พบหน้าที่คุณต้องการ',
        pageNotFoundDescription: 'ขออภัย, ไม่พบหน้าที่คุณพยายามเปิด :(',
        products: oftenUsedTranslations['th'].products,
        projects: oftenUsedTranslations['th'].projects,
        sales: 'การขาย',
        settings: 'การตั้งค่า',
        skillCategories: oftenUsedTranslations['th'].skillCategories,
        skills: oftenUsedTranslations['th'].skills,
        solutions: oftenUsedTranslations['th'].solutions,
        roles: oftenUsedTranslations['th'].roles,
        companies: oftenUsedTranslations['th'].companies,
    },
    'en': {

        stage: 'Stage',
        emailSettings: 'Email Settings',
        employeeAndOEs: 'People',
        edit: 'Edit',
        show: 'Show',
        orgUnitsDescription: 'View organizational structures and units. Here you get an overview of the different departments and organizational units.',
        employeesDescription: 'Explore employee profiles and their information. Here you will find a list of employees and their details.',
        employeeAndOEsLong: 'People & Organizational Units',
        levelDefinitions: 'Level Information',
        bugReport: 'Bug report',
        businesses: oftenUsedTranslations['en'].businesses,
        certificates: oftenUsedTranslations['en'].certificates,
        competences: oftenUsedTranslations['en'].competences,
        editMyProfile: 'Edit profile',
        employees: oftenUsedTranslations['en'].employees,
        login: oftenUsedTranslations['en'].login,
        logout: 'Log out',
        myProfile: 'My profile',
        newOpportunity: 'New request',
        opportunities: 'Requests',
        orgUnits: oftenUsedTranslations['en'].orgUnits,
        pageNotFound: 'Page not found',
        pageNotFoundDescription: 'Sorry, the page you tried to open was not found :(',
        products: oftenUsedTranslations['en'].products,
        projects: oftenUsedTranslations['en'].projects,
        sales: 'Sales',
        settings: 'Settings',
        skillCategories: oftenUsedTranslations['en'].skillCategories,
        skills: 'Skills',
        solutions: oftenUsedTranslations['en'].solutions,
        roles: oftenUsedTranslations['en'].roles,
        companies: oftenUsedTranslations['en'].companies,
    },
    'de': {
        businesses: oftenUsedTranslations['de'].businesses,
        certificates: oftenUsedTranslations['de'].certificates,
        competences: oftenUsedTranslations['de'].competences,
        editMyProfile: 'Profil bearbeiten',
        employees: oftenUsedTranslations['de'].employees,
        login: oftenUsedTranslations['de'].login,
        logout: 'Ausloggen',
        myProfile: 'Mein Profil',
        newOpportunity: 'Neue Anfrage',
        opportunities: oftenUsedTranslations['de'].opportunities,
        orgUnits: oftenUsedTranslations['de'].orgUnits,
        pageNotFound: 'Seite nicht gefunden',
        pageNotFoundDescription: 'Entschuldigung. Die Seite wurde nicht gefunden :(',
        products: oftenUsedTranslations['de'].products,
        projects: oftenUsedTranslations['de'].projects,
        sales: 'Vertrieb',
        settings: 'Einstellungen',
        skillCategories: oftenUsedTranslations['de'].skillCategories,
        skills: oftenUsedTranslations['de'].skills,
        solutions: oftenUsedTranslations['de'].solutions,
        bugReport: 'Fehlerbericht',
        levelDefinitions: 'Stufen-Informationen',
        employeeAndOEs: 'Menschen',
        employeeAndOEsLong: 'Personen & Organisationseinheiten',
        employeesDescription: 'Erkunde Mitarbeiterprofile und deren Informationen. Hier findest du eine Liste der Mitarbeiter und deren Details.',
        orgUnitsDescription: 'Betrachte die Organisationsstrukturen und Einheiten. Hier erhältst du einen Überblick über die verschiedenen Abteilungen und Organisationseinheiten.',
        show: 'Anzeigen',
        edit: 'Bearbeiten',
        roles: oftenUsedTranslations['de'].roles,
        emailSettings: 'E-Mail Einstellungen',
        companies: oftenUsedTranslations['de'].companies,
        stage: 'Stage',
    },
}