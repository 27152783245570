import { OperationVariables, gql } from "@apollo/client";
import { IStyleDTO } from "@sharedInterfaces/ICompanySettings";

import GraphQL from "../graphQL";

import { styleGql } from "./getCompanySettings";

const query = gql`
mutation setStyle($newStyle: StyleInput!) {
    setStyle(newStyle: $newStyle)
    ${styleGql}
},
`;

export default async function setStyle(newStyle: IStyleDTO)
{
    const variables: OperationVariables = { newStyle };
    return GraphQL.mutate<IStyleDTO>(query, variables);
}