import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import Product from '@src/Objects/Product';
import CardBox from '@src/Components/CardBox/CardBox';
import ProductDialog from '@src/Components/Dialogs/ProductDialog/ProductDialog';
import './ProductCard.css';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';

interface ProductCardProps
{
    product: Product
    selected?: boolean
    onSelectChange?: (state: boolean) => void
}

/**
 * Product card component.
 * 
 * @param {ProductCardProps} product - The product object.
 * @param {boolean} selected - The selection status for the product card.
 * @param {function} onSelectChange - The function to be called when the selection status changes.
 * @returns {JSX.Element} - The product card JSX element.
 */
function ProductCard({ product, selected, onSelectChange, }: ProductCardProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).products;
    const navigate = useNavigate();
    return (
        <CardBox
            menu={
                <div>
                    <MenuItem
                        onClick={() =>
                        {
                            return openDialog(
                                <ProductDialog
                                    id="ProductDialog"
                                    product={product}
                                />);
                        }}
                    >
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText>{langStrings.edit}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                        {
                            product.delete();
                        }}
                    >
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>{langStrings.delete}</ListItemText>
                    </MenuItem>
                </div>
            }
            onSelectChange={onSelectChange}
            selected={selected}
            title={product.title}
            className={'productCard'}
            onClick={() =>
            {

                // return;
                navigate(entityTypeToLink(product.id, EEntityType.PRODUCT))
            }}
        >
            <div>{langStrings.skillCoverage}: {product.skillCoverage}% </div>
            <ExpandableText>
                <>
                    {langStrings.description + ": "}
                    <FormatedTextInput value={product.description} readonly={true} />
                </>
            </ExpandableText>
        </CardBox>
    );
}

export default ProductCard;
