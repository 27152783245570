import React, { useContext, useEffect } from 'react';
import { Cancel, Chat, CopyAll, MailOutline, Send, Share } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, MenuItem, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IOrgUnitDetailDTO } from '@sharedInterfaces/IOrgUnit';
import { ISmallEmployee } from '@sharedInterfaces/IWhoIAm';
import { AppState } from '@store/store';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import { Entity } from '@sharedReact/Objects/Entity';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import EmployeesInput from '../../formsControls/inputs/EmployeesInput/EmployeesInput';
import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import { generateTextForEntity } from '../../../helper/generateTextForEntity';

import styles from './ShareButton.module.css';

interface ShareButtonProps
{
    entity: Entity;
}

/**
 * ShareButton component.
 * 
 * @param { entity } - The entity object.
 * @returns { JSX.Element } - The generated JSX element.
 */
export function ShareButton({ entity }: ShareButtonProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills.oftenUsed;

    const showDialog = () =>
        openDialog(
            <ShareDialog
                id={'ShareDialog'}
                entity={entity}
                resolve={() => { }}
            />
        );

    return (
        <>

            <IconButton title={langStrings.share} onClick={showDialog}>
                <Share />
            </IconButton>
        </>
    );
}

export function ShareMenuItem({ entity }: { entity: Entity })
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills.oftenUsed;

    return <>
        <MenuItem
            onClick={open}
        >
            <ListItemIcon>
                <Share />
            </ListItemIcon>
            <ListItemText>{langStrings.share}</ListItemText>
        </MenuItem></>

    function open(event: React.MouseEvent<HTMLElement>)
    {
        event.stopPropagation();
        event.preventDefault();
        openDialog(
            <ShareDialog
                id={'ShareDialog'}
                entity={entity}
                resolve={() => { }}
            />
        );
    }
}

enum EShareBy
{
    mail = 'email',
    teams = 'teams'
}
interface ShareDialogProps
{
    id: string
    entity: Entity;
    resolve: () => void;
}

/**
 * ShareDialog component.
 *
 * @param {object} close - An object with a "close" function of type "() => void".
 * @returns {JSX.Element} - The JSX Element representing the ShareDialog component.
 */
export function ShareDialog({ id, entity, resolve }: ShareDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills.oftenUsed;
    const link = `${window.location.origin}${entity.link}`;
    const allEmployees = useSelector((state: AppState) => state.company.allEmployees);
    const [employees, setEmployees] = React.useState<ISmallEmployee[]>([]);
    const [showDetails, setShowDetails] = React.useState(false);

    const [subject, setSubject] = React.useState('');
    const [body, setBody] = React.useState('');

    const [shareBy, setShareBy] = React.useState<'' | EShareBy>('');

    const [sending, setSending] = React.useState(false);


    useEffect(() =>
    {
        const sub = async () =>
        {
            const { subject, text } = await generateTextForEntity(entity, link);
            setSubject(subject);
            setBody(text);
        }
        sub();
    }, [entity]);

    const onShareByMail = () =>
    {
        // shareByEmail(entity, employees)
        setShareBy(EShareBy.mail)
        setShowDetails(true)
    }

    const onShareByTeams = () =>
    {
        // shareByTeams(entity, employees)
        setShareBy(EShareBy.teams)
        setShowDetails(true)
    }

    const onClose = () =>
    {
        resolve();
    }
    const onSend = async () =>
    {
        if (shareBy === EShareBy.mail)
        {
            setSending(true);
            await shareByEmail(employees, subject, body)
            setTimeout(() =>
            {
                setSending(false);
            }, 3000);
        }
        if (shareBy === EShareBy.teams)
        {
            setSending(true);
            await shareByTeams(employees, subject, body)
            setTimeout(() =>
            {
                setSending(false);
            }, 3000);
        }
        // onClose();
    }
    return (
        <Dialog
            id={id}
            footer={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        text={langStrings.cancel}
                        icon={<Cancel />}
                        onClick={onClose} />
                    {shareBy !== '' &&
                        <Button
                            text={langStrings.send}
                            disabled={sending}
                            icon={<Send />}
                            onClick={onSend} />
                    }
                </div>
            }
            title={`${langStrings.share} - ${entity.title}`}
            onClose={onClose}
        >
            <div style={{ minHeight: 160, minWidth: 'min(800px, 75vw)' }}>
                <RowElement title={langStrings.link}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={link}
                        disabled
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <IconButton title={langStrings.copy} onClick={copyToClipboard.bind(null, link)}>
                                    <CopyAll />
                                </IconButton>
                            ),
                        }}
                    />
                </RowElement>
                <div className={styles.iconContainer}>
                    <div
                        className={styles.button}
                        style={shareBy === EShareBy.mail ?
                            {
                                backgroundColor: 'var(--var-primary-color)',
                                color: 'var(--var-on-primary-color)',
                                borderRadius: 15,
                            }
                            : undefined
                        }
                        onClick={onShareByMail}
                    >
                        <MailOutline />
                        <Typography variant='subtitle1'>{langStrings.mail}</Typography>
                    </div>
                    <div
                        className={styles.button + (shareBy === EShareBy.teams ? ` ${styles.selected}` : '')}
                        style={shareBy === EShareBy.teams ?
                            {
                                backgroundColor: 'var(--var-primary-color)',
                                color: 'var(--var-on-primary-color)',
                                borderRadius: 15,
                            }
                            : undefined
                        }
                        onClick={onShareByTeams}
                    >
                        <img src="/icons/msTeams_outline.png" className={styles.actionIcon} />
                        <Typography variant='subtitle1'>{langStrings.msTeams}</Typography>
                    </div>
                </div>
                {showDetails &&
                    <>
                        <RowElement title={langStrings.shareWithText}>
                            <EmployeesInput allEmployees={allEmployees}
                                selectedEmployees={employees}
                                showMode={false}
                                size={'small'}
                                onChange={setEmployees} />
                        </RowElement>
                        <RowElement title={langStrings.subject} style={{ marginTop: 20 }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={subject}
                                onChange={(event) =>
                                {
                                    setSubject(event.target.value);
                                }}
                            />
                        </RowElement>
                        <RowElement title={langStrings.text} style={{ marginTop: 10 }}>
                            <TextField
                                fullWidth
                                multiline
                                variant="outlined"
                                value={body}
                                onChange={(event) =>
                                {
                                    setBody(event.target.value);
                                }}
                            />
                        </RowElement>
                    </>
                }
            </div>
        </Dialog>
    )
}


/**
 * This function copies the provided link to the clipboard.
 *
 * @param {string} link - The link to be copied to the clipboard.
 * 
 * @returns {void}
 */
function copyToClipboard(link: string) 
{
    navigator.clipboard.writeText(link);
}

/**
 * Generates a description string based on the provided HTML description.
 * 
 * @param {string} htmlDescription - The HTML description used to generate the description string.
 * @returns {string} The generated description string.
 */
export function generateDescription(htmlDescription: string, title: string = 'Beschreibung'): string
{
    if (htmlDescription !== "")
    {
        htmlDescription = htmlDescription
            .replace(/\u00A0/g, ' ')
            .replace(/&nbsp;/g, ' ')
            .replace(/\s+/g, ' ')
            .replace(/  +/g, ' ')
            .trim(); // Remove extra whitespace
        return `${title}:
${extractTextFromHTML(htmlDescription.length > 800 ? htmlDescription.substring(0, 800) + "..." : htmlDescription)}
`
    }
    return "";
}

/**
 * Function to share an entity by email.
 * 
 * @param entity - The entity to be shared.
 * @param employees - An array of employees to share the entity with.
 * 
 * @returns A promise that resolves when sharing is completed.
 */
async function shareByEmail(employees: ISmallEmployee[], subject: string, text: string)
{
    const emailAddresses = employees.map(e => e.email).join(',');
    //Ersetze alle Zeilenumbrücke durch %0D%0A
    const newText = text.replace(/\n/g, '%0D%0A')
        .replace(/\t/g, '%09')
        .replace(/&/g, '(UND)')
        .replace(/#/g, '(HASH)');
    window.location.href = `mailto:${emailAddresses}?subject=${subject.replace(/&/g, '(UND)')}&body=${newText}`;
}


/**
 * Share by teams.
 * 
 * @param entity - The entity object.
 * @param employees - The array of employee objects.
 * @returns A promise that resolves with void.
 */
async function shareByTeams(employees: ISmallEmployee[], subject: string, text: string) 
{

    const newText = text.replace(/&/g, '(UND)');
    const newSubject = subject.replace(/&/g, '(UND)');
    if (employees.length === 0)
    {
        return alert("Um eine Nachricht per Teams zu teilen muss mindestens 1 Mitarbeiter angegeben werden.");
    }
    const emailAddresses = employees.map(e => e.email).join(',');
    const url = `https://teams.microsoft.com/l/chat/0/0?users=${employees.length ? emailAddresses : ''}&topicName=${window.encodeURI(newSubject)}&message=${window.encodeURI(newText)}`
    const win = window.open(url, '_blank', 'width=600,height=400');
    if (win)
    {

        window.focus();
    } else
    {
        console.log('Das Popup wurde blockiert.');
    }
    setTimeout(function ()
    {
        if (win) win.close();
    }, 10000);
}

/**
 * Extracts text from HTML.
 * 
 * @param {string} html - The HTML string to extract text from.
 * @returns {string} The extracted text.
 */
function extractTextFromHTML(html: string): string
{
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || "";
}

/**
 * Check if the given object is an organization unit.
 * 
 * @param {any} obj - The object to check.
 * @returns {boolean} - Returns true if the object is an organization unit, otherwise returns false.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isOrgUnit(obj: any): obj is IOrgUnitDetailDTO
{
    return obj && typeof obj.leader === 'object' && typeof obj.leader.id === 'number';
}