import { Add, Cancel, ChevronRight, Delete, DragIndicator, Edit, ExpandMore, Insights, Save } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { IconButton, Typography } from '@mui/material';
import { cloneDeep } from '@apollo/client/utilities';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IGenerateSkillCategory } from '@sharedInterfaces/ISkillCategory';
import { AppState } from '@store/store';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import { setGenerateCategories } from '@src/APIs/graphQl/SkillCategory/generateCategories';
import { NewBadge } from '@src/App/NewLayout/Components/ItemBadge/ItemBadge';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import { ErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import { EWebsocketTopics } from '@sharedInterfaces/globalEnums';
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';
import { ISuggestCategorizeSkillsWebsocketRequest, TSuggestCategorizeSkillsWebsocketResponse } from '@sharedInterfaces/websocket/ISuggestCategorizeSkillsWebsocketEvents';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import Row from '../../../sharedReact/General/Forms/Row/Row';
import ErrorBox from '../../ErrorBox/ErrorBox';
import LoadingDialog from '../LoadingDialog/LoadingDialog';
import { AskForTextDialog } from '../AskForTextDialog/AskForTextDialog';

interface CreateCategorizationDialogProps
{
    id: string
    resolve?: () => void;
}
/**
 * Function to create a categorization dialog component.
 *
 * @returns {JSX.Element} The categorization dialog component.
 */
export function CreateCategorizationDialog({ id, resolve: onClose }: CreateCategorizationDialogProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang);
    const originalSkillCategories = useSelector((state: AppState) => state.company.skillCategories);

    const [skillCategories, setSkillCategories] = useState<IGenerateSkillCategory[]>(originalSkillCategories);
    const [draggedItemId, setDraggedItemId] = useState<number | null>(null);
    const [wishText, setWishText] = useState(langStrings.categorizeDialog.defaultCategorizeText as string);
    const [generating, setGenerating] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [expanded, setExpanded] = useState<string[]>([]);
    const [subKey, setSubKey] = React.useState<string>("");

    const expandAll = () =>
    {
        const allNodeIds = ["0", ...skillCategories.map(category => category.id.toString())];
        setExpanded(allNodeIds);
    };

    // const collapseAll = () =>
    // {
    //     setExpanded([]);
    // };
    useEffect(() =>
    {
        expandAll();
    }, []); // Abhängigkeit von skillCategories, damit es bei Änderungen aktualisiert wird

    const handleDragStart = (e: React.DragEvent, id: number | null) =>
    {
        e.stopPropagation();
        setDraggedItemId(id);
    };

    const handleDragOver = (e: React.DragEvent) =>
    {
        e.preventDefault(); // Erlaubt das Droppen durch Verhindern des Standardverhaltens
    };

    const handleDrop = (e: React.DragEvent, targetCategoryId: number | null) =>
    {
        e.stopPropagation();
        if (draggedItemId == null) return;
        if (draggedItemId === targetCategoryId) return;

        if (targetCategoryId !== null && isDescendant(draggedItemId, targetCategoryId, skillCategories))
        {
            console.log("Verschieben abgebrochen: Ziel ist ein Nachkomme des gezogenen Elements.");
            return;
        }

        if (targetCategoryId === null)
        {
            const updatedCategories = [...skillCategories];
            const draggedCategoryIndex = skillCategories.findIndex(cat => cat.id === draggedItemId);
            updatedCategories[draggedCategoryIndex] = {
                ...updatedCategories[draggedCategoryIndex],
                parentCategory: null
            }

            setSkillCategories(updatedCategories);
            return;
        }

        const draggedCategoryIndex = skillCategories.findIndex(cat => cat.id === draggedItemId);
        const targetCategoryIndex = skillCategories.findIndex(cat => cat.id === targetCategoryId);

        if (draggedCategoryIndex < 0 || targetCategoryIndex < 0) return;

        const updatedCategories = [...skillCategories];
        updatedCategories[draggedCategoryIndex] = {
            ...updatedCategories[draggedCategoryIndex],
            parentCategory: targetCategoryId
        }


        setSkillCategories(updatedCategories);
    };
    function getMillisecondsSinceMidnight()
    {
        const now = new Date();
        const midnight = new Date(now.setHours(0, 0, 0, 0));
        return Date.now() - midnight.getTime();
    }

    const renderActionButtons = (categoryId: number, mainLevel: boolean = false) =>
    {
        const hasChildren = skillCategories.some(category => category.parentCategory === categoryId);
        const handleAdd = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        {
            e.stopPropagation();

            openDialog(<AskForTextDialog
                id={'AskForTextDialog'}
                title={langStrings.categorizeDialog.editCategory}
                message={langStrings.categorizeDialog.pleaseEnterCategoryName}
                resolve={async (name) =>
                {
                    if (name && name !== "")
                    {
                        const updatedCategories = cloneDeep(skillCategories);
                        updatedCategories.push({
                            id: getMillisecondsSinceMidnight(),
                            title: name,
                            parentCategory: categoryId === 0 ? null : categoryId
                        })
                        setSkillCategories(updatedCategories);
                        setExpanded([...expanded, updatedCategories[updatedCategories.length - 1].id.toString()]);
                    }
                }}
            />);

        };

        const handleEdit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        {
            e.stopPropagation();
            const categoryIndex = skillCategories.findIndex(category => category.id === categoryId);
            openDialog(<AskForTextDialog
                id={'AskForTextDialog'}
                title={langStrings.categorizeDialog.editCategory}
                message={langStrings.categorizeDialog.pleaseEnterCategoryName}
                defaultValue={skillCategories[categoryIndex].title}
                resolve={async (name) =>
                {
                    if (!name) return;
                    const updatedCategories = cloneDeep(skillCategories);
                    if (categoryIndex >= 0)
                    {
                        updatedCategories[categoryIndex] = {
                            ...updatedCategories[categoryIndex],
                            title: name
                        }
                        setSkillCategories(updatedCategories);
                    }
                }}
            />);
        }
        const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, categoryId: number) =>
        {
            e.stopPropagation();
            if (hasChildren)
            {
                console.log('Löschen nicht möglich: Kategorie hat Unterkategorien.');
                return;
            }
            const updatedCategories = skillCategories.filter(category => category.id !== categoryId);
            setSkillCategories(updatedCategories);
        };
        return (
            <div>
                <IconButton onClick={handleAdd}>
                    <Add fontSize='small' />
                </IconButton>
                {!mainLevel &&
                    <>
                        <IconButton onClick={handleEdit}>
                            <Edit fontSize='small' />
                        </IconButton>
                        <IconButton
                            disabled={hasChildren}
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDelete(e, categoryId)}
                        >
                            <Delete fontSize='small' />
                        </IconButton>
                        <IconButton>
                            <DragIndicator fontSize='small' />
                        </IconButton>
                    </>}
            </div>
        );
    };

    const onSave = async () =>
    {
        setSaving(true);
        await setGenerateCategories(skillCategories)
            .then(() =>
            {
                onClose && onClose();
                setSaving(false);
            })
            .catch(ex =>
            {
                openDialog(<ErrorDialog id={'ErrorDialog'}
                    error={ex}
                    resolve={function ()
                    {
                        setTimeout(setSaving.bind(null, false), 2500)
                    }}
                />);
            });
    }
    const topic = EWebsocketTopics.suggestCategorizeSkills;
    function onGenerateCategories(): void
    {
        setGenerating(true);
        const request: ISuggestCategorizeSkillsWebsocketRequest = {
            wishText,
            skillCategories: skillCategories.map((c) => ({ title: c.title, id: c.id, parentCategory: c.parentCategory })),
            key: subKey,
        };
        WebSocketClient.sendMessage(topic, request);
    }
    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<TSuggestCategorizeSkillsWebsocketResponse>(topic, (data) =>
        {
            if (subKey !== data.key) return;
            if ('error' in data)
            {
                setErrorText(data.error)
                setTimeout(setGenerating.bind(null, false), 2500)
                return;
            }
            if (!data.skillCategories)
            {
                setErrorText(langStrings.error.oftenUsed.error)
                setTimeout(setGenerating.bind(null, false), 2500)
                return;
            }
            setGenerating(false);
            const categories = data.skillCategories;
            categories.sort((a, b) => a.title.localeCompare(b.title))
            setSkillCategories(categories);
            setErrorText("");
            setExpanded(["0", ...categories.map(r => r.id.toString())]);
        });
        setSubKey(subKey);
        return () =>
        {
            return WebSocketClient.unsubscripe(subKey)
        }
    }, [])



    return <Dialog
        id={id}
        title={langStrings.categorizeDialog.suggestCategoriesTitle}
        footer={
            <div style={{
                marginTop: 10,
                display: 'flex',
                gap: 10,
                justifyContent: 'space-between'
            }}>
                <Button
                    size={'normal'}
                    text={langStrings.categorizeDialog.cancel}
                    icon={<Cancel />}
                    onClick={() =>
                    {
                        onClose && onClose();
                    }}
                />
                <Button
                    size={'normal'}
                    text={langStrings.categorizeDialog.useAndClose}
                    icon={<Save />}
                    onClick={onSave}
                    disabled={generating || saving}
                />
            </div>
        }
        onClose={() =>
        {
            onClose && onClose();
        }}
    >
        <div>

            <RowElement title={langStrings.categorizeDialog.existingCategories}>
                {/* <IconButton onClick={expandAll} aria-label="Alle ausklappen">
                    <ExpandMore />
                </IconButton>
                <IconButton onClick={collapseAll} aria-label="Alle einklappen">
                    <ChevronRight />
                </IconButton> */}
                <TreeView
                    // key={skillCategories.length ? skillCategories[skillCategories.length - 1].id : 0}
                    style={{ width: '100%' }}
                    expanded={expanded}
                    onNodeToggle={(event, nodeIds) => setExpanded(nodeIds)}
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                // defaultExpanded={["0", ...skillCategories.map(category => category.id.toString())]}
                >
                    <TreeItem
                        nodeId="0"
                        key={0}
                        label={<div
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            draggable
                            onDragStart={(e) => handleDragStart(e, null)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, null)}
                        >
                            {langStrings.skillCategories.mainLevel}
                            {renderActionButtons(0, true)}
                        </div>}
                    >
                        <TreeRenderer
                            categories={skillCategories}
                            parentId={null}
                            handleDragStart={handleDragStart}
                            handleDragOver={handleDragOver}
                            handleDrop={handleDrop}
                            renderActionButtons={renderActionButtons}
                        />
                        {/* {renderTree(skillCategories, null)} */}
                    </TreeItem>
                </TreeView>
            </RowElement>
            <Row>
                <RowElement title={langStrings.categorizeDialog.yourAiWish} alignTitle='left'>
                    <TextInput value={wishText} onChange={function (val: string): void
                    {
                        setWishText(val);
                    }} />
                </RowElement>
                <Button
                    icon={<Insights />}
                    text={langStrings.categorizeDialog.generate}
                    size={'normal'}
                    onClick={onGenerateCategories}
                    disabled={generating || saving}
                />
            </Row>
            {errorText && <ErrorBox close={() => { setErrorText("") }}>{errorText}</ErrorBox>}
            <Typography variant='body2'>
                {langStrings.categorizeDialog.explainWishText}
            </Typography>
        </div>
        {(generating || saving) && <LoadingDialog />}
    </Dialog>;
}

interface TreeRendererProps
{
    categories: IGenerateSkillCategory[];
    parentId: number | null;
    handleDragStart: (e: React.DragEvent, id: number | null) => void
    handleDragOver: (e: React.DragEvent) => void
    handleDrop: (e: React.DragEvent, targetCategoryId: number | null) => void
    renderActionButtons: (categoryId: number, mainLevel?: boolean) => JSX.Element
}

/**
 * Renders a tree structure based on the provided props.
 * 
 * @param {TreeRendererProps} categories - The categories to render in the tree.
 * @param {string} parentId - The id of the parent node.
 * @param {Function} handleDragStart - The function to handle drag start event.
 * @param {Function} handleDragOver - The function to handle drag over event.
 * @param {Function} handleDrop - The function to handle drop event.
 * @param {Function} renderActionButtons - The function to render action buttons.
 * 
 * @returns {JSX.Element[]} An array of JSX elements representing the tree structure.
 */
function TreeRenderer({ categories, parentId, handleDragStart, handleDragOver, handleDrop, renderActionButtons }: TreeRendererProps)
{
    const smallSkillCategories = useSelector((state: AppState) => state.company.skillCategories);

    const categoriesToRender = categories
        .filter(category => category.parentCategory === parentId);
    // const [innerCategories, setInnerCategories] = useState(categories);

    // useEffect(() =>
    // {
    //     setInnerCategories(categories);
    // }, [categories])


    return <>
        {
            categoriesToRender
                .map(category =>
                {
                    const childCategories = categories.filter(c => c.parentCategory === category.id)
                    const originalCat = smallSkillCategories.find(sc => sc.id === category.id)
                    const modified = !originalCat
                        || originalCat.title !== category.title
                        || originalCat.parentCategory !== category.parentCategory;
                    return <TreeItem
                        key={categoriesToRender.length ? `${category.id}-${categoriesToRender[categoriesToRender.length - 1].id}` : category.id}
                        // key={category.id}
                        icon={childCategories.length ? undefined : <div style={{ width: 24 }} />}
                        // icon={<div style={{ width: 24 }} />}
                        nodeId={category.id.toString()}
                        label={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    fontWeight: modified ? 600 : 'initial',
                                    // color: modified ? 'var(--var-on-primary-color)' : '#000',
                                    // backgroundColor: modified ? 'var(--var-primary-color)' : 'rgb(255, 255, 255)',
                                }}
                                draggable
                                onDragStart={(e) => handleDragStart(e, category.id)}
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, category.id)}
                            >
                                <div style={{ display: 'flex', gap: 5 }}>
                                    {category.title}
                                    {modified && <NewBadge />}
                                </div>
                                {renderActionButtons(category.id)}
                            </div>
                        }
                    >
                        <TreeRenderer
                            categories={categories}
                            parentId={category.id}
                            handleDragStart={handleDragStart}
                            handleDragOver={handleDragOver}
                            handleDrop={handleDrop}
                            renderActionButtons={renderActionButtons}
                        />
                    </TreeItem>
                })
        }</>
}


const isDescendant = (parentId: number, childId: number, categories: IGenerateSkillCategory[]): boolean =>
{
    const parentCategory = categories.find(category => category.id === parentId);
    if (!parentCategory) return false;

    const children = categories.filter(category => category.parentCategory === parentId);
    for (const child of children)
    {
        if (child.id === childId || isDescendant(child.id, childId, categories))
        {
            return true;
        }
    }
    return false;
};