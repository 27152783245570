import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Settings, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeSuggestedCompetence } from '@sharedInterfaces/IEmployee';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import './SuggestedCompetences.css';
import { setHiddenCompetences } from '@store/reducer/employeeSettingsReducer';
import getSuggestedCompetences from '@src/APIs/graphQl/Employee/Suggestions/getSuggestedCompetences';
import hideSuggestedCompetence from '@src/APIs/graphQl/Employee/Suggestions/hideSuggestedCompetence';
import { DialogManagerContext, useDialog } from '@sharedReact/Dialog/DialogManager';

import listStyles from '../../App/NewLayout/Lists/List/List.module.css';
import CardBox from '../CardBox/CardBox';
import ExpandableText from '../ExpandableText/ExpandableText';
import FormatedTextInput from '../formsControls/inputs/FormatedTextInput/FormatedTextInput';
import Button from '../../sharedReact/General/Button/Button';
import { EmployeeCoverage } from '../Opportunities/EmployeeSuggestions/EmployeeCoverage';


import { ConfigureWantedCometenceDialog } from './ConfigureWantedCometenceDialog';
import { HiddenCompetencesDialog } from './HiddenCompetencesDialog';


/**
 * Represents the availability settings component.
 * 
 * @returns {JSX.Element}
 */
function SuggestedCompetences()
{
    const { openDialog } = useContext(DialogManagerContext)
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;
    const offline = useSelector((state: AppState) => state.client.offline);
    const [competences, setCompetences] = React.useState<IEmployeeSuggestedCompetence[]>([]);
    const [hiddenCompetencesDialog, setHiddenCompetencesDialog] = React.useState<boolean>(false);

    const updateSuggestions = () =>
    {
        getSuggestedCompetences((suggestedCompetences) =>
        {
            setCompetences(suggestedCompetences);
        });
    }

    React.useEffect(() =>
    {
        if (hiddenCompetencesDialog === false)
        {
            updateSuggestions();
        } else
        {
            openDialog(
                < HiddenCompetencesDialog
                    id="HiddenCompetencesDialog"
                    resolve={setHiddenCompetencesDialog.bind(null, false)} />
            )
        }

    }, [hiddenCompetencesDialog]);

    return (
        <div className='SuggestedCompetences'>
            <CardBox
                title={langStrings.suggestedCompetences}
                rightButtons={
                    <IconButton
                        onClick={setHiddenCompetencesDialog.bind(null, true)}
                        disabled={offline}
                    >
                        <Settings />
                    </IconButton>
                }
            >
                <Typography variant='subtitle2'>{langStrings.suggestionCardHelpText}</Typography>
                <Container>

                    {competences.length === 0 &&
                        <p className={listStyles.emptyListText}>{langStrings.oftenUsed.empty}</p>
                    }
                    {competences.map(cp =>
                        <SuggestedCompetence
                            key={cp.id}
                            competence={cp}
                            update={updateSuggestions}
                        />
                    )}
                </Container>
            </CardBox>
        </div>
    );
}
export default SuggestedCompetences;

/**
 * Generate a suggested competence element.
 * @param {competence} - The competence object.
 * @returns {JSX.Element} - The suggested competence element.
 */
export function SuggestedCompetence({ competence, update }:
    { competence: IEmployeeSuggestedCompetence, update: () => void })
{
    const { openDialog } = useContext(DialogManagerContext);
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const hiddenCompetences = useSelector((state: AppState) => state.employeeSettings.competenceSettings.hiddenCompetences);
    const wantedCompetences = useSelector((state: AppState) => state.employeeSettings.competenceSettings.wantedCompetences);
    const offline = useSelector((state: AppState) => state.client.offline);

    const allCompetence = allCompetences.find(a => a.id === competence.id)
    const onHide = () =>
    {
        hideSuggestedCompetence(competence.id);
        const newHiddenProjects = hiddenCompetences.concat();
        newHiddenProjects.push(competence.id);
        dispatch(setHiddenCompetences(newHiddenProjects));
    }
    const onConfigure = () =>
    {
        openDialog(<ConfigureWantedCometenceDialog
            id="ConfigureWantedCometenceDialog"
            competence={competence}
            resolve={() =>
            {
                update();
            }}
        />)
    }
    if (
        !allCompetence
        || hiddenCompetences.includes(competence.id)
        || wantedCompetences.find(wc => wc.id === competence.id && wc.level === competence.level)
    ) return <></>
    return (
        <CardBox
            title={`${competence.title} - ${allCompetence.levels[competence.level - 1].title}`}
            rightButtons={<IconButton
                title={langStrings.hideSuggestedCompetenceHelper}
                onClick={onHide}
                disabled={offline}
            >
                <VisibilityOff />
            </IconButton>}
        >
            <div className='SuggestedCompetence'>
                <ExpandableText extraHiddenChildren={
                    <div style={{ paddingBottom: 5 }}>
                        <EmployeeCoverage expended suggestedEmployee={competence.employee} hideName hideAvailability />
                    </div>
                }
                >
                    {
                        (competence.description && competence.description !== "") ?
                            <FormatedTextInput value={competence.description} readonly={true} /> :
                            undefined
                    }
                </ExpandableText>
                <div className='buttons'>
                    <Button
                        helpText={langStrings.hideSuggestedCompetenceHelper}
                        color={'normal'}
                        disabled={offline}

                        text={langStrings.hideSuggestedCompetence}
                        icon={<VisibilityOff />}
                        onClick={onHide} />
                    <Button
                        text={langStrings.configurate}
                        icon={<Settings />}
                        disabled={offline}
                        onClick={onConfigure} />
                </div>
            </div>
        </CardBox>
    )
}