import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import './CategorizeDialog.css';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import { BoxButton, BoxButtons } from '../../BoxButton/BoxButton';

import { CreateCategorizationDialog } from './CreateCategorizationDialog';
import { CategorizeDialog } from './CategorizeDialog';

interface CategorizeDialogMainProps
{
    id: string
    resolve: () => void;
}
/**
 * CategorizeDialog component.
 *
 * @param {CategorizeDialogMainProps} close - The props for closing the dialog.
 * @returns {JSX.Element} The rendered dialog element.
 */
function CategorizeDialogMain({ id, resolve: close }: CategorizeDialogMainProps)
{
    const { openDialog } = useContext(DialogManagerContext);

    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).categorizeDialog;

    return (
        <Dialog
            id={id}
            title={langStrings.dialogTitle}
            onClose={function (): void
            {
                close();
            }}
        >
            <BoxButtons>
                <BoxButton
                    title={langStrings.suggestCategoriesTitle}
                    description={langStrings.suggestCategoriesDescription}
                    onClick={() =>
                    {
                        openDialog(
                            <CreateCategorizationDialog
                                id="CreateCategorizationDialog"
                            />
                        )
                    }}
                />
                <BoxButton
                    title={langStrings.suggestCategorisationTitle}
                    description={langStrings.suggestCategorisationDescription}
                    onClick={() =>
                    {
                        openDialog(
                            < CategorizeDialog
                                id="CategorizeDialog"
                            />
                        )
                    }}
                />
            </BoxButtons>
        </Dialog>
    );
}
export default CategorizeDialogMain;