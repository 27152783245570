import React from 'react';
import { Done } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from '@apollo/client/utilities';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import IProduct, { IProductCompetenceReference, IProductEmployeeReference, IProductRoleReference } from '@sharedInterfaces/IProduct';
import { ISkillReferenceDTO } from '@sharedInterfaces/ISkill';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import { updateProduct } from '@store/reducer/productReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import Product from '@src/Objects/Product';
import createProduct from '@src/APIs/graphQl/Product/createProduct';
import LinkInput from '@src/Components/formsControls/inputs/LinkInput/LinkInput';
import EmployeeSelect from '@src/Components/formsControls/inputs/EmployeeSelect/EmployeeSelect';
import editProduct from '@src/APIs/graphQl/Product/editProduct';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import ErrorBox from '../../ErrorBox/ErrorBox';
import './ProductDialog.css';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import CertificatesInput from '../../formsControls/inputs/CertificatesInput/CertificatesInput';
import EmployeesInput from '../../formsControls/inputs/EmployeesInput/EmployeesInput';
import SkillsInput from '../../formsControls/inputs/SkillsInput/SkillsInput';
import CompetencesInput from '../../formsControls/inputs/CompetencesInput/CompetencesInput';
import FormatedTextInput from '../../formsControls/inputs/FormatedTextInput/FormatedTextInput';
import RolesInput from '../../formsControls/inputs/RolesInput/RolesInput';



interface ProductDialogProps
{
    id: string
    product?: Product
    resolve?: (val: Product | null) => void;
}

/**
 * Represents a product dialog.
 *
 * @param {ProductDialogProps} product - The product.
 * @param {function} onCreate - The handler for create.
 * @param {function} onEdit - The handler for edit.
 * @param {function} onClose - The handler for close.
 * @returns {JSX.Element} The product dialog element.
 */
function ProductDialog({ id, product, resolve }: ProductDialogProps)
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).products;
    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const allCertificates = useSelector((state: AppState) => state.company.allCertificates);
    const allEmployees = useSelector((state: AppState) => state.company.allEmployees);
    const allRoles = useSelector((state: AppState) => state.company.smallRoles);

    const permissions = useSelector((state: AppState) => state.permissions);
    const readRoles = checkPermissions('Roles', 'Retrieve', permissions);

    // const dispatch = useDispatch();
    const [errorText, setErrorText] = React.useState("" as string);
    const [saving, setSaving] = React.useState(false as boolean);

    const [productName, setProductName] = React.useState<string>(product ? product.title : '');
    const [description, setDescription] = React.useState<string>(product ? product.description : '');
    const [externalRessourceLink, setExternalRessourceLink] = React.useState<string>(product?.externalRessourceLink ? product.externalRessourceLink : '');
    const [productOwner, setProductOwner] = React.useState<number | undefined>(product?.productOwner?.id ? product.productOwner.id : undefined);
    const [competences, setCompetences] = React.useState<IProductCompetenceReference[]>((product ? product.competences : []));
    const [skills, setSkills] = React.useState<ISkillReferenceDTO[]>((product ? product.skills : []));
    const [certificates, setCertificates] = React.useState<{ title: string, id?: number }[]>((product ? product.certificates : []));
    const [employees, setEmployees] = React.useState<IProductEmployeeReference[]>((product ? product.employees : []));
    const [roles, setRoles] = React.useState<IProductRoleReference[]>((product ? product.roles : []));


    const onFetchError = (ex: { toString(): string }) =>
    {
        const error = ex.toString();
        setErrorText(error);
        setTimeout(() =>
        {
            setSaving(false);
        }, 3000);
    }
    const onFetch = (newProduct: IProduct) =>
    {
        const prod = new Product(newProduct);
        resolve && resolve(prod);
        setSaving(false);
        dispatch(updateProduct(prod));
    }

    const onSave = async () =>
    {
        setSaving(true);
        if (productName === "")
        {
            setErrorText(langStrings.newProductError);
            setTimeout(() =>
            {
                setSaving(false);
            }, 1500);
            return;
        }
        if (!product)
        {
            await createProduct(
                {
                    title: productName,
                    description,
                    skills,
                    certificates,
                    employees: employees,
                    competences,
                    roles,
                    externalRessourceLink,
                    productOwner,
                }
            )
                .then(onFetch)
                .catch(onFetchError)
        } else
        {
            await editProduct(
                {
                    id: product.id,
                    title: productName,
                    description,
                    skills,
                    certificates,
                    employees: employees,
                    competences,
                    roles,
                    externalRessourceLink,
                    productOwner,
                }
            )
                .then(onFetch)
                .catch(onFetchError)
        }

    };

    return (
        <Dialog
            id={id}
            title={product ? product.title : langStrings.newProduct}
            onClose={() =>
            {
                resolve && resolve(null)
            }}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Done />}
                        disabled={saving}
                        text={!product ? langStrings.create : langStrings.save}
                        size={'normal'}
                        onClick={onSave}
                    />
                </div>
            }
        >
            <div className="productDialog">
                <div className="newProduct">
                    <RowElement title={langStrings.productName} alignTitle="left">
                        <TextInput
                            value={productName}
                            onChange={setProductName} />
                    </RowElement>
                    <RowElement title={langStrings.description} alignTitle="left">
                        <FormatedTextInput
                            value={description}
                            onChange={setDescription}
                            placeholder={langStrings.descriptionPlaceholder}
                        />
                    </RowElement>
                    <RowElement title={langStrings.oftenUsed.productOwner} alignTitle="left">
                        <EmployeeSelect
                            employees={allEmployees}
                            selectedEmployee={productOwner}
                            setSelectedEmployee={setProductOwner}
                        />
                    </RowElement>
                    <RowElement title={langStrings.oftenUsed.externalRessource} alignTitle="left">
                        <LinkInput
                            value={externalRessourceLink}
                            size='small'
                            onChange={setExternalRessourceLink}
                        />
                    </RowElement>
                    {readRoles &&
                        <RowElement title={langStrings.oftenUsed.roles} alignTitle='left'>
                            <RolesInput
                                allRoles={allRoles}
                                selectedRoles={roles}
                                showMode={false}
                                size={'medium'}
                                onChange={roles => setRoles(roles)}
                            />
                        </RowElement>
                    }
                    <RowElement title={langStrings.oftenUsed.competences} alignTitle="left">
                        <CompetencesInput
                            showMode={false}
                            size='medium'
                            competences={cloneDeep(competences)}
                            onChange={competences => setCompetences(competences)} />
                    </RowElement>
                    <RowElement title={langStrings.skills} alignTitle="left">
                        <SkillsInput
                            showMode={false}
                            allowNewSkills={true}
                            size='medium'
                            skills={skills}
                            allSkills={allSkills}
                            competences={competences}
                            onChange={skills => setSkills(skills as ISkillReferenceDTO[])}
                        />
                    </RowElement>
                    <RowElement title={langStrings.certificates} alignTitle="left">
                        <CertificatesInput
                            allCertificates={allCertificates}
                            selectedCertificates={certificates}
                            showMode={false} size='medium'
                            competences={competences}
                            onChange={setCertificates}
                        />
                    </RowElement>
                    <RowElement title={langStrings.employees} alignTitle="left">
                        <EmployeesInput
                            selectedEmployees={employees}
                            allEmployees={allEmployees}
                            onChange={setEmployees} showMode={false} size={'medium'} />
                    </RowElement>
                </div>
                {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
            </div>
        </Dialog>
    );
}

export default ProductDialog;