import { OperationVariables, gql } from "@apollo/client";
import store from "@store/store";
import { setEmployeeRoles } from "@store/reducer/employeeReducer";
import { setCompanySmallRoles } from "@store/reducer/companyReducer";
import { cloneDeep } from "@apollo/client/utilities";

import GraphQL from "../graphQL";

const query = gql`
mutation createAndMapRole($title: String!) {
    createAndMapRole(title: $title) 
},
`;

/**
 * Creates and maps a role.
 *
 * @param {string} title - The title of the role.
 * @returns {Promise<number>} - A promise that resolves with the created role's ID.
 */
export default async function createAndMapRole(title: string)
{
    const variables: OperationVariables = {
        title,
    };
    return GraphQL.mutate<number>(query, variables).then(result =>
    {
        const state = store.getState();
        const newRoles = cloneDeep(state.employee.roles);
        newRoles.push({ id: result, title: title });
        store.dispatch(setEmployeeRoles(newRoles))
        const newAllRoles = [...state.company.smallRoles];
        newAllRoles.push({ title: title, id: result, otherNames: [], version: 0 });
        store.dispatch(setCompanySmallRoles(newAllRoles))
        return result;
    });
}
