import { Merge } from '@mui/icons-material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import ISkillDetailDTO from '@sharedInterfaces/ISkill';
import { AppState } from '@store/store';
import { setEmployeeSkills } from '@store/reducer/employeeReducer';
import { setCompanyAllSkills } from '@store/reducer/companyReducer';
import { setSkills } from '@store/reducer/skillReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import SkillDetail from '@src/Objects/SkillDetail';
import mergeSkills from '@src/APIs/graphQl/Skill/mergeSkills';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import ErrorBox from '../../ErrorBox/ErrorBox';

import './MergeSkillsDialog.css';
interface MergeSkillsDialogProps
{
    id: string
    skills: SkillDetail[]
    resolve: () => void
    skillsWithoutFilter: SkillDetail[]
}

/**
 * Component representing a dialog for merging skills.
 *
 * @param {MergeSkillsDialogProps} props - The properties for the MergeSkillsDialog.
 * @returns {JSX.Element} - The JSX element for the MergeSkillsDialog.
 */
function MergeSkillsDialog(props: MergeSkillsDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;

    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const employee = useSelector((state: AppState) => state.employee);

    const skills = props.skills;
    const uniqueEmployees = {} as { [key: number]: { title: string, id: number, skills: { title: string, level: number }[] } }
    skills.forEach((skillDetail) =>
    {
        skillDetail.employees.forEach((employee) =>
        {
            if (uniqueEmployees[employee.id] === undefined)
                uniqueEmployees[employee.id] = {
                    title: employee.title,
                    id: employee.id,
                    skills: [{ title: skillDetail.title, level: employee.level }]
                };
            else
                uniqueEmployees[employee.id].skills.push({ title: skillDetail.title, level: employee.level });
        });
    });
    const employees = Object.values(uniqueEmployees);
    let suggestedSkill: ISkillDetailDTO = skills[0]; // Assume the first skill has the most employees and highest level
    skills.forEach((skill) =>
    {
        if (skill.employees.length > suggestedSkill.employees.length ||
            (skill.employees.length === suggestedSkill.employees.length &&
                Math.max(...skill.employees.map((e) => e.level)) > Math.max(...suggestedSkill.employees.map((e) => e.level))))
        {
            suggestedSkill = skill;
        }
    });

    const dispatch = useDispatch();
    const [newSkillName, setNewSkillName] = React.useState(suggestedSkill.title as string);
    const [newDescription, setNewDescription] = React.useState(suggestedSkill.description as string);
    const [errorText, setErrorText] = React.useState("" as string);
    const [saving, setSaving] = React.useState(false as boolean);

    if (!employee)
    {
        return null
    }
    return (
        <Dialog id={props.id} title={langStrings.mergeSkills + ': ' + skills.map(s => s.title).join(', ')} onClose={function (): void
        {
            props.resolve();
        }}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Merge />}
                        disabled={saving}
                        text={langStrings.merge}
                        size={'normal'}
                        onClick={async function (): Promise<void>
                        {
                            setSaving(true);
                            await mergeSkills(
                                {
                                    id: suggestedSkill.id,
                                    title: newSkillName,
                                    description: newDescription
                                },
                                skills.map(s => s.id)
                            ).catch(ex =>
                            {
                                setErrorText(langStrings.error + " " + ex.toString());
                                setTimeout(() =>
                                {
                                    setSaving(false);
                                }, 2500);
                            }).then((result) =>
                            {
                                if (!result)
                                {
                                    setErrorText(langStrings.error);
                                    return;
                                }
                                setErrorText("");
                                setSaving(false);

                                const newAllSkills = allSkills.map(skill =>
                                    skill.id === result.newSkill.id ?
                                        {
                                            ...skill,
                                            title: result.newSkill.title,
                                            otherNames: result.otherNames
                                        } : skill
                                );

                                // Use filter to create a new array for newEmployeeSkills
                                const newEmployeeSkills = employee.skills.filter(skill => !result.removed.includes(skill.id))
                                    .map(skill => skill.id === result.newSkill.id ?
                                        {
                                            ...skill,
                                            title: result.newSkill.title,
                                            level: result.newSkill.employees.find(e => e.id === employee.id)?.level || 1
                                        }
                                        : skill);

                                const newSkills = props.skillsWithoutFilter.filter(skill => !result.removed.includes(skill.id))
                                    .map(skill => skill.id === result.newSkill.id ? { ...skill, title: result.newSkill.title, description: result.newSkill.description, employees: result.newSkill.employees } : skill);

                                // Update the state with new arrays
                                dispatch(setCompanyAllSkills(newAllSkills));
                                dispatch(setEmployeeSkills(newEmployeeSkills));
                                dispatch(setSkills(newSkills.map(ns => new SkillDetail(ns))))
                                props.resolve();
                            })
                        }}
                    />
                </div>
            }
        >
            <div className="mergeBox">
                <span>{langStrings.skillsSelectedForMerge}:</span>
                <ul>
                    {skills.map(skill => { return <li key={skill.id}>{skill.title}</li> })}
                </ul>
                <span>{langStrings.effectEmployeesWarning} {employees.length} {langStrings.employees}:</span>
                <ul>
                    {employees.map(emp =>
                    {
                        return <li key={emp.id}>{emp.title}
                            <ul>
                                {emp.skills.map(skill => <li key={skill.title}>{skill.title + " - " + skill.level}</li>)}
                            </ul>
                        </li>
                    })}
                </ul>
                <h2>{langStrings.newSkill}</h2>
                <div className="newSkill">
                    <RowElement title={langStrings.skill} alignTitle="left">
                        <TextInput value={newSkillName} onChange={s => setNewSkillName(s)} />
                    </RowElement>
                    <RowElement title={langStrings.description} alignTitle="left">
                        <TextInput value={newDescription} onChange={s => setNewDescription(s)} />
                    </RowElement>
                    <p>{langStrings.descriptionGeneratesAutomaticly}</p>
                </div>
                {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
            </div>
        </Dialog>
    );
}

export default MergeSkillsDialog;