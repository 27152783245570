import React from 'react';
import { IReference } from '@sharedInterfaces/IReference';
import './BusinessSelect.css';

interface BusinessSelectProps
{
    businesses: IReference[]
    selectedBusiness: number
    disabled?: boolean;
    setSelectedBusiness: (val: IReference) => void
}

/**
 * Renders a business select component.
 *
 * @param {BusinessSelectProps} props - The props for the business select component.
 * @returns {JSX.Element} The rendered business select component.
 */
function BusinessSelect(props: BusinessSelectProps)
{
    const businesss = [...props.businesses];
    businesss.sort((a, b) => a.title.localeCompare(b.title))
    return (
        <div className="businessSelect">
            <select
                disabled={props.disabled}
                value={props.selectedBusiness}
                onChange={(e) =>
                {
                    const business = businesss.find(b => b.id === parseInt(e.target.value));
                    props.setSelectedBusiness(business ? business : { id: -1, title: '' })
                }}
            >
                {props.selectedBusiness === -1 && <option value={-1}></option>}

                {props.businesses.map(e => <option
                    key={e.id}
                    value={e.id}
                >
                    {e.title}
                </option>)}
            </select>
        </div>
    );
}

export default BusinessSelect;


