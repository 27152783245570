import React, { CSSProperties, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeCoverage } from '@sharedInterfaces/IOpportunity';
import { EEntityType, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import { IconButton, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { EmployeePicture } from '@src/Components/EmployeePicture/EmployeePicture';
import RoleCompare from '@src/Components/RoleCompare/RoleCompare';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import SkillsLevelCompare from '../../SkillsLevelCompare/SkillsLevelCompare';
import CertificateCompare from '../../CertificateCompare/CertificateCompare';

import styles from './EmployeeCoverage.module.css';

interface EmployeeSuggestionProps
{
    hideAvailability?: true
    hideName?: true
    suggestedEmployee: IEmployeeCoverage;
    inDialog?: true
    expended?: true
    rightTopButton?: (id: number) => JSX.Element
    handleDragStart?: (event: React.DragEvent<HTMLDivElement>, item: IEmployeeCoverage | null) => void;
    handleDragEnd?: (event: React.DragEvent<HTMLDivElement>) => void;
}



/**
 * Generates the employee coverage.
 *
 * @name EmployeeCoverage
 * @param {EmployeeSuggestionProps} params 
 *        The parameters for generating the employee coverage.
 *      - {rightTopButton} {boolean} - Determines if the right top button should be displayed.
 *      - {hideAvailability} {boolean} - Determines if the availability should be hidden.
 *      - {hideName} {boolean} - Determines if the name should be hidden.
 *      - {suggestedEmployee} {inDialog} - The suggested employee for the coverage.
 *      - {handleDragStart} {Function} - The function to handle the drag start event.
 *      - {handleDragEnd} {Function} - The function to handle the drag end event.
 * @returns {JSX.Element} The generated employee coverage.
 */
export function EmployeeCoverage({ rightTopButton, hideAvailability, hideName, suggestedEmployee, inDialog, expended, handleDragStart, handleDragEnd }: EmployeeSuggestionProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).sales;
    const allEmployees = useSelector((state: AppState) => state.company.allEmployees);

    const windowSize = useSelector((state: AppState) => state.windowSize);

    const [isExpanded, setExpanded] = React.useState(expended ? true : false);

    const [outerHeaderHeight, setOuterHeaderHeight] = React.useState(70);

    const [outerWidth, setOuterWidth] = React.useState(0);


    const outerHeaderRef = useRef<HTMLDivElement>(null);
    const employeeSuggestionRef = useRef<HTMLDivElement>(null);
    const thisAllEmployee = allEmployees.find(e => e.id === suggestedEmployee.id);
    const availabilityObj = thisAllEmployee?.availability;
    const availability = (!availabilityObj ||
        (
            availabilityObj.availability === 0 &&
            availabilityObj.workDayCount === 0
        )
    ) ? null : Math.round(availabilityObj.availability);

    const availabilityText = !availability ? 'N/A' : `${availability}%`;


    const emp = suggestedEmployee;
    useEffect(() =>
    {
        if (outerHeaderRef.current)// && employeeSuggestionRef.current)
        {
            setOuterHeaderHeight(outerHeaderRef.current.scrollHeight);
        }
        if (employeeSuggestionRef.current)
        {
            setOuterWidth(employeeSuggestionRef.current.offsetWidth);
        }
    }, [outerHeaderRef, employeeSuggestionRef, windowSize, windowSize.width]);



    const nameSplit = thisAllEmployee ? thisAllEmployee.title.split(' ') : [];
    const firstName = nameSplit.length > 0 ? nameSplit[0] : '';
    const lastName = nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : '';
    const coveredSkills = [...emp.coveredSkills];
    const missingSkills = [...emp.missingSkills];

    const certScore = Math.round(
        emp.coveredCertificates.length /
        (emp.coveredCertificates.length + emp.missingCertificates.length) * 100
    );

    const roleScore = (!emp.coveredRoles || !emp.missingRoles) ? null : Math.round(
        emp.coveredRoles.length /
        (emp.coveredRoles.length + emp.missingRoles.length) * 100
    );
    // coveredSkills.sort((a, b) => b.wantedLevel - a.wantedLevel);
    // missingSkills.sort((a, b) => b.wantedLevel - a.wantedLevel);

    const size = outerWidth > 500 ? styles.big : (outerWidth > 400 ? styles.normal : styles.small)
    return <div
        className={`${styles.employeeSuggestionOuter} ${size}`}
        style={{ '--percent': emp.score * 100, } as CSSProperties}
    >
        <div
            ref={employeeSuggestionRef}
            className={styles.employeeSuggestion + (isExpanded ? ` ${styles.expanded}` : '')}
            draggable
            style={{

                maxHeight: isExpanded ? employeeSuggestionRef.current?.scrollHeight : outerHeaderHeight,
            } as CSSProperties}
            onDragStart={(event) => { handleDragStart && handleDragStart(event, emp) }}
            onDragEnd={handleDragEnd}
        >
            <div
                ref={outerHeaderRef}
            >
                {rightTopButton && rightTopButton(emp.id)}
                <div className={styles.header}>
                    {!hideName &&
                        <div className={styles.titleLine}>
                            {thisAllEmployee && <EmployeePicture
                                avatarSize={45}
                                openDialogOnClick={true}
                                employee={{
                                    firstName: firstName,
                                    lastName: lastName,
                                    pictureId: thisAllEmployee.pictureId,
                                    id: thisAllEmployee.id,
                                }} />}
                            <div className={styles.suggestionTitle}>
                                <NavLink to={entityTypeToLink(emp.id, EEntityType.EMPLOYEE)}>
                                    <Typography variant='h5'>
                                        {emp.title}
                                    </Typography>
                                </NavLink>
                            </div>
                        </div>
                    }
                    <div className={styles.suggestionInfo}>
                        <table border={0} cellSpacing="0" cellPadding="0" className={styles.headerInfoTable}>
                            <tbody>
                                {(roleScore !== null && (emp.coveredRoles.length || emp.missingRoles.length)) ?
                                    <tr className={styles.headerInfo} title={langStrings.roleScoreHint}>
                                        <td>
                                            <Typography variant='body2'>
                                                {langStrings.roleScore}:
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant='body2'>
                                                {roleScore}%
                                            </Typography>
                                        </td>
                                    </tr>
                                    : <></>
                                }
                                {(emp.coveredSkills.length || emp.missingSkills.length) ? <>
                                    <tr className={styles.headerInfo} title={langStrings.skillScoreHint}>
                                        <td>
                                            <Typography variant='body2'>
                                                {langStrings.skillScore}:
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant='body2'>
                                                {Math.round(emp.score * 100)}%
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr className={styles.headerInfo} title={langStrings.skillCoverageHint}>
                                        <td>
                                            <Typography variant='body2'>
                                                {langStrings.skillCoverage}:
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant='body2'>
                                                {Math.round(emp.skillCoverage * 100)}%
                                            </Typography>
                                        </td>
                                    </tr>
                                </>
                                    : undefined
                                }
                                {(emp.coveredCertificates.length || emp.missingCertificates.length) ?
                                    <tr className={styles.headerInfo} title={langStrings.certificatesHint}>
                                        <td>
                                            <Typography variant='body2'>
                                                {
                                                    langStrings.certificateScore}:
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant='body2'>
                                                {
                                                    certScore
                                                }
                                                %
                                            </Typography>
                                        </td>
                                    </tr>
                                    : undefined
                                }
                                {!hideAvailability &&
                                    <tr className={styles.headerInfo} title={langStrings.availabilityHint}>
                                        <td>
                                            <Typography variant='body2' fontWeight={availability && availability > 80 ? 600 : undefined}>
                                                {langStrings.availability}:
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant='body2' fontWeight={availability && availability > 80 ? 600 : undefined}>
                                                {availabilityText}
                                            </Typography>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={styles.skills}>
                {emp.coveredRoles.length > 0 &&
                    <div className={styles.skillsInner} title={langStrings.coveredRolesHint}>
                        <RowElement title={langStrings.coveredRoles} alignTitle={'center'}>
                            <RoleCompare size="medium" inDialog={inDialog} roles={emp.coveredRoles} />
                        </RowElement>
                    </div>
                }
                {emp.missingRoles.length > 0 &&
                    <div className={styles.skillsInner} title={langStrings.missingRolesHint}>
                        <RowElement title={langStrings.missingRoles} alignTitle={'center'}>
                            <RoleCompare size="medium" negative={true} inDialog={inDialog} roles={emp.missingRoles} />
                        </RowElement>
                    </div>
                }


                {coveredSkills.length > 0 &&
                    <div className={styles.skillsInner} title={langStrings.coveredSkillsHint}>
                        <RowElement alignTitle={'center'} title={langStrings.coveredSkills}>
                            <SkillsLevelCompare employeeId={thisAllEmployee?.id} size="medium" inDialog={inDialog} skills={emp.coveredSkills} />
                        </RowElement>
                    </div>}
                {missingSkills.length > 0 &&
                    <div className={styles.skillsInner} title={langStrings.missingSkillsHint}>
                        <RowElement title={langStrings.missingSkills} alignTitle={'center'}>
                            <SkillsLevelCompare employeeId={thisAllEmployee?.id} size="medium" inDialog={inDialog} skills={emp.missingSkills} />
                            {/* <SkillsInput size="small" showMode={true} skills={missingSkills} onChange={s => { }} /> */}
                        </RowElement>
                    </div>}
                {emp.coveredCertificates.length > 0 &&
                    <div className={styles.skillsInner} title={langStrings.coveredCertificatesHint}>
                        <RowElement title={langStrings.coveredCertificates} alignTitle={'center'}>
                            <CertificateCompare size="medium" inDialog={inDialog} certificates={emp.coveredCertificates} />
                        </RowElement>
                    </div>
                }
                {emp.missingCertificates.length > 0 &&
                    <div className={styles.skillsInner} title={langStrings.missingCertificatesHint}>
                        <RowElement title={langStrings.missingCertificates} alignTitle={'center'}>
                            <CertificateCompare size="medium" negative={true} inDialog={inDialog} certificates={emp.missingCertificates} />
                        </RowElement>
                    </div>
                }
            </div>

        </div>
        <div className={styles.expandOuter}>
            <IconButton
                size='small'
                className={styles.expand}
                aria-label="expand"
                onClick={() => { setExpanded(!isExpanded) }}
            >
                {isExpanded ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
            </IconButton>
        </div>
    </div>;
}
