import './CompetencesPage.css';
import React, { useContext, useEffect, useState } from 'react';
import { Add, TableChart, TableRows } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import { textContainsLowerCase } from '@src/Objects/Helper';
import Competence from '@src/Objects/Competence';
import CompetenceOverlay from '@src/Components/Overlays/CompetenceOverlay/CompetenceOverlay';
import { CompetenceDialog } from '@src/Components/Dialogs/CompetenceDialog/CompetenceDialog';
import EnhancedTable from '@src/App/NewLayout/Components/DataTable/DataTable';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import getCompetences from '@src/APIs/graphQl/Competence/getCompetences';
import CompetenceCard from '@src/App/NewLayout/Cards/Competences/CompetenceCard/CompetenceCard';

import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';
import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';


/**
 * Renders the CompetencesPage component.
 *
 * @returns {JSX.Element}
 */
function CompetencesPage()
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const entities = useSelector((state: AppState) => state.competences);
    const langStrings = getLanguageTexts(lang).competence;
    const permissions = useSelector((state: AppState) => state.permissions);
    const emp = useSelector((state: AppState) => state.employee);
    const [selected, setSelected] = useState<number[]>([]);
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    const isDense = (width: number) => width <= 1000

    useEffect(() =>
    {
        getCompetences().catch((ex) =>
        {
            setLoadingError(ex);
        })
    }, []);


    const types: IViewType<Competence>[] = [
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            minWidth: 400,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        id={EEntityType.COMPETENCE}
                        dense={isDense(width)}
                        noCheckbox={true}
                        selected={selected}
                        setSelected={setSelected}
                        order='desc'
                        orderBy='employees'
                        fields={[{
                            id: 'id',
                            label: 'ID',
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'string',
                        },
                        {
                            id: 'title',
                            label: langStrings.competenceName,
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.COMPETENCE}/`,
                        },
                        {
                            id: 'levels',
                            label: langStrings.levels,
                            disablePadding: isDense(width),
                            align: 'center',
                            type: 'string',
                        },
                        {
                            id: 'description',
                            label: langStrings.description,
                            disablePadding: isDense(width),
                            align: 'left',
                            minWidth: 1000,
                            type: 'description',
                        },
                        {
                            id: 'employees',
                            label: langStrings.employees,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 435,
                            type: 'string',
                        },
                        ]}
                        rows={
                            innerEntries.map(entity => ({
                                id: entity.id,
                                title: {
                                    value: <CompetenceOverlay
                                        competenceId={entity.id}
                                    >
                                        {entity.title}
                                    </CompetenceOverlay>,
                                    orderKey: entity.title
                                },
                                levels: entity.levels.length,
                                description: entity.description,
                                employees: entity.employees.length,
                            }
                            ))
                        } />

            ,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <CompetenceCard
                            key={entity.id}
                            competence={entity}
                        />)
                }
            </Container>,
        }
    ];

    const actions: IEntitiesPageAction[] = [
        {
            id: 'create',
            text: langStrings.newCompetence,
            icon: <Add />,
            filter()
            {
                if (!checkPermissions('Competencies', 'Add', permissions))
                    return false;
                return true;

            },
            action()
            {
                return openDialog(<CompetenceDialog id="CompetenceDialog" />);
            },
        }
    ];
    return (
        <EntitiesPage
            title={langStrings.competences}
            entities={entities}
            loadingError={loadingError}
            selected={selected}
            setSelected={setSelected}
            views={[
                {
                    id: 'all', title: langStrings.oftenUsed.allEntries,
                    filter()
                    {
                        return true;
                    },
                },
                {
                    id: 'my', title: langStrings.oftenUsed.myEntries,
                    filter(entity)
                    {
                        return entity.employees.find(e => e.id === emp?.id) !== undefined
                    },
                },
            ]}
            types={types}
            actions={actions}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) && textContainsLowerCase(searchText, entity.title)
            }
        />
    );
}

export default CompetencesPage;