import { useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import Role from '@src/Objects/Role';
import CardBox from '@src/Components/CardBox/CardBox';
import './RoleCard.css';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import { RoleDialog } from '@src/Components/Dialogs/RoleDialog/RoleDialog';

interface RoleCardProps
{
    role: Role
    selected?: boolean
    onSelectChange?: (state: boolean) => void
}

/**
 * RoleCard component.
 *
 * @param {RoleCardProps} props - The props for the RoleCard component.
 *
 * @returns {JSX.Element} The rendered RoleCard component.
 */
function RoleCard(props: RoleCardProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).roles;
    const navigate = useNavigate();
    const role = props.role;
    return (
        <CardBox
            menu={
                <div>
                    <MenuItem
                        onClick={() =>
                        {
                            return openDialog(
                                <RoleDialog
                                    id="RoleDialog"
                                    role={role}
                                />);
                        }}
                    >
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText>{langStrings.edit}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                        {
                            role.delete()
                        }}
                    >
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>{langStrings.delete}</ListItemText>
                    </MenuItem>
                </div>
            }
            onSelectChange={props.onSelectChange}
            selected={props.selected}
            title={role.title}
            className={'roleCard'}
            onClick={() =>
            {
                navigate(`${role.link}`)
            }}
        >
            <ExpandableText>
                <>
                    {langStrings.description + ": "}
                    <FormatedTextInput value={role.description} readonly={true} />
                </>
            </ExpandableText>
        </CardBox>
    );
}

export default RoleCard;
