import './ProjectPage.css';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { EEntityType, ELinks, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import { updateProject } from '@store/reducer/projectReducer';
import Project from '@src/Objects/Project';
import ProjectDialog from '@src/Components/Dialogs/ProjectDialog/ProjectDialog';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import getProject from '@src/APIs/graphQl/Project/getProject';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import EmployeesCard from '@src/App/NewLayout/Cards/Employees/EmployeesCard/EmployeesCard';
import { formatDateLocalized } from '@sharedInterfaces/globalHelper';

import EntityPage from '../../../../../sharedReact/Pages/EntityPage/EntityPage';
interface ProjectPageProps
{
    entity?: Project;
    inDialog?: boolean;
    onChange?: (changed: Project) => void
    onDelete?: () => void
}

/**
 * Represents a project page component.
 *
 * @param {ProjectPageProps} props - The props for the project page.
 * @returns {JSX.Element} The project page element.
 */
function ProjectPage(props: ProjectPageProps)
{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { openDialog } = useContext(DialogManagerContext);
    const { id } = useParams();
    const entityId = props.entity ? props.entity.id : id ? Number.parseInt(id) : -1;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).projects;
    const permissions = useSelector((state: AppState) => state.permissions);
    const entityState = useSelector((state: AppState) => state.projects.find(a => a.id === entityId))

    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    const setEntityState = (entity: Project) => entity && dispatch(updateProject(entity))


    useEffect(() =>
    {
        if (props.inDialog) return;
        getProject(entityId).catch((ex) =>
        {
            setLoadingError(ex);
        });

    }, [entityId, props.inDialog]);

    useEffect(() =>
    {
        if (props.entity) setEntityState(props.entity);
    }, [props.entity]);

    const handleOpenEditDialog = async () =>
    {
        return openDialog(
            <ProjectDialog
                id="ProjectDialog"
                project={entityState}
                resolve={(entity) =>
                {
                    if (entity)
                    {
                        setEntityState(entity);
                        props.onChange && props.onChange(entity);
                    }
                }}
            />);
    };

    return (
        <EntityPage
            inDialog={props.inDialog}
            entity={entityState}
            loadingError={loadingError}
            admin={permissions.admin}
            permissions={permissions.entities.Projects}
            onDelete={() =>
            {
                if (!props.inDialog)
                    navigate(`/${ELinks.SALES_PROJECTS}`);
            }}
            openEditDialog={handleOpenEditDialog}
            informations={
                !entityState ? [] : [
                    (entityState.business && entityState.business.id !== -1) ?
                        <EntityPageInformation title={langStrings.customer} >
                            <NavLink to={entityTypeToLink(entityState.business.id, EEntityType.BUSINESS)}>
                                {entityState.business.title}
                            </NavLink>
                        </EntityPageInformation> : undefined,
                    <EntityPageInformation key={1} title={langStrings.active} >
                        {`${entityState.active ? langStrings.yes : langStrings.no}`}
                    </EntityPageInformation>,
                    entityState.startDate ?
                        <EntityPageInformation
                            title={langStrings.startDate}
                        >
                            {formatDateLocalized(entityState.startDate, lang)}
                        </EntityPageInformation>
                        : undefined,
                    entityState.endDate ?
                        <EntityPageInformation
                            title={langStrings.endDate}
                        >
                            {formatDateLocalized(entityState.endDate, lang)}
                        </EntityPageInformation>
                        : undefined,
                    <EntityPageInformation key={2} title={langStrings.hours} >
                        {`${Math.round(entityState.isHours * 10) / 10} / ${Math.round(entityState.totalHours * 10) / 10} - ${entityState.percent}%`}
                    </EntityPageInformation>,
                    <EntityPageInformation key={3} title={langStrings.revenue} >
                        {`${entityState.revenue.toLocaleString('de-DE')}€`}
                    </EntityPageInformation>,
                    <EntityPageInformation key={4} title={langStrings.dayPrice} >
                        {`${entityState.dayPrice.toLocaleString('de-DE')}€`}
                    </EntityPageInformation>,
                    <EntityPageInformation key={4} title={langStrings.internalProject} >
                        {entityState.internalProject ? langStrings.yes : langStrings.no}
                    </EntityPageInformation>,

                    <EntityPageInformation key={5} title={langStrings.description} size={'full'}>
                        <ExpandableText>
                            <FormatedTextInput value={entityState.description} readonly={true} />
                        </ExpandableText>
                    </EntityPageInformation>,
                ]
            }
        >
            <>
                {
                    entityState &&
                    <EmployeesCard employees={entityState.employees} />
                }
            </>
        </EntityPage>
    );
}

export default ProjectPage;
