import { Add, ArrowDownward, ArrowUpward, Delete, Edit, Done } from '@mui/icons-material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ILevel } from '@sharedInterfaces/ICompanySettings';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import { setLevelDefinition } from '@store/reducer/levelDefinitionReducer';
import Company from '@src/Objects/Company';
import editLevelDefinitions from '@src/APIs/graphQl/Company/editLevelDefinitions';

import CardBox from '../../CardBox/CardBox';
import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import './EditLevelList.css';
import RoundIconButton from '../../Buttons/RoundIconButton/RoundIconButton';


interface EditLevelListProps
{
    level: ILevel[]
    readOnly?: true
    setCompany: (val: Company) => void

}

/**
 * EditLevelList function.
 * 
 * @param {EditLevelListProps} props - The props for the EditLevelList component.
 * @returns {JSX.Element} - The JSX element representing the EditLevelList component.
 */
function EditLevelList(props: EditLevelListProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const dispatch = useDispatch();

    // const [edit, setEdit] = React.useState(false as boolean);
    const [saving, setSaving] = React.useState(false as boolean);
    const [changed, setChanged] = React.useState(false as boolean);
    const [editLevel, setEditLevel] = React.useState(null as null | ILevel[]);

    return (
        <CardBox title={
            <div className='editLevelListTitle'>

                {langStrings.levelDefinitions}
                {!saving ?
                    < div className='edit' onClick={async () =>
                    {
                        if (saving) return;
                        if (editLevel)
                        {
                            if (!changed)
                            {
                                setEditLevel(null);
                                setSaving(false);
                                setChanged(false);
                                return;
                            }
                            setSaving(true);
                            const result = await editLevelDefinitions(editLevel)
                                .then((result) =>
                                {
                                    props.setCompany(result);
                                    setEditLevel(null);
                                    setSaving(false);
                                    setChanged(false);
                                    dispatch(setLevelDefinition(result.level))
                                }).catch(ex =>
                                {
                                    setTimeout(() =>
                                    {
                                        setSaving(false);
                                        setChanged(false);
                                    }, 3000);
                                    alert(ex);
                                });
                            return result;
                        } else
                        {
                            setEditLevel(props.level.slice());
                            setChanged(false);
                        }
                    }}>
                        {!props.readOnly &&
                            <RoundIconButton icon={!editLevel ? <Edit /> : <Done />}
                                helperText={''}
                                size={'small'}
                                onClick={() => { }} />
                        }
                    </div> :
                    null
                }
            </div>
        }>

            <div className='editLevelList'>

                <div className='levelDefinitions'>
                    {!editLevel &&
                        props.level.map(level =>
                            <div key={level.level} className='levelDefinition'>
                                <div className='headLine'>
                                    <RowElement title={langStrings.title} alignTitle={'left'}>
                                        <div className='title'>{level.title}</div>
                                    </RowElement>
                                    <div className='level'>{langStrings.level} {level.level}</div>
                                </div>
                                <div className="textsLine">
                                    <RowElement title={langStrings.description} alignTitle={'left'}>
                                        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} className='description'>{level.description}</pre>
                                    </RowElement>
                                    <RowElement title={langStrings.explanation} alignTitle={'left'}>
                                        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} className='helperText'>{level.helperText}</pre>                                </RowElement>
                                </div>
                            </div>)
                    }
                    {editLevel &&
                        editLevel.map((level, index) =>
                            <div key={level.level} className='levelDefinition editMode'>
                                <div className='headLine'>
                                    <RowElement title={langStrings.title} alignTitle={'left'}>
                                        <div style={{ marginLeft: 10, marginTop: 10, marginBlock: 10 }}>
                                            <TextInput value={level.title} onChange={function (val: string): void
                                            {
                                                const newEditLevel = editLevel.slice().map(l => Object.assign({}, l));
                                                newEditLevel[index].title = val;
                                                setEditLevel(newEditLevel);
                                                setChanged(true);
                                            }} />
                                        </div>
                                    </RowElement>
                                    <div className='level'>{langStrings.level} {level.level}</div>
                                    <div className='upDown'>
                                        {level.level > 1 ? <div className='arrow'><ArrowUpward onClick={() =>
                                        {
                                            const newEditLevel = editLevel.slice();

                                            newEditLevel[index - 1].level++;
                                            newEditLevel[index].level--;
                                            newEditLevel.sort((a, b) => a.level - b.level)
                                            setEditLevel(newEditLevel);
                                            setChanged(true);
                                        }}
                                        /></div> : ''}
                                        {level.level < editLevel.length ? <div className='arrow'><ArrowDownward onClick={() =>
                                        {
                                            const newEditLevel = editLevel.slice();

                                            newEditLevel[index].level++;
                                            newEditLevel[index + 1].level--;
                                            newEditLevel.sort((a, b) => a.level - b.level)
                                            setEditLevel(newEditLevel);
                                            setChanged(true);
                                        }}
                                        /></div> : ''}
                                    </div>
                                </div>
                                <RowElement title={langStrings.description} alignTitle={'left'}>
                                    <div className='description'>
                                        <TextInput value={level.description} onChange={function (val: string): void
                                        {
                                            const newEditLevel = editLevel.slice();

                                            newEditLevel[index].description = val;
                                            setEditLevel(newEditLevel);
                                            setChanged(true);
                                        }} />
                                    </div>
                                </RowElement>
                                <RowElement title={langStrings.explanation} alignTitle={'left'}>
                                    <div className='helperText'>
                                        <TextInput value={level.helperText} onChange={function (val: string): void
                                        {
                                            const newEditLevel = editLevel.slice();

                                            newEditLevel[index].helperText = val;
                                            setEditLevel(newEditLevel);
                                            setChanged(true);
                                        }} />
                                    </div>
                                </RowElement>

                                <div className='btnRow'>
                                    <div className='deleteBtn'
                                        onClick={() =>
                                        {
                                            const newEditLevel = editLevel.slice();

                                            newEditLevel.splice(index, 1);
                                            setEditLevel(newEditLevel);
                                            setChanged(true);
                                        }}
                                    >
                                        <Delete />
                                    </div>
                                </div>
                            </div>)
                    }

                    {editLevel &&
                        <Button size={'normal'} text={langStrings.newLevelDefinition} icon={<Add />} onClick={function (): void
                        {
                            const newEditLevel = editLevel.slice();

                            newEditLevel.push({ level: newEditLevel.length + 1, title: langStrings.newLevelDefinition, description: '', helperText: '' });

                            setEditLevel(newEditLevel);
                        }} disabled={saving} />
                    }
                </div>
            </div>
        </CardBox>
    );
}

export default EditLevelList;
