import './ProductPage.css';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { EEntityType, ELinks, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import { IEmployeeCoverage } from '@sharedInterfaces/IOpportunity';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import Product from '@src/Objects/Product';
import ProductDialog from '@src/Components/Dialogs/ProductDialog/ProductDialog';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import CardBox from '@src/Components/CardBox/CardBox';
import { EmployeeSuggestions } from '@src/Components/Opportunities/EmployeeSuggestions/EmployeeSuggestions';
import getProduct from '@src/APIs/graphQl/Product/getProduct';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import productGetSuggestedEmployees from '@src/APIs/graphQl/Product/productGetSuggestedEmployees';
import { combinedKnowledge } from "@src/helper/helper";
import CertificatesCard from '@src/App/NewLayout/Cards/Certificates/CertificatesCard/CertificatesCard';
import SkillsCard from '@src/App/NewLayout/Cards/Skills/SkillsCard/SkillsCard';
import CompetencesCard from '@src/App/NewLayout/Cards/Competences/CompetencesCard/CompetencesCard';
import EmployeesCard from '@src/App/NewLayout/Cards/Employees/EmployeesCard/EmployeesCard';
import RolesCard from '@src/App/NewLayout/Cards/Roles/RolesCard/RolesCard';

import EntityPage from '../../../../../sharedReact/Pages/EntityPage/EntityPage';

interface ProductPageProps
{
    entity?: Product;
    inDialog?: boolean;
}

/**
 * Function component representing a product page.
 *
 * @param {ProductPageProps} entity - The entity object.
 * @param {boolean} inDialog - Indicates if the component is in a dialog.
 * @param {function} onChange - The onChange event handler.
 * @param {function} onDelete - The onDelete event handler.
 * 
 * @return {JSX.Element} The rendered JSX element.
 */
function ProductPage({ entity, inDialog, }: ProductPageProps)
{
    const navigate = useNavigate();
    const { openDialog } = useContext(DialogManagerContext);
    const { id, tab } = useParams();
    const entityId = entity ? entity.id : id ? Number.parseInt(id) : -1;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).products;
    const permissions = useSelector((state: AppState) => state.permissions);
    const readRoles = checkPermissions('Roles', 'Retrieve', permissions);
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const entityState = useSelector((state: AppState) => state.products.find(a => a.id === entityId))

    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);
    const { combinedSkills, combinedCertificates } = combinedKnowledge(entityState, allCompetences);
    const [suggestedEmployees, setSuggestedEmployees] = useState<IEmployeeCoverage[] | null>(null)

    useEffect(() =>
    {
        if (inDialog) return;
        getProduct(entityId).catch((ex) =>
        {
            setLoadingError(ex);
        });

    }, [entityId, inDialog]);

    useEffect(() =>
    {
        if (inDialog) return;
        if (entityId === -1) return;
        if (tab !== 'suggestedEmployees') return;
        if (suggestedEmployees) return;
        productGetSuggestedEmployees(entityId, (suggestions) =>
        {
            // console.log(suggestions);
            setSuggestedEmployees(suggestions);
        }).catch((ex) =>
        {
            setLoadingError(ex);
        });
    }, [entity, tab])

    const handleOpenEditDialog = async () =>
    {
        return openDialog(
            <ProductDialog
                id="ProductDialog"
                product={entityState}
            />);
    };

    let content = undefined
    if (entityState)
    {
        switch (tab)
        {
            case 'suggestedEmployees':
                content = <>
                    {/* {entityState &&
                        suggestedEmployees?.levels.map(level =>
                        {
                            const levelName = entityState.levels[level.level - 1].title
                            return <CardBox key={level.level} title={levelName}>
                                <EmployeeSuggestions suggestedEmployees={level.employees} id={level.level.toString()} />
                            </CardBox>
                        })
                    } */}
                    {!suggestedEmployees && <LoadingBox />}
                    {suggestedEmployees &&
                        <CardBox title={langStrings.suggestedEmployees} key={1} >
                            <EmployeeSuggestions suggestedEmployees={suggestedEmployees} id={"suggestedEmployees"} />
                        </CardBox>
                    }
                </>
                break
            default:
                content = <>
                    <EmployeesCard title={langStrings.fittingUsers} employees={[...entityState.employees]} />
                    {readRoles &&
                        <RolesCard roles={entityState.roles} />
                    }
                    <CompetencesCard competences={entityState.competences} />
                    <SkillsCard skills={combinedSkills} />
                    <CertificatesCard
                        certificates={combinedCertificates}
                        title={langStrings.certificates}
                    />
                </>
        }

    }




    return (
        <EntityPage
            inDialog={inDialog}
            loadingError={loadingError}
            entity={entityState}
            admin={permissions.admin}
            permissions={permissions.entities.Products}
            tabs={inDialog ? undefined : [
                {
                    title: langStrings.oftenUsed.dashboard,
                    link: entityTypeToLink(entityId, EEntityType.PRODUCT, 'dashboard'),
                },
                {
                    title: langStrings.suggestedEmployees,
                    link: entityTypeToLink(entityId, EEntityType.PRODUCT, 'suggestedEmployees'),
                },
            ]}
            onDelete={() =>
            {
                if (!inDialog)
                    navigate(`/${ELinks.SALES_PRODUCTS}`);
            }}
            openEditDialog={handleOpenEditDialog}
            informations={
                entityState ?
                    [
                        <EntityPageInformation key={1} title={langStrings.skillCoverage} >
                            {`${entityState.skillCoverage}%`}
                        </EntityPageInformation>,
                        (entityState.productOwner?.id) ?
                            <EntityPageInformation title={langStrings.oftenUsed.productOwner} >
                                <NavLink to={entityTypeToLink(entityState.productOwner.id, EEntityType.EMPLOYEE)}>{entityState.productOwner.title}</NavLink>
                            </EntityPageInformation>
                            : undefined,
                        (entityState.externalRessourceLink && entityState.externalRessourceLink !== "") ?
                            <EntityPageInformation title={langStrings.oftenUsed.externalRessource} >
                                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <Button
                                        title={langStrings.oftenUsed.openInNewTab}
                                        endIcon={<OpenInNew fontSize='small' />}
                                        size='small'
                                        style={{ textTransform: 'none' }}
                                        onClick={() =>
                                        {
                                            window.open(entityState?.externalRessourceLink, '_blank');
                                        }}
                                    >
                                        {langStrings.oftenUsed.open}
                                    </Button>
                                </div>
                            </EntityPageInformation> : undefined,
                        <EntityPageInformation key={2} title={langStrings.description} size={'full'}>
                            <ExpandableText>
                                <FormatedTextInput value={entityState.description} readonly={true} />
                            </ExpandableText>
                        </EntityPageInformation>,
                        // <EntityPageInformation key={3} title={'JSON'} >
                        //<RenderJson data ={entityState}/>
                        // </EntityPageInformation>
                    ]
                    : []
            }
        >
            {content}
        </EntityPage>
    );
}

export default ProductPage;

