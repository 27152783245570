import React from 'react';
import { Menu } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import UserButton from '@src/Components/UserButton/UserButton';
import './Header.css';
import SearchBox from '@src/Components/SearchBox/SearchBox';

const MemoizedSearchBox = React.memo(SearchBox);
const MemoizedUserButton = React.memo(UserButton);

interface HeaderProps
{
  toggleNav: () => void;
  navIsOpen: boolean;
}

/**
 * Renders the header component.
 *
 * @param {HeaderProps} toggleNav - The toggleNav function.
 * @param {HeaderProps} navIsOpen - The navIsOpen boolean value.
 *
 * @returns {JSX.Element} The rendered header component.
 */
function Header({ toggleNav, navIsOpen }: HeaderProps)
{
  const companyName = useSelector((state: AppState) => state.company.name);
  const width = useSelector((state: AppState) => state.windowSize.width);

  const title = React.useMemo(
    () => `CompetenceManager ${companyName && width > 800 ? ' - ' + companyName : ''}`,
    [companyName, width]
  );

  return (
    <header className="header">
      {companyName && (
        <div className={"navButton" + (navIsOpen ? ' navIsOpen' : '')} onClick={toggleNav}>
          <Menu />
        </div>
      )}
      <div className="title">{title}</div>
      <div className='rightOptions'>
        {companyName && <MemoizedSearchBox />}
        <MemoizedUserButton />
      </div>
    </header>
  );
}

export default Header;
