import { OperationVariables, gql } from "@apollo/client";
import store from "@store/store";
import { setEmployeeRoles } from "@store/reducer/employeeReducer";
import { ISmallRole } from "@sharedInterfaces/IWhoIAm";
import { cloneDeep } from "@apollo/client/utilities";

import GraphQL from "../graphQL";

const query = gql`
mutation changeMapRole($id: Int!, $add: Boolean!) {
    changeMapRole(id: $id, add: $add) 
},
`;

/**
 * Change the map role.
 * 
 * @param {number} id - The ID of the role.
 * @param {boolean} add - Specifies whether to add or remove the role.
 * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the role was successfully changed.
 */
export default async function changeMapRole(id: number, add: boolean)
{
    const variables: OperationVariables = {
        id,
        add,
    };
    return GraphQL.mutate<boolean>(query, variables).then(result =>
    {
        const state = store.getState();
        if (!add)
        {
            store.dispatch(setEmployeeRoles(state.employee.roles.filter(c => c.id !== id)));
        } else
        {
            const allRole = state.company.smallRoles.find(c => c.id === id) as ISmallRole;

            const newRoles = cloneDeep(state.employee.roles.filter(c => c.id !== id));
            newRoles.push({
                id: id,
                title: allRole.title,
            });
            store.dispatch(setEmployeeRoles(newRoles))
        }
        return result;
    });
}
