import React from 'react';
import { useSelector } from 'react-redux';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import getCertificate from '@src/APIs/graphQl/Certificate/getCertificate';
import CertificatePage from '@src/App/NewLayout/Pages/Certificates/CertificatePage/CertificatePage';

import Overlay from '../Overlay/Overlay';
import './CertificateOverlay.css';



interface CertificateOverlayProps
{
    certificateId: number;
    children: React.ReactNode;
    disabled?: boolean;
}

/**
 * Represents a Certificate Overlay component.
 * 
 * @param {CertificateOverlayProps} props - The props for the Certificate Overlay component.
 * @returns {JSX.Element} The rendered Certificate Overlay component.
 */
function CertificateOverlay(props: CertificateOverlayProps)
{
    const entity = useSelector((state: AppState) => state.certificates.find(a => a.id === props.certificateId));
    const permissions = useSelector((state: AppState) => state.permissions);
    const isAllowed = checkPermissions('Certificates', 'Retrieve', permissions) || checkPermissions('Certificates', 'RetrieveOwn', permissions);
    const disabled = !isAllowed || props.disabled;

    return (
        <Overlay
            disabled={disabled}
            triggerItem={props.children}
            loaded={entity !== null}
            onFirstOpen={() =>
            {
                getCertificate(props.certificateId);
            }}>
            {entity && <CertificatePage
                entity={entity}
                inDialog={true}
            />}
        </Overlay>
    );
}

export default CertificateOverlay;
