import { ELanguage } from "../languageHelper";

import { oftenUsedTranslations } from "./oftenUsed"

export interface ISalesTexts
{
    coveredRolesHint: string;
    coveredRoles: string;
    missingRolesHint: string;
    missingRoles: string;
    roleScoreHint: string;
    roleScore: string;
    toManyEmployeesSelected: string;
    productsDescription: string;
    products: string;
    coveredCertificatesHint: string;
    coveredCertificates: string;
    missingCertificatesHint: string;
    missingCertificates: string;
    certificatesHint: string;
    certificateScore: string;
    coveredSkillsHint: string;
    missingSkillsHint: string;
    availabilityHint: string;
    skillScoreHint: string;
    skillCoverageHint: string;
    select: string;
    selectedEmployeeLimitHint: string;
    projects: string;
    projectsDescription: string;
    requests: string;
    requestsDescription: string;
    businesses: string;
    businessesDescription: string;
    analyseText: string;
    sales: string
    newOpportunity: string
    opportunity: string
    opportunities: string
    title: string
    customer: string
    employees: string
    startDate: string
    endDate: string
    utilization: string
    remote: string
    dayPrice: string
    topSkills: string
    neededSkills: string
    selectedEmployee: string
    suggestedEmployee: string
    delete: string
    loading: string
    overview: string
    pleaseEnterProjectTitle: string
    save: string
    location: string
    partner: string
    skillCoverage: string
    skillScore: string
    availability: string
    coveredSkills: string
    missingSkills: string
}
type ILangData = {
    [lang in ELanguage]: ISalesTexts
}
export const salesTexts: ILangData = {
    'th': {
        missingRoles: 'บทบาทที่ขาด',
        missingRolesHint: 'ไม่มีบทบาทเหล่านี้',
        coveredRoles: 'บทบาทที่มี',
        coveredRolesHint: 'มีบทบาทเหล่านี้และมีความสำคัญ',
        roleScoreHint: 'การครอบคลุมของบทบาทที่มีอยู่และที่จำเป็น',
        roleScore: 'การครอบคลุมบทบาท',
        toManyEmployeesSelected: 'ในคำขอนี้มีการกำหนดจำนวนพนักงานไว้ที่ [LIMIT] คุณได้เลือกไปแล้ว [AMOUNT]',
        productsDescription: 'แสดง แก้ไข และเพิ่มผลิตภัณฑ์',
        certificateScore: 'ความครอบคลุมของใบรับรอง',
        skillCoverageHint: 'การครอบคลุมทักษะที่จำเป็นโดยไม่คำนึงถึงระดับทักษะ',
        missingCertificatesHint: 'ไม่มีใบรับรองเหล่านี้',
        missingCertificates: 'ใบรับรองที่หายไป',
        coveredCertificatesHint: 'ใบรับรองเหล่านี้มีอยู่และมีความสำคัญ',
        coveredCertificates: 'ใบรับรองที่มีอยู่',
        certificatesHint: 'อัตราส่วนของใบรับรองที่มีและที่จำเป็น',
        availabilityHint: 'ความพร้อมใช้งานใน 28 วันถัดไป',
        skillScoreHint: 'การประเมินทักษะที่จำเป็น ซึ่งรวมถึงระดับทักษะด้วย',
        missingSkillsHint: 'ทักษะเหล่านี้ไม่สามารถหาได้',
        coveredSkillsHint: 'เหล่านี้คือทักษะที่มีอยู่และเกี่ยวข้อง',
        selectedEmployeeLimitHint: 'หากคุณต้องการเลือกพนักงานเพิ่มเติม โปรดเพิ่มขนาดทีม',
        select: 'เลือก',
        sales: 'การขาย',
        newOpportunity: 'โอกาสใหม่',
        opportunity: oftenUsedTranslations['th'].opportunity,
        opportunities: oftenUsedTranslations['th'].opportunities,
        title: 'ชื่อโปรเจกต์',
        customer: 'ลูกค้า',
        employees: oftenUsedTranslations['th'].employees,
        startDate: 'วันเริ่มโปรเจกต์',
        endDate: 'วันสิ้นสุดโปรเจกต์',
        utilization: 'การใช้ประโยชน์',
        remote: 'ระยะไกล',
        dayPrice: 'อัตราวัน',
        topSkills: 'ทักษะสำคัญ',
        neededSkills: 'ทักษะที่ต้องการ',
        selectedEmployee: 'พนักงานที่เลือก',
        suggestedEmployee: 'พนักงานที่แนะนำ',
        delete: oftenUsedTranslations['th'].delete,
        loading: oftenUsedTranslations['th'].loading,
        overview: 'ภาพรวม',
        save: oftenUsedTranslations['th'].save,
        partner: 'พาร์ทเนอร์',
        location: 'สถานที่',
        pleaseEnterProjectTitle: 'โปรดใส่ชื่อโปรเจกต์',
        skillCoverage: 'ความครอบคลุมทักษะ',
        skillScore: 'คะแนนทักษะ',
        availability: 'ความพร้อมใช้งาน',
        coveredSkills: 'ทักษะที่ครอบคลุม',
        missingSkills: 'ทักษะที่ขาดหายไป',
        analyseText: 'วิเคราะห์ข้อความ',
        requests: oftenUsedTranslations['th'].opportunities,
        businesses: oftenUsedTranslations['th'].businesses,
        projects: oftenUsedTranslations['th'].projects,
        "requestsDescription": "ดู, แก้ไข, และเพิ่มคำขอ.",
        "businessesDescription": "ดู, แก้ไข, และเพิ่มธุรกิจ.",
        "projectsDescription": "ดู, แก้ไข, และเพิ่มโครงการ.",
        products: oftenUsedTranslations['th'].products,

    },
    'en': {
        missingRoles: 'Missing roles',
        missingRolesHint: 'These roles are not present.',
        coveredRoles: 'Present roles',
        coveredRolesHint: 'These roles are present and relevant.',
        roleScoreHint: 'The coverage of existing and needed roles.',
        roleScore: 'Role Coverage',
        toManyEmployeesSelected: 'The request has a limit of [LIMIT] employees; you have already selected [AMOUNT].',
        productsDescription: 'View, edit, and add products.',
        certificateScore: 'Certificate coverage',
        skillCoverageHint: 'Coverage of the necessary skills without considering their level.',
        missingCertificatesHint: 'These certificates are not available.',
        missingCertificates: 'Missing Certificates',
        coveredCertificatesHint: 'These certificates are present and relevant.',
        coveredCertificates: 'Existing Certificates',
        certificatesHint: 'The ratio of existing and required certificates.',
        availabilityHint: 'The availability in the next 28 days',
        skillScoreHint: 'The skill rating of the required skills, this includes the levels.',
        missingSkillsHint: 'These skills are not available.',
        coveredSkillsHint: 'These are the skills that are available and relevant.',
        selectedEmployeeLimitHint: 'If you would like to select more employees, please increase the team size.',
        select: 'Select',
        sales: 'Sales',
        newOpportunity: 'New opportunity',
        opportunity: oftenUsedTranslations['en'].opportunity,
        opportunities: oftenUsedTranslations['en'].opportunities,
        title: 'Project title',
        customer: 'Customer',
        employees: 'Employees',
        startDate: 'Start date',
        endDate: 'End date',
        utilization: 'Utilization',
        remote: 'Remote',
        dayPrice: 'Daily rate',
        topSkills: 'Top skills',
        neededSkills: 'Needed skills',
        selectedEmployee: 'Selected employees',
        suggestedEmployee: 'Suggested employees',
        delete: oftenUsedTranslations['en'].delete,
        loading: oftenUsedTranslations['en'].loading,
        overview: 'Overview',
        save: oftenUsedTranslations['en'].save,
        partner: 'Partner',
        location: 'Location',
        pleaseEnterProjectTitle: 'Please enter a project title.',
        skillCoverage: 'Skill coverage',
        skillScore: 'Skill score',
        availability: 'Availability',
        coveredSkills: 'Covered skills',
        missingSkills: 'Missing skills',
        analyseText: 'Analyse text',
        requests: oftenUsedTranslations['en'].opportunities,
        businesses: oftenUsedTranslations['en'].businesses,
        projects: oftenUsedTranslations['en'].projects,
        "requestsDescription": "View, edit, and add requests.",
        "businessesDescription": "View, edit, and add businesses.",
        "projectsDescription": "View, edit, and add projects.",
        products: oftenUsedTranslations['en'].products,
    },
    'de': {
        sales: 'Vertrieb',
        newOpportunity: 'Neue Anfrage',
        opportunity: oftenUsedTranslations['de'].opportunity,
        opportunities: oftenUsedTranslations['de'].opportunities,
        title: 'Projektname',
        customer: 'Kunde',

        employees: oftenUsedTranslations['de'].employees,
        startDate: 'Projektstart',
        endDate: 'Projektende',
        utilization: 'Auslastung',
        remote: 'Remote',
        dayPrice: 'Tagessatz',
        topSkills: 'Top-Kenntnisse',
        neededSkills: 'Benötigte Kenntnisse',
        selectedEmployee: 'Ausgewählte Mitarbeiter',
        suggestedEmployee: 'Vorgeschlagene Mitarbeiter',
        delete: 'Löschen',
        loading: oftenUsedTranslations['de'].loading,
        overview: oftenUsedTranslations['de'].overview,
        save: oftenUsedTranslations['de'].save,
        partner: 'Partner',
        location: 'Standort',
        pleaseEnterProjectTitle: 'Bitte einen Projektnamen eingeben.',
        skillCoverage: 'Fähigkeitsabdeckung',
        skillScore: 'Fähigkeitsbewertung',
        availability: 'Verfügbarkeit',
        coveredSkills: 'Vorhandene Fähigkeiten',
        missingSkills: 'Fehlende Fähigkeiten',
        analyseText: 'Anfrage analysieren',
        requests: oftenUsedTranslations['de'].opportunities,
        businesses: oftenUsedTranslations['de'].businesses,
        projects: oftenUsedTranslations['de'].projects,
        requestsDescription: 'Anfragen anzeigen, bearbeiten und hinzufügen.',
        businessesDescription: 'Firmen anzeigen, bearbeiten und hinzufügen.',
        projectsDescription: 'Projekte anzeigen, bearbeiten und hinzufügen.',
        productsDescription: 'Produkte anzeigen, bearbeiten und hinzufügen.',
        select: 'Auswählen',
        selectedEmployeeLimitHint: 'Wenn du mehr Mitarbeiter auswählen möchtest erhöhe die Team größe.',
        coveredSkillsHint: 'Das sind die Fähigkeiten, die vorhanden und relevant sind.',
        missingSkillsHint: 'Diese Fähigkeiten sind nicht vorhanden.',
        skillScoreHint: 'Die Fähigkeitsbewertung der benötigten Fähigkeiten, dies schließt die Level mit ein.',
        availabilityHint: 'Die Verfügbarkeit in den nächsten 28 Tagen',
        skillCoverageHint: 'Die Fähigkeitsabdeckung der benötigten Fähigkeiten ohne deren Level.',
        certificateScore: 'Zertifikatsabdeckung',
        certificatesHint: 'Die Abdeckung der vorhandenen und benötigten Zertifikate.',
        coveredCertificates: 'Vorhandene Zertifikate',
        coveredCertificatesHint: 'Diese Zertifikate sind vorhanden und relevant.',
        missingCertificates: 'Fehlende Zertifikate',
        missingCertificatesHint: 'Diese Zertifikate sind nicht vorhanden.',
        products: oftenUsedTranslations['de'].products,
        toManyEmployeesSelected: 'In der Anfrage ist eine Mitarbeiterzahl von [LIMIT] festgelegt, du hast jetzt bereits [AMOUNT] ausgewählt.',
        roleScore: 'Rollenabdeckung',
        roleScoreHint: 'Die Abdeckung der vorhandenen und benötigten Rollen.',

        coveredRolesHint: 'Diese Rollen sind vorhanden und relevant.',
        coveredRoles: 'Vorhandene Rollen',
        missingRolesHint: 'Diese Rollen sind nicht vorhanden.',
        missingRoles: 'Fehlende Rollen',
    },
}