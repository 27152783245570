import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Cancel, Delete } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';


interface confirmDeleteModalProps
{
    id: string;
    elementName: string;
    message: string;
    resolve: (value: boolean) => void;
}
const ConfirmDeleteModal: React.FC<confirmDeleteModalProps> = ({ id, elementName, message, resolve, }) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).layout;

    const title = langStrings.oftenUsed.deleteTitle.replace("[TITLE]", elementName);
    const deleteHint = message ?
        message :
        langStrings.oftenUsed.deleteHint.replace("[TITLE]", elementName);

    const [isOpen, setIsOpen] = useState(true);

    const onconfirmDelete = () =>
    {
        resolve(true);
        setIsOpen(false);
    };

    const onCancel = () =>
    {
        resolve(false);
        setIsOpen(false);
    };

    useEffect(() =>
    {
        if (!isOpen)
        {
            onCancel();
        }
    }, [isOpen, onCancel]);

    if (!isOpen) return null;

    return (
        <Dialog
            id={id}
            footer={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    text={langStrings.oftenUsed.cancel}
                    icon={<Cancel />}
                    onClick={onCancel} />
                <Button
                    icon={<Delete />}
                    text={langStrings.oftenUsed.delete}
                    color='red'
                    onClick={onconfirmDelete}
                />
            </div>}
            title={title}
            onClose={function (): void
            {
                onCancel();
            }}>
            <p>{deleteHint}</p>
        </Dialog>
    );
};

export function useConfirmDeleteDialog()
{
    const { openDialog } = useContext(DialogManagerContext);
    const confirmDelete = (elementName: string, message = "") =>
    {
        return new Promise((resolve) =>
        {
            const dialog = (
                <ConfirmDeleteModal
                    id="ConfirmDeleteModal"
                    elementName={elementName}
                    message={message}
                    resolve={(result) => resolve(result)}
                />
            );
            openDialog(dialog);
        });
    };
    return confirmDelete;
}