import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Edit } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IOUSalesDataEmployee } from '@sharedInterfaces/IOrgUnit';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { IOrgUnitTexts } from '@sharedInterfaces/Language/languageTexts/orgUnit';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import Project from '@src/Objects/Project';
import RoundIconButton from '@src/Components/Buttons/RoundIconButton/RoundIconButton';
import ProjectDialog from '@src/Components/Dialogs/ProjectDialog/ProjectDialog';
import ProjectOverlay from '@src/Components/Overlays/ProjectOverlay/ProjectOverlay';
import DataTable, { IDataTableField, IDataTableRow } from '@src/App/NewLayout/Components/DataTable/DataTable';
import getProject from '@src/APIs/graphQl/Project/getProject';

import { generateBoxTitleProject, sumProjectValues } from './SalesContent';
import { SalesField } from './SalesField';

export interface InnerDataTableProps
{
    monthIds: string[];
    salesEmp: IOUSalesDataEmployee;
    searchText: string;
    allEmpProjects: {
        [key: number]: string;
    };
    showAbsence: boolean;
    showUnbilableProjectTime: boolean;
    actualMonth: string
    actualMonthSource: string
    trendSensitivity: number
    refresh: () => void;
}

/**
 * Function component representing the inner data table.
 *
 * @param {InnerDataTableProps} props - The props for the InnerDataTable component.
 * @param {function} props.refresh - The function to refresh the data table.
 * @param {Array<string>} props.monthIds - The array of month IDs.
 * @param {Array<any>} props.allEmpProjects - The array of all employee projects.
 * @param {Array<any>} props.salesEmp - The array of sales employees.
 * @param {string} props.searchText - The search text.
 * @param {boolean} props.showAbsence - Indicates if absence should be shown.
 * @param {string} props.actualMonth - The actual month.
 * @param {string} props.actualMonthSource - The source of the actual month.
 *
 * @returns {JSX.Element} - The JSX element representing the inner data table.
 */
export function InnerDataTable({ refresh, monthIds, trendSensitivity, allEmpProjects, salesEmp, searchText, showAbsence, actualMonth, actualMonthSource, showUnbilableProjectTime }: InnerDataTableProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;

    const projects = useSelector((state: AppState) => state.projects);
    const permissions = useSelector((state: AppState) => state.permissions);
    const editProjectPermission = checkPermissions('Projects', 'Edit', permissions);

    const width = useSelector((state: AppState) => state.windowSize.width);
    const monthOptions: Intl.DateTimeFormatOptions = { month: 'long' };
    // const isDense = (width: number) => width <= 1350;
    const isDense = (width: number) => true;

    const isSearchedEmployee = salesEmp.title.toLowerCase().includes(searchText);

    const onEdit = () =>
    {
        refresh();
    };

    const monthFieldsHoliday: { [key: string]: { value: JSX.Element; orderKey: number; }; } = {};
    monthIds.forEach(id =>
    {
        const month = salesEmp.months.find(m => m.monthId === id);
        const hoursSum = !showAbsence ? 0 : month ? month.holidayHours : 0;
        monthFieldsHoliday['monthId' + id] = {
            orderKey: 0,
            value: <SalesField
                bookedHours={0}
                plannedHours={0}
                bookedRevenue={0}
                plannedRevenue={0}
                absenceHours={hoursSum}
                showUnbilableProjectTime={false}
                unbilableProjectTime={0}
                hideRevenue
                trendSensitivity={trendSensitivity}
            />
            // value: (month && (hoursSum !== 0 || revenueSum !== 0)) ? <div className={`orgUnitPageMonthBox ${month.source}`}>
            //     <div> {`${(Math.round(hoursSum * 100) / 100).toLocaleString()}${langStrings.hoursShort}`}</div>
            // </div> : <div className={`orgUnitPageMonthBox `}>-</div>
        };
    });

    const totalEmpAbsence = !showAbsence ? 0 : monthIds.map(id => salesEmp.months.find(m => m.monthId === id))
        .filter(m => m)
        .reduce((prev, cur) => prev + (cur ? cur.holidayHours : 0), 0);

    const employeeProjects = Object.entries(allEmpProjects);
    employeeProjects.sort((a, b) => a[1].localeCompare(b[1]));
    const employeeRows = employeeProjects
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([projectId, projectTitle]) => projectTitle.toLowerCase().includes(searchText) || isSearchedEmployee)
        .map(([stringProjectId, projectTitle]) =>
        {

            return generateEmpProjectRow({
                stringProjectId, projectTitle, monthIds, salesEmp, langStrings, projects, editProjectPermission,
                actualMonth, actualMonthSource,
                onEdit, openDialog, showUnbilableProjectTime,
                trendSensitivity
            });
        });
    if (showAbsence)
    {
        employeeRows.push({
            id: -999,
            project: {
                orderKey: langStrings.absence,
                value: <div className='nameColumn'>{langStrings.absence}</div>
            },
            edit: {
                value: <div></div>,
                orderKey: 9999,
            },
            ...monthFieldsHoliday,
            sum: {
                orderKey: totalEmpAbsence,
                value: <SalesField
                    bookedHours={0}
                    plannedHours={0}
                    bookedRevenue={0}
                    plannedRevenue={0}
                    absenceHours={totalEmpAbsence}
                    showUnbilableProjectTime={false}
                    unbilableProjectTime={0}
                    hideRevenue
                    trendSensitivity={trendSensitivity}
                />
                // value: ((totalEmpHolidays !== 0)) ?
                //     <div>
                //         <div> {`${(Math.round((totalEmpHolidays) * 100) / 100).toLocaleString()}${langStrings.hoursShort}`}</div>
                //     </div> : <>-</>
            },
        });
    }


    return (<>
        <DataTable
            id={'salesinner'}
            key={salesEmp.id}
            style={{ backgroundColor: '#f9f9f9' }}
            dense={true}
            noCheckbox={true}
            hideFooter={true}
            rows={employeeRows}
            fields={[
                ...(editProjectPermission ? [{
                    id: 'edit',
                    label: '',
                    disablePadding: true,
                    width: 54,
                    align: 'center',
                    type: 'JSX.Element',
                }] : []) as IDataTableField<'JSX.Element'>[],
                {
                    id: 'project',
                    label: langStrings.project,
                    disablePadding: isDense(width),
                    width: 220,
                    align: 'left',
                    type: 'JSX.Element',
                },
                ...monthIds.map(id => ({
                    id: `monthId${id}`,
                    label: (new Date(id)).toLocaleString(undefined, monthOptions),
                    disablePadding: true,
                    align: 'center',
                    type: 'JSX.Element',
                }) as IDataTableField<'JSX.Element'>
                ),
                {
                    id: 'sum',
                    label: langStrings.sum,
                    disablePadding: isDense(width),
                    align: 'center',
                    type: 'JSX.Element',
                },
            ]}
            selected={[]}
            setSelected={() => { }} />
    </>);
}
interface EmpProjectRowProps
{
    stringProjectId: string;
    projectTitle: string;
    monthIds: string[];
    salesEmp: IOUSalesDataEmployee;
    langStrings: IOrgUnitTexts;
    projects: Project[];
    editProjectPermission: boolean;
    actualMonth: string;
    actualMonthSource: string;
    showUnbilableProjectTime: boolean;
    trendSensitivity: number;

    onEdit: () => void;
    openDialog: (dialog: React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>) => void;
}
/**
 * Generates a table row for displaying employee projects.
 *
 * @param EmpProjectRowProps - The properties of the employee project row.
 *                           - {stringProjectId, projectTitle, monthIds, salesEmp, onEdit} - The details of the project.
 *
 * @return IDataTableRow<IDataTableField<JSX.Element>[]> - The generated table row.
 */
function generateEmpProjectRow({ stringProjectId, projectTitle, monthIds, trendSensitivity, salesEmp, langStrings, projects, editProjectPermission, actualMonth, actualMonthSource, onEdit, openDialog, showUnbilableProjectTime }: EmpProjectRowProps): IDataTableRow<IDataTableField<'JSX.Element'>[]>
{
    const projectId = parseInt(stringProjectId);
    if (!projectTitle) projectTitle = langStrings.unknown;
    const monthFields: { [key: string]: { value: JSX.Element; orderKey: number; }; } = {};
    monthIds.forEach(id =>
    {
        const month = salesEmp.months.find(m => m.monthId === id);
        const project = month?.projects.find(p => p.projectId === projectId);

        // Bedingungen basierend auf actualMonthSource
        const shouldCalculateBooked = (actualMonth !== id || actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
        const shouldCalculatePlanned = (actualMonth !== id || actualMonthSource === 'both' || actualMonthSource === 'forecast');

        const bookedHours = shouldCalculateBooked && project ? project.bookedHours : 0;
        const unbillableBookedTimes = shouldCalculateBooked && project ? project.bookedNotBillableHours : 0;
        const bookedRevenue = shouldCalculateBooked && project ? project.bookedRevenue : 0;

        const plannedHours = shouldCalculatePlanned && project ? (month?.source !== 'mixed' && project.totalPlannedHours != null ? project.totalPlannedHours : project.plannedHours) : 0;
        const plannedRevenue = shouldCalculatePlanned && project ? (month?.source !== 'mixed' && project.totalPlannedRevenue != null ? project.totalPlannedRevenue : project.plannedRevenue) : 0;
        // const visiblePlannedHours = month?.source !== 'booking' ? plannedHours : 0;
        const visiblePlannedRevenue = month?.source !== 'booking' ? plannedRevenue : 0;
        const revenueSum = bookedRevenue + visiblePlannedRevenue;

        const boxTitle = project ? generateBoxTitleProject(plannedHours, langStrings, bookedHours, plannedRevenue, bookedRevenue, unbillableBookedTimes) : '';

        monthFields['monthId' + id] = {
            orderKey: revenueSum,
            value: <SalesField
                title={boxTitle}
                source={month?.source}
                bookedHours={bookedHours}
                plannedHours={plannedHours}
                bookedRevenue={bookedRevenue}
                plannedRevenue={plannedRevenue}
                absenceHours={0}
                showUnbilableProjectTime={showUnbilableProjectTime}
                unbilableProjectTime={unbillableBookedTimes}
                showTrend
                trendSensitivity={trendSensitivity}
            />
            // value: (month && (hoursSum !== 0 || revenueSum !== 0)) ?
            //     <div className={`orgUnitPageMonthBox ${month.source} ${revenueSum > 0 ? '' : 'noRevenue'}`} title={boxTitle}>
            //         <div> {`${Math.round(revenueSum).toLocaleString()}€`}</div>
            //         <div> {`${(Math.round(hoursSum * 100) / 100).toLocaleString()}${langStrings.hoursShort}`}</div>
            //     </div> : <div className={`orgUnitPageMonthBox `} title={boxTitle}>-</div>
        };
    });

    const editProject = editProjectPermission ? {
        edit: {
            value: <RoundIconButton icon={<Edit />} helperText={langStrings.edit} size={'small'}
                onClick={() =>
                {
                    const oldProject = projects.find(p => p.id === projectId);
                    getProject(projectId)
                        .then((newVersion) =>
                        {
                            const project = newVersion ? newVersion : oldProject;
                            return openDialog(
                                <ProjectDialog
                                    id="ProjectDialog"
                                    project={project}
                                    resolve={(entity) =>
                                    {
                                        if (entity)
                                        {
                                            onEdit();
                                        }
                                    }} />);
                        });
                }} />,
            orderKey: projectId
        }
    } : undefined;


    const projectBookedHours = salesEmp.months.filter(m => monthIds.includes(m.monthId)).reduce((total, month) =>
    {
        const isCurrentMonth = month.monthId === actualMonth;
        const calculateBooked = !isCurrentMonth || (actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
        return total + (calculateBooked ? sumProjectValues(month.projects.filter(p => p.projectId === projectId), proj => proj.bookedHours) : 0);
    }, 0);

    const projectUnbillableBookedTimes = salesEmp.months.filter(m => monthIds.includes(m.monthId)).reduce((total, month) =>
    {
        const isCurrentMonth = month.monthId === actualMonth;
        const calculateBooked = !isCurrentMonth || (actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
        return total + (calculateBooked ? sumProjectValues(month.projects.filter(p => p.projectId === projectId), proj => proj.bookedNotBillableHours) : 0);
    }, 0);

    const projectPlannedHours = salesEmp.months.filter(m => monthIds.includes(m.monthId)).reduce((total, month) =>
    {
        const isCurrentMonth = month.monthId === actualMonth;
        const calculatePlanned = (!isCurrentMonth && month.source !== 'booking') || (actualMonthSource === 'both' || actualMonthSource === 'forecast');
        return total + (calculatePlanned ? sumProjectValues(month.projects.filter(p => p.projectId === projectId), proj => isCurrentMonth && actualMonthSource === 'forecast' && proj.totalPlannedHours != null ? proj.totalPlannedHours : proj.plannedHours) : 0);
    }, 0);

    const projectBookedRevenue = salesEmp.months.filter(m => monthIds.includes(m.monthId)).reduce((total, month) =>
    {
        const isCurrentMonth = month.monthId === actualMonth;
        const calculateBooked = !isCurrentMonth || (actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
        return total + (calculateBooked ? sumProjectValues(month.projects.filter(p => p.projectId === projectId), proj => proj.bookedRevenue) : 0);
    }, 0);

    const projectPlannedRevenue = salesEmp.months.filter(m => monthIds.includes(m.monthId)).reduce((total, month) =>
    {
        const isCurrentMonth = month.monthId === actualMonth;
        const calculatePlanned = (!isCurrentMonth && month.source !== 'booking') || (actualMonthSource === 'both' || actualMonthSource === 'forecast');
        return total + (calculatePlanned ? sumProjectValues(month.projects.filter(p => p.projectId === projectId), proj => isCurrentMonth && actualMonthSource === 'forecast' && proj.totalPlannedRevenue != null ? proj.totalPlannedRevenue : proj.plannedRevenue) : 0);
    }, 0);

    const boxTitle = generateBoxTitleProject(projectPlannedHours, langStrings, projectBookedHours, projectPlannedRevenue, projectBookedRevenue, projectUnbillableBookedTimes);


    return {
        ...editProject,
        id: salesEmp.id,
        project: {
            orderKey: projectTitle,
            value: <div className='nameColumn'>
                <ProjectOverlay projectId={projectId}>{projectTitle}</ProjectOverlay>
            </div>
        },
        ...monthFields,
        sum: {
            orderKey: projectBookedRevenue + projectPlannedRevenue,
            value: <SalesField
                title={boxTitle}
                bookedHours={projectBookedHours}
                plannedHours={projectPlannedHours}
                bookedRevenue={projectBookedRevenue}
                plannedRevenue={projectPlannedRevenue}
                absenceHours={0}
                showUnbilableProjectTime={showUnbilableProjectTime}
                unbilableProjectTime={projectUnbillableBookedTimes}
                trendSensitivity={trendSensitivity}
            // showTrend
            />
            // value: ((projectBookedHours !== 0 || projectPlannedHours !== 0 || projectBookedRevenue !== 0 || projectPlannedRevenue !== 0)) ?
            //     <div
            //         title={boxTitle}>
            //         <div> {`${Math.round(projectBookedRevenue + projectPlannedRevenue).toLocaleString()}€`}</div>
            //         <div> {`${(Math.round((projectBookedHours - (showUnbilableProjectTime ? 0 : projectUnbillableBookedTimes) + projectPlannedHours) * 100) / 100).toLocaleString()}${langStrings.hoursShort}`}</div>
            //     </div> : <>-</>
        },
    };
}
