import { CallSplit, Merge, TableChart, TableRows } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import { textContainsLowerCase } from '@src/Objects/Helper';
import SkillDetail from '@src/Objects/SkillDetail';
import SkillOverlay from '@src/Components/Overlays/SkillOverlay/SkillOverlay';
import MergeSkillsDialog from '@src/Components/Dialogs/MergeSkillsDialog/MergeSkillsDialog'
import SplitSkillsDialog from '@src/Components/Dialogs/SplitSkillsDialog/SplitSkillsDialog'
import EnhancedTable from '@src/App/NewLayout/Components/DataTable/DataTable';
import getSkills from '@src/APIs/graphQl/Skill/getSkills';
import './SkillsPage.css';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import SkillCard from '@src/App/NewLayout/Cards/Skills/SkillCard/SkillCard';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';
import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';

/**
 * Component representing the SkillsPage.
 * 
 * @returns {JSX.Element} The JSX element for the SkillsPage.
 */
function SkillsPage()
{
    const { openDialog } = useContext(DialogManagerContext)
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;
    const emp = useSelector((state: AppState) => state.employee);
    const levelDefinitions = useSelector((state: AppState) => state.levelDefinition);
    const maxLevel = levelDefinitions.length;
    const permissions = useSelector((state: AppState) => state.permissions);
    const entities = useSelector((state: AppState) => state.skills);

    const isDense = (width: number) => width <= 1350
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);
    useEffect(() =>
    {
        getSkills().catch((ex) =>
        {
            setLoadingError(ex);
        })
    }, []);

    const [selected, setSelected] = useState<number[]>([]);

    const types: IViewType<SkillDetail>[] = [
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            minWidth: 290,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        key={"SkillsTable"}
                        id={EEntityType.SKILL}
                        dense={isDense(width)}
                        noCheckbox={!((checkPermissions('Skills', 'Delete', permissions) || checkPermissions('Skills', 'Add', permissions)) && checkPermissions('Skills', 'Edit', permissions))}
                        selected={selected}
                        setSelected={setSelected}
                        order='desc'
                        orderBy='employees'
                        fields={[{
                            id: 'id',
                            label: 'ID',
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'string',
                        },
                        {
                            id: 'title',
                            label: langStrings.skillName,
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.SKILLS}/`,
                        },
                        {
                            id: 'description',
                            label: langStrings.description,
                            disablePadding: isDense(width),
                            align: 'left',
                            minWidth: 1200,
                            type: 'description',
                        },
                        {
                            id: 'employees',
                            label: langStrings.employees,
                            disablePadding: isDense(width),
                            align: 'center',
                            type: 'string',
                            minWidth: 390,
                        },
                        {
                            id: 'minLevel',
                            label: langStrings.lowestEmplyeeSkillLevel,
                            disablePadding: true,
                            align: 'center',
                            type: 'level',
                            minWidth: 900,
                        },
                        {
                            id: 'averageLevel',
                            label: langStrings.averageEmployeeSkillLevel,
                            disablePadding: true,
                            align: 'center',
                            type: 'level',
                            minWidth: 550,
                        },
                        {
                            id: 'maxLevel',
                            label: langStrings.highestEmployeeSkillLevel,
                            disablePadding: true,
                            align: 'center',
                            type: 'level',
                            minWidth: 800,
                        },
                        {
                            id: 'products',
                            label: langStrings.products,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 1000,
                            type: 'string',
                        },
                        ]}
                        rows={
                            innerEntries.map(entity => ({
                                id: entity.id,
                                title: {
                                    value: <SkillOverlay skillId={entity.id}
                                        key={`SkillOverlay${entity.id}`}
                                    >{entity.title}</SkillOverlay>,
                                    orderKey: entity.title
                                },
                                description: entity.description,
                                employees: entity.employees.length,
                                products: entity.products.length,
                                minLevel: {
                                    value: entity.minLevel,
                                    percent: entity.minLevel / maxLevel * 100,
                                    orderKey: entity.minLevel,
                                },
                                averageLevel: {
                                    value: entity.averageLevel,
                                    percent: entity.averageLevel / maxLevel * 100,
                                    orderKey: entity.averageLevel,
                                },
                                maxLevel: {
                                    value: entity.maxLevel,
                                    percent: entity.maxLevel / maxLevel * 100,
                                    orderKey: entity.maxLevel,
                                },
                            }
                            ))
                        } />,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <SkillCard
                            key={entity.id}
                            skill={entity}
                        />)
                }
            </Container>,
        }
    ];

    const actions: IEntitiesPageAction[] = [
        {
            id: 'merge',
            text: langStrings.merge,
            icon: <Merge />,
            filter(selectedCount)
            {
                if (
                    !checkPermissions('Skills', 'Delete', permissions) ||
                    !checkPermissions('Skills', 'Edit', permissions)
                )
                    return false;
                return selectedCount > 1;
            },
            action(selectedIDs)
            {
                if (!this.filter(selectedIDs.length)) return;

                openDialog(
                    <MergeSkillsDialog
                        id="MergeSkillsDialog"
                        resolve={() => { setSelected([]) }}
                        skillsWithoutFilter={[...entities]}
                        skills={entities.filter(s => selected.indexOf(s.id) > -1)} />
                )
            },
        },
        {
            id: 'split',
            text: langStrings.split,
            icon: <CallSplit />,
            filter(selectedCount)
            {
                if (
                    !checkPermissions('Skills', 'Add', permissions) ||
                    !checkPermissions('Skills', 'Edit', permissions)
                )
                    return false;
                return selectedCount === 1;
            },
            action(selectedIDs)
            {
                if (!this.filter(selectedIDs.length)) return;
                openDialog(
                    <SplitSkillsDialog
                        id="SplitSkillsDialog"
                        resolve={() => { setSelected([]) }}
                        skillsWithoutFilter={entities}
                        skill={entities.find(s => selected.indexOf(s.id) > -1) as SkillDetail} />
                )
            },
        }
    ];

    if (!maxLevel) return <LoadingBox />
    return (
        <EntitiesPage
            title={langStrings.skills}
            entities={entities}
            selected={selected}
            loadingError={loadingError}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) && textContainsLowerCase(searchText, entity.title)
            }
            setSelected={setSelected}
            views={[
                {
                    id: 'all', title: langStrings.oftenUsed.allEntries,
                    filter()
                    {
                        return true;
                    },
                },
                {
                    id: 'my', title: langStrings.oftenUsed.myEntries,
                    filter(entity)
                    {
                        return entity.employees.find(e => e.id === emp?.id) !== undefined
                    },
                },
            ]}
            types={types}
            actions={actions}
        />
    );
}


export default SkillsPage;

