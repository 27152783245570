import React from 'react';
import { useSelector } from 'react-redux';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import getProduct from '@src/APIs/graphQl/Product/getProduct';
import ProductPage from '@src/App/NewLayout/Pages/Products/ProductPage/ProductPage';

import Overlay from '../Overlay/Overlay';
import './ProductOverlay.css';

interface ProductOverlayProps
{
    productId: number;
    children: React.ReactNode;
    disabled?: boolean;
}

/**
 * Renders a product overlay component.
 *
 * @param {ProductOverlayProps} props - The props object for configuring the product overlay.
 * @returns {JSX.Element} - The rendered product overlay component.
 */
function ProductOverlay(props: ProductOverlayProps)
{
    const product = useSelector((state: AppState) => state.products.find(p => p.id === props.productId));
    const permissions = useSelector((state: AppState) => state.permissions);
    const isAllowed = checkPermissions('Products', 'Retrieve', permissions) || checkPermissions('Products', 'RetrieveOwn', permissions);
    const disabled = !isAllowed || props.disabled;

    return (
        <Overlay
            disabled={disabled}
            triggerItem={props.children}
            loaded={product !== null}
            onFirstOpen={() =>
            {
                getProduct(props.productId);
            }}>
            {product && <ProductPage
                entity={product}
                inDialog={true}
            />}
        </Overlay>
    );
}

export default ProductOverlay;
