import { AccountTree, TableChart, TableRows } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './OrgUnitsPage.css';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import { IOrgUnitDetailDTO } from '@sharedInterfaces/IOrgUnit';
import { textContainsLowerCase } from '@src/Objects/Helper';
import OrgUnitOverlay from '@src/Components/Overlays/OrgUnitOverlay/OrgUnitOverlay';
import EnhancedTable from '@src/App/NewLayout/Components/DataTable/DataTable';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import getOrgUnits from '@src/APIs/graphQl/OrgUnit/getOrgUnits';
import OrgUnitCard from '@src/App/NewLayout/Cards/OrgUnits/ProjectCard/OrgUnitCard';
import OrgUnitDiagram from '@src/Components/OrgUnitDiagram/OrgUnitDiagram';

import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';
import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';

/**
 * Represents the Organization Units page.
 * @returns {JSX.Element}
 */
function OrgUnitsPage()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;
    const entities = useSelector((state: AppState) => state.orgUnits)
    const employees = useSelector((state: AppState) => state.employees);

    const isDense = (width: number) => width <= 500

    const [selected, setSelected] = useState<number[]>([]);
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    useEffect(() =>
    {
        getOrgUnits().catch((ex) =>
        {
            setLoadingError(ex);
        })
    }, []);

    const types: IViewType<IOrgUnitDetailDTO>[] = [
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            minWidth: 375,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        id={EEntityType.ORGANISATIONAL_UNIT}
                        dense={isDense(width)}
                        selected={selected}
                        setSelected={setSelected}
                        noCheckbox={true}
                        order='asc'
                        orderBy='title'
                        fields={[
                            {
                                id: 'id',
                                label: 'ID',
                                disablePadding: isDense(width),
                                align: 'left',
                                type: 'string',
                            },
                            {
                                id: 'title',
                                label: langStrings.orgName,
                                disablePadding: isDense(width),
                                align: 'left',
                                type: 'JSX.Element',
                                link: `/${ELinks.PEOPLE_ORGUNITS}/`,
                            },
                        ]}
                        rows={
                            innerEntries.map(entity => ({
                                id: entity.id,
                                title: {
                                    value: <OrgUnitOverlay id={entity.id}
                                    >{entity.title} </OrgUnitOverlay>,
                                    orderKey: entity.title
                                },
                            }
                            ))
                        } />,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <OrgUnitCard key={entity.id}
                            entity={entity}
                        />)
                }
            </Container>,
        },
        {
            id: EViewType.ORGUNIT,
            title: langStrings.oftenUsed.orgUnits,
            icon: <AccountTree fontSize='small' />,
            renderContainer: (allEntities, innerEntries) =>
            {
                employees
                return <OrgUnitDiagram units={innerEntries} />
                // const orgUnitTree = buildOrgUnitTree([...innerEntries]);
                // return (
                //     <svg width="100%" height={orgUnitTree.length * 200} style={{ border: '1px solid black' }}>
                //         {orgUnitTree.map((unit, index) => (
                //             <OrgUnitSVG key={unit.id} unit={unit} depth={0} index={index} />
                //         ))}
                //     </svg>
                // );
                // return <AppSVG rootUnits={innerEntries} />;
            },
        }
    ];

    const actions: IEntitiesPageAction[] = [

    ];

    return (
        <EntitiesPage
            title={langStrings.orgUnits}
            entities={entities}
            selected={selected}
            loadingError={loadingError}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) && textContainsLowerCase(searchText, entity.title)
            }
            setSelected={setSelected}
            views={[
                {
                    id: 'all', title: langStrings.oftenUsed.allEntries,
                    filter()
                    {
                        return true;
                    },
                },
            ]}
            types={types}
            actions={actions}
        />
    );
}
export default OrgUnitsPage;