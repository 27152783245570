import { Cancel, Check } from '@mui/icons-material';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ISmallEmployee, ISmallSkill } from '@sharedInterfaces/IWhoIAm';
import { AppState } from '@store/store';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import questionEmployeeSkill from '@src/APIs/graphQl/Skill/questionEmployeeSkill';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import RateBar from '../../formsControls/inputs/RateBar/RateBar';
import ExpandableText from '../../ExpandableText/ExpandableText';
import FormatedTextInput from '../../formsControls/inputs/FormatedTextInput/FormatedTextInput';
import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import { EmployeePicture } from '../../EmployeePicture/EmployeePicture';

interface QuestionEmployeeSkillDialogProps
{
    id: string
    employeeId: number,
    skillId: number,
    level?: number,
    resolve: (value: boolean) => void;
}
const QuestionEmployeeSkillDialog: React.FC<QuestionEmployeeSkillDialogProps> =
    ({ id, employeeId, skillId, level, resolve, }) =>
    {
        const lang = useSelector((state: AppState) => state.employee.language);
        const langStrings = getLanguageTexts(lang).skills;
        const smallSkill = useSelector((state: AppState) => state.company.allSkills.find(s => s.id === skillId)) as ISmallSkill;
        const description = useSelector((state: AppState) => state.skills.find(s => s.id === skillId)?.description);
        const levelDefinitions = useSelector((state: AppState) => state.levelDefinition);
        const getCurrentLevelDefinition = () => levelDefinitions.find(definition => definition.level === level);
        const currentLevelDefinition = getCurrentLevelDefinition();
        const employee = useSelector((state: AppState) => state.company.allEmployees.find(e => e.id === employeeId)) as ISmallEmployee;


        let firstName: string;
        let lastName: string;

        if (employee.title.indexOf('0'))
        {
            const split = employee.title.split(' ');
            firstName = split[0];
            lastName = split[1];
        } else
        {
            firstName = employee.title;
            lastName = '';
        }

        const [saving, setSaving] = useState<boolean>(false);
        const [userRating, setUserRating] = useState<number>(level ? level : 0);
        const [message, setMessage] = useState<string>('');


        const onConfirm = () =>
        {
            setSaving(true);
            questionEmployeeSkill(skillId, employeeId, userRating, message).finally(() =>
            {
                setSaving(false);
                resolve(true);
            });
        };

        const onCancel = () =>
        {
            resolve(false);
        };


        return (
            <Dialog
                id={id}
                footer={<div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                    <Button
                        text={langStrings.oftenUsed.cancel}
                        disabled={saving}
                        icon={<Cancel />}
                        onClick={onCancel} />
                    <Button
                        icon={<Check />}
                        disabled={saving}
                        text={langStrings.oftenUsed.confirm}
                        onClick={onConfirm} />
                </div>}
                title={langStrings.questionDialogTitle}
                onClose={onCancel}
            >
                <Typography variant="h6" gutterBottom>
                    {langStrings.questionSkillHelper}
                </Typography>

                <RowElement title={langStrings.oftenUsed.employee}>
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        <EmployeePicture
                            avatarSize={38}
                            openDialogOnClick
                            employee={{
                                id: employee.id,
                                pictureId: employee.pictureId,
                                firstName: firstName,
                                lastName: lastName,
                            }}
                        />
                        <Typography variant="body1" style={{ flexWrap: 'nowrap', textWrap: 'nowrap' }}>
                            {employee.title}
                        </Typography>
                    </div>
                </RowElement>
                {
                    smallSkill && (
                        <>
                            <RowElement title={langStrings.questioningSkillLevelFor}>
                                {smallSkill.title}
                            </RowElement>
                        </>
                    )
                }
                {
                    description && (
                        <RowElement title={langStrings.description}>
                            <ExpandableText>
                                <FormatedTextInput value={description} readonly={true} />
                            </ExpandableText>
                        </RowElement>
                    )
                }
                {
                    smallSkill &&
                    <RowElement title={langStrings.actualRating}>
                        {currentLevelDefinition?.title || 'Nicht definiert'}
                        <Typography variant="caption" display="block" style={{ marginTop: 7 }} gutterBottom>
                            {currentLevelDefinition?.description}
                        </Typography>
                    </RowElement>
                }

                <RowElement title={langStrings.ratingSuggestion}>
                    <RateBar

                        level={userRating}
                        name={smallSkill?.title || ''}
                        setValue={!saving ? setUserRating : () => { }}
                        style={{ width: '100%' }}
                    />
                </RowElement>
                <TextField
                    disabled={saving}
                    fullWidth
                    label={langStrings.questionDialogMessage}
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    margin="normal"
                />
            </Dialog >
        );
    };

export function useQuestionEmployeeSkillDialog()
{
    const { openDialog } = useContext(DialogManagerContext);
    const questionEmployeeSkillDialog = (employeeId: number, skillId: number, level: number | undefined) =>
    {
        return new Promise<boolean>((resolve) =>
        {
            const dialog = (
                <QuestionEmployeeSkillDialog
                    id="QuestionEmployeeSkillDialog"
                    employeeId={employeeId}
                    skillId={skillId}
                    level={level}
                    resolve={(result) => resolve(result)}
                />
            );
            openDialog(dialog);
        });
    };

    return questionEmployeeSkillDialog;
}