import React from 'react';
import { AppState } from '@store/store';
import { Check, Edit, FormatPaint, StarHalf, Diversity3, LockPerson, PersonAdd, Sync, Calculate, Info, TableChart, Surfing, Email, AccessTime, Api, Microsoft } from '@mui/icons-material';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IStyleDTO } from '@sharedInterfaces/ICompanySettings';
import { setCompanyName } from '@store/reducer/companyReducer';
import { Tabs, Tab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InnerLayout from '@sharedReact/Layouts/InnerLayout/InnerLayout';
import './SettingsPage.css';
import Company from '@src/Objects/Company';
import changeCompanyName from '@src/APIs/graphQl/Company/changeCompanyName';
import getCompanySettings from '@src/APIs/graphQl/Company/getCompanySettings';

import EditLevelList from '../../Components/Settings/EditLevelList/EditLevelList';
import EditOuList from '../../Components/Settings/EditOuList/EditOuList';
import EditPermissionGroupsList from '../../Components/Settings/EditPermissionGroupsList/EditPermissionGroupsList';
import LoadingBox from '../../sharedReact/General/LoadingBox/LoadingBox';
import StyleSettings from '../../Components/Settings/StyleSettings/StyleSettings';
import TextInput from '../../Components/formsControls/inputs/TextInput/TextInput';

import { CalculationsTab } from './CalculationsTab';
import { ClockodoSyncTab } from './ClockodoSyncTab';
import { GeneralTab } from './GeneralTab';
import { HolidayTab } from './HolidayTab';
import { RegistrationTab } from './RegistrationTab';
import EmailSettingsPage from './EmailSettingsPage';
import RestApiTab from './RestApiTab';
import { MicrosoftSettingsTab } from './MicrosoftSettingsTab';
interface SettingsPageProps
{
    tabId: ESettingsTabs
    style?: IStyleDTO
    setStyle?: (val: IStyleDTO) => void
}

/**
 * Function component for the SettingsPage.
 * @param {SettingsPageProps} tab - The tab parameter.
 * @param {SettingsPageProps} style - The style parameter.
 * @param {SettingsPageProps} setStyle - The setStyle parameter.
 * @returns {JSX.Element} The SettingsPage component.
 */
function SettingsPage({ tabId, style, setStyle }: SettingsPageProps)
{
    const navigate = useNavigate();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const dispatch = useDispatch();
    const companyId = useSelector((state: AppState) => state.company.companyId);
    const companyName = useSelector((state: AppState) => state.company.name);
    const width = useSelector((state: AppState) => state.windowSize.width);
    const offline = useSelector((state: AppState) => state.client.offline);
    const scrollTabs = width < 700;
    const wrappedTab = width < 900;

    const [company, setCompany] = React.useState<Company | null>(null);
    const [editName, setEditName] = React.useState<string>("");
    const [saving, setSaving] = React.useState<boolean>(false);
    const loadCompanySettings = () =>
        getCompanySettings(companyId, (companySettings) =>
        {
            setCompany(companySettings);
        });
    React.useEffect(() =>
    {
        loadCompanySettings();
    }, [companyId]);

    if (offline) return <InnerLayout
        title={
            <div className="settingsTitle">
                {companyName}
            </div>
        }>
        <div className="companySettings">
            {langStrings.offline}
        </div>
    </InnerLayout >


    if (!company) return <LoadingBox />

    const mainTabs = [{
        icon: <Info />,
        label: langStrings.general,
        key: 0,
        tabs: [{
            url: `/${ELinks.SETTINGS}`,
            icon: <Info />,
            label: langStrings.general,
            key: ESettingsTabs.GENERAL,
            render: <GeneralTab company={company} setCompany={setCompany}
            />,
        },
        {
            url: `/${ELinks.SETTINGS_STYLE}`,
            icon: <FormatPaint />,
            label: langStrings.styleTitle,
            key: ESettingsTabs.STYLE,
            render: (tabId === ESettingsTabs.STYLE && style && setStyle) &&
                <StyleSettings style={style} setStyle={setStyle} />
        },
        {
            url: `/${ELinks.SETTINGS_LEVELS}`,
            icon: <StarHalf />,
            label: langStrings.levelDefinitions,
            key: ESettingsTabs.LEVELS,
            render: (company.level.length &&
                <EditLevelList

                    level={company.level}
                    setCompany={setCompany}
                />
            )
        }],
    },
    {
        icon: <Diversity3 />,
        label: langStrings.userAndOUs,
        key: 1,
        tabs: [{
            url: `/${ELinks.SETTINGS_ORGUNITS}`,
            icon: <Diversity3 />,
            label: langStrings.orgUnits,
            key: ESettingsTabs.ORGUNITS,
            render: <EditOuList
                employees={company.employees}
                OUs={company.OUs}
                permissionGroups={company.permissionGroups}
                setCompany={setCompany}
            />
        },
        {
            url: `/${ELinks.SETTINGS_REGISTRATION}`,
            icon: <PersonAdd />,
            label: langStrings.registration,
            key: ESettingsTabs.REGISTRATION,
            render: <RegistrationTab
                setCompany={setCompany}
                company={company}
            />
        },
        {
            url: `/${ELinks.SETTINGS_PERMISSIONS}`,
            icon: <LockPerson />,
            label: langStrings.permissions,
            key: ESettingsTabs.PERMISSIONS,
            render: <EditPermissionGroupsList
                permissionGroups={company.permissionGroups}
                employees={company.employees}
                setCompany={setCompany}
            />
        }]
    },
    {
        icon: <Sync />,
        label: langStrings.synchronisation,
        key: 2,
        tabs: [
            {
                url: `/${ELinks.SETTINGS_MICROSOFT}`,
                icon: <Microsoft />,
                label: langStrings.microsoft,
                key: ESettingsTabs.MICROSOFT,
                render: <MicrosoftSettingsTab
                    company={company}
                    setCompany={setCompany}
                    reload={loadCompanySettings}
                />
            },
            {
                url: `/${ELinks.SETTINGS_CLOCKODO}`,
                icon: <AccessTime />,
                label: langStrings.clockodo,
                key: ESettingsTabs.CLOCKODO,
                render: <ClockodoSyncTab
                    clockodoSync={company.clockodoSync}
                    clockodo={company.clockodo}
                    setCompany={setCompany}
                    reload={loadCompanySettings}
                />
            },
            {
                url: `/${ELinks.SETTINGS_HOLIDAYS}`,
                icon: <Surfing />,
                label: langStrings.holidays,
                key: ESettingsTabs.HOLIDAYS,
                render: <HolidayTab
                    clockodoSync={company.clockodoSync.sync}
                    holidaySettings={company.holidaySettings}
                    setHolidaySettings={(newHolidaySettings) =>
                    {
                        setCompany((prevCompany) =>
                        {
                            if (prevCompany)
                            {
                                return { ...prevCompany, holidaySettings: newHolidaySettings };
                            }
                            return null;
                        });
                    }}
                />
            },
            {
                url: `/${ELinks.SETTINGS_RESTAPI}`,
                icon: <Api />,
                label: langStrings.restAPI,
                key: ESettingsTabs.RESTAPI,
                render: <RestApiTab
                    apiKeys={company.apiKeys}
                    setCompany={setCompany}
                />
            },
        ]
    },
    {
        icon: <TableChart />,
        label: langStrings.dataTabTitle,
        key: 3,
        tabs: [{
            url: `/${ELinks.SETTINGS_CALCULATIONS}`,
            icon: <Calculate />,
            label: langStrings.calculations,
            key: ESettingsTabs.CALCULATIONS,
            render: <CalculationsTab
                calculations={company.calculations}
                setCompany={setCompany}
            />
        },
        //Email Settings
        {
            url: `/${ELinks.SETTINGS_EMAIL}`,
            icon: <Email />,
            label: langStrings.emailSettings,
            key: ESettingsTabs.EMAIL,
            render: <EmailSettingsPage
                emailSettings={company.emailSettings}
                microsoftConnectionStatus={company.microsoftSettings.connectionState}
                globalEmailSupport={company.globalEmailSupport}
                setCompany={setCompany}
            />
        },
        ]
    },
    ]

    const activeMainTab = mainTabs.find(mt => mt.tabs.some(st => st.key === tabId))
    const activeMainTabId = activeMainTab ? activeMainTab.key : mainTabs[0].key;
    const activeSubTab = mainTabs.find(mt => mt.tabs.some(st => st.key === tabId))?.tabs
        .find(st => st.key === tabId)

    return (
        <InnerLayout title={
            <div className="settingsTitle">
                {
                    editName === "" ? companyName : <TextInput
                        value={editName}
                        onChange={function (val: string): void
                        {
                            setEditName(val);
                        }}
                    />
                }
                <div className="editTitle">
                    <div className="editTitleIcon"
                        style={{ display: saving || company.name === '' ? 'none' : 'inherit' }}
                        onClick={() =>
                        {
                            if (editName === "")
                            {
                                setEditName(company.name)
                            } else
                            {
                                if (company.name !== editName)
                                    setSaving(true);
                                changeCompanyName(editName)
                                    .then((result) =>
                                    {
                                        setCompany(result);
                                        dispatch(setCompanyName(result.name))
                                        setEditName("");
                                        setSaving(false);
                                    })
                                    .catch(() =>
                                    {
                                        alert(langStrings.errorChangeName);
                                        setTimeout(() =>
                                        {
                                            setSaving(false);
                                        }, 3000);
                                    })
                            }
                        }}>
                        {editName === "" ? <Edit /> : <Check />}
                    </div>
                </div>
            </div>
        }>
            <div className="companySettings">
                <Tabs className='tabControl' value={activeMainTabId}
                    // onChange={() => { }}
                    aria-label=""
                    variant={scrollTabs ? 'scrollable' : 'fullWidth'}
                    scrollButtons="auto"
                    centered={scrollTabs ? false : true}
                    textColor='primary'
                    indicatorColor='primary'
                    style={{ borderBottom: '1px solid #ccc' }}
                >
                    {mainTabs.map((tab) => <Tab
                        value={tab.key}
                        key={tab.key}
                        wrapped={wrappedTab ? true : undefined}
                        onClick={() => navigate(tab.tabs[0].url)}
                        icon={tab.icon}
                        label={tab.label} />)}
                </Tabs>

                <Tabs className='tabControl' value={tabId}
                    // onChange={() => { }}
                    textColor='secondary'
                    indicatorColor='secondary'
                    aria-label=""
                    variant={'fullWidth'}
                    scrollButtons="auto"
                    style={{ borderBottom: '1px solid #ccc' }}
                    centered={true}
                >
                    {activeMainTab?.tabs.map((tab) => <Tab
                        key={tab.key}
                        value={tab.key}
                        color='secondary'
                        sx={{ fontSize: '0.7rem', minWidth: '50px', padding: '6px' }}
                        wrapped={wrappedTab ? true : undefined}
                        onClick={() => navigate(tab.url)}
                        icon={tab.icon}
                        label={tab.label}
                    />)}
                </Tabs>
                {activeSubTab ? activeSubTab.render : <LoadingBox />}
            </div>
        </InnerLayout >
    );
}

export default SettingsPage;




export enum ESettingsTabs
{
    GENERAL = 0,
    STYLE = 1,
    LEVELS = 2,
    REGISTRATION = 3,
    ORGUNITS = 4,
    PERMISSIONS = 5,
    CALCULATIONS = 6,
    // SYNCHRONISATION = 7,
    CLOCKODO = 8,
    HOLIDAYS = 9,
    EMAIL = 10,
    RESTAPI = 11,
    MICROSOFT = 12,
}

