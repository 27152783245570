import React from 'react';
import { ICompanyUser } from '@sharedInterfaces/ICompanySettings';
import './EmployeeSelect.css';
import { generateFullName } from '@sharedInterfaces/globalHelper';
import { ISmallEmployee } from '@sharedInterfaces/IWhoIAm';

interface EmployeeSelectProps
{
    employees: ICompanyUser[] | ISmallEmployee[]
    selectedEmployee?: number
    setSelectedEmployee: (val: number | undefined) => void
}
function isCompanyUser(employee: ICompanyUser | ISmallEmployee): employee is ICompanyUser
{
    return (employee as ICompanyUser).firstname !== undefined;
}
/**
 * EmployeeSelect component.
 * 
 * @param {Object} props - The props for the EmployeeSelect component.
 * @param {EmployeeSelectProps} props - The props for the EmployeeSelect component.
 * @returns {JSX.Element} - The rendered EmployeeSelect component.
 */
function EmployeeSelect(props: EmployeeSelectProps)
{
    const employees = props.employees;
    employees.sort((a, b) =>
    {
        if (isCompanyUser(a) && isCompanyUser(b))
        {
            const compareLastname = a.lastname.localeCompare(b.lastname);
            if (compareLastname !== 0)
            {
                return compareLastname;
            }
            return a.firstname.localeCompare(b.firstname);
        } else if (!isCompanyUser(a) && !isCompanyUser(b))
        {
            return a.title.localeCompare(b.title);
        }
        return 0;
    });
    return (
        <div className="emplyeeSelect">
            <select
                value={props.selectedEmployee}
                onChange={(e) =>
                {
                    props.setSelectedEmployee(parseInt(e.target.value))
                }}
            >
                {(
                    props.selectedEmployee === 0
                    || props.selectedEmployee === undefined
                ) && <option value={0}></option>}

                {employees.map(e =>
                {
                    const fullName = isCompanyUser(e)
                        ? generateFullName(e.firstname, e.lastname)
                        : `${e.title}`; // Adjust as needed for ISmallEmployee

                    return (
                        <option key={e.id} value={e.id}>
                            {fullName}
                        </option>
                    );
                })}
            </select>
        </div>
    );
}

export default EmployeeSelect;


