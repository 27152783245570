import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import getOpportunity from '@src/APIs/graphQl/Opportunity/getOpportunity';
import OpportunityPage from '@src/App/NewLayout/Pages/Opportunities/OpportunityPage/OpportunityPage';

import Overlay from '../Overlay/Overlay';

import './OpportunityOverlay.css';



interface OpportunityOverlayProps
{
    opportunityId: number;
    children: React.ReactNode;
    disabled?: boolean;
}

/**
* Represents an OpportunityOverlay component.
* 
* @param {OpportunityOverlayProps} props - The props of the OpportunityOverlay component.
* @returns {JSX.Element} The rendered OpportunityOverlay component.
*/
function OpportunityOverlay(props: OpportunityOverlayProps)
{
    const opportunity = useSelector((state: AppState) => state.opportunity.find(o => o.id === props.opportunityId));
    const permissions = useSelector((state: AppState) => state.permissions);
    const isAllowed = checkPermissions('Opportunities', 'Retrieve', permissions) || checkPermissions('Opportunities', 'RetrieveOwn', permissions);
    const disabled = !isAllowed || props.disabled;

    return (
        <Overlay
            disabled={disabled}
            triggerItem={props.children}
            loaded={opportunity !== null}
            onFirstOpen={() =>
            {
                getOpportunity(props.opportunityId);
            }}>
            {opportunity && <OpportunityPage
                entity={opportunity}
                inDialog={true}
            />}
        </Overlay>
    );
}

export default OpportunityOverlay;
