import React from 'react';
import { ISmallCertificate } from '@sharedInterfaces/IWhoIAm';
import { AppState } from '@store/store';

import EntitySearchBox from '../EntitySearchBox/EntitySearchBox';

interface CertificateSearchBoxProps
{
    filteredAllCertificates: ISmallCertificate[]
    newItem: IListElement
    size?: 'medium' | 'small' | 'verysmall'
    allowNew?: true
    clearOnSelect?: true
    onSelectedCertificate: (selectedCertificate: IListElement) => void;
}
interface IListElement
{
    title: string,
    id?: number
    // otherNames: string[]
}
/**
 * Component representing a search box for certificates.
 * @param {CertificateSearchBoxProps} allCertificates - List of all certificates.
 * @param {function} onSelectedCertificate - Callback function when a certificate is selected.
 * @param {function} onTextChanged - Callback function when the text in the search box changes.
 * @returns {JSX.Element} The search box element.
 */
function CertificateSearchBox({ filteredAllCertificates, newItem, clearOnSelect, size, allowNew, onSelectedCertificate, }: CertificateSearchBoxProps)
{
    return <EntitySearchBox
        filteredItems={filteredAllCertificates}
        newItem={newItem}
        allowNew={allowNew}
        size={size}
        clearOnSelect={clearOnSelect}
        setNewItem={newItem =>
        {
            onSelectedCertificate(newItem)
        }}
        allItemsSelector={(state: AppState) => state.company.allCertificates}
    />


}

export default CertificateSearchBox;
