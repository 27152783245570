import React, { Suspense, useEffect } from 'react';
import { SvgIconProps } from '@mui/material';
import { useSelector } from 'react-redux';
import './EntitiesPage.css';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import ContentHeader, { ITabConfig } from '@sharedReact/Layouts/LayoutElements/ContentHeader/ContentHeader';
import { AppState } from '@store/store';
import Content from '@sharedReact/Layouts/LayoutElements/Content/Content';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import Button from '@sharedReact/General/Button/Button';
import { Entity } from '@sharedReact/Objects/Entity';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import { hashToJson, jsonToHash } from '@src/helper/helper';

import { ViewMenu } from './ViewMenu';
import { IViewType, ViewTypeMenu } from './ViewTypeMenu';




export enum EViewType
{
    TABLE = 'table',
    CARD = "card",
    TREE = "tree",
    ORGUNIT = "orgunit"
}

export interface IView<T extends Entity>
{
    id: string,
    title: string
    filter: (entity: T) => boolean
}

export interface IEntitiesPageAction
{
    id: string
    text: string
    icon: React.ReactElement<SvgIconProps>
    filter: (selectedCount: number) => boolean
    action: (selectedIDs: number[]) => void
}

interface EntitiesPageProps<T extends Entity>
{
    title: string
    entities: T[] | null
    types: IViewType<T>[]
    views: IView<T>[]
    actions: IEntitiesPageAction[]
    selected: number[]
    loadingError: CustomGraphQLError | null;
    tabs?: ITabConfig[]
    filter: (entity: T, selectedView: IView<T>, searchText: string) => boolean
    setSelected: (selected: number[]) => void
    children?: JSX.Element
}

const CONST_TABLE_MIN_WITH = 350;
/**
 * Represents a page displaying a list of entities.
 * 
 * @param {EntitiesPageProps<T>} props - The props for the EntitiesPage component.
 * @returns {JSX.Element} - The rendered EntitiesPage component.
 */
function EntitiesPage<T extends Entity>(props: EntitiesPageProps<T>)
{
    const width = useSelector((state: AppState) => state.windowSize.width);
    const types = props.types.filter(t => (t.id !== EViewType.TABLE) ||
        ((!t.minWidth && width >= CONST_TABLE_MIN_WITH) ||
            (t.minWidth && t.minWidth < width)));
    const loaded = props.entities !== null;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).entityPage;
    const offline = useSelector((state: AppState) => state.client.offline);
    const [searchText, setSearchText] = React.useState<string>("");
    const [type, setType] = React.useState<IViewType<T>>(types[0]);
    const [selectedView, setSelectedView] = React.useState<IView<T>>(props.views[0]);

    useEffect(() =>
    {
        const parameter = hashToJson<{ viewId: string, typeId: string, search: string }>(window.location.hash);
        const { viewId, typeId, search } = parameter;

        const searchedType = types.find(t => t.id === typeId);
        const initialType = typeId && searchedType ? searchedType : types[0];
        const searchedView = props.views.find(v => v.id === viewId);
        const initialView = viewId && searchedView ? searchedView : props.views[0];
        setType(initialType);
        setSelectedView(initialView);
        setSearchText(search ? decodeURI(search) : "");
    }, [types, props.views]); // Removed viewId and typeId from dependencies, as they are not used directly

    const changeSearch = (search: string) =>
    {
        const parameter = hashToJson<{ viewId: string, typeId: string, search?: string }>(window.location.hash);
        setSearchText(search || "");
        parameter.search = search;
        if (search === "")
        {
            delete parameter.search
        }
        window.location.hash = jsonToHash(parameter)
    }
    const changeSelectedView = (view: IView<T>) =>
    {
        const parameter = hashToJson<{ viewId: string, typeId: string, search: string }>(window.location.hash);
        setSelectedView(view);
        props.setSelected([]);
        parameter.viewId = view.id;
        window.location.hash = jsonToHash(parameter)
    }
    const changeType = (type: IViewType<T>) =>
    {
        const parameter = hashToJson<{ viewId: string, typeId: string, search: string }>(window.location.hash);
        setType(type);
        props.setSelected([]);
        parameter.typeId = type.id;
        window.location.hash = jsonToHash(parameter)
    }
    useEffect(
        () =>
        {
            const available = types.some(t => t.id === type.id);
            if (!available)
            {
                changeType(types[0])
            }
        },
        [width, types]
    )
    const error = props.loadingError;
    const errorMessage = error ?
        (
            error.statusCode === 401 ?
                langStrings.notAllowed :
                error.statusCode === 408 ?
                    langStrings.offline :
                    error.message
        ) :
        '';

    if (error) return <Content contentHeader={
        <ContentHeader
            title={errorMessage}
            tabs={props.tabs}
        >
        </ContentHeader >
    }
    >
        {errorMessage}
    </Content>
    const visibleEntities = props.entities ?
        props.entities.filter((e) => props.filter(e, selectedView, searchText)) :
        [];
    return <Content contentHeader={<ContentHeader
        title={props.title}
        tabs={props.tabs}
        styleType='flex'
    >
        <ViewMenu views={props.views} selectedView={selectedView} setSelectedView={changeSelectedView} />
        <ViewTypeMenu types={types} selected={type} onChange={changeType} />
    </ContentHeader >} >
        {(loaded && props.entities) ?
            <div className='entitiesPage'>
                <div className="searchEntityBox">
                    {langStrings.search}:
                    <input type="text"
                        name="SearchText"
                        className="searchBox"
                        value={searchText}
                        onChange={(e) => changeSearch(e.target.value)}
                    />
                </div>
                {props.actions.length > 0 &&
                    <div className={'buttons'}>
                        {/* + (!props.actions.some(a => a.filter(props.selected.length)) ? ' hidden' : '')}> */}
                        {
                            props.actions.map(action => <Button
                                key={action.id}
                                size={'normal'}
                                text={action.text}
                                icon={action.icon}
                                disabled={!action.filter(props.selected.length) || offline}
                                onClick={() => action.action(props.selected)} />)
                        }
                    </div>
                }
                <Suspense fallback={<LoadingBox />}>
                    {type.renderContainer(
                        props.entities,
                        visibleEntities,
                        width,
                        props.selected
                    )}
                </Suspense>
                {props.children &&
                    props.children
                }
            </div>
            : <LoadingBox />}
    </Content>;
}

export default EntitiesPage;
