import React from 'react';
import { useSelector } from 'react-redux';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import getSkill from '@src/APIs/graphQl/Skill/getSkill';
import SkillPage from '@src/App/NewLayout/Pages/Skills/SkillPage/SkillPage';

import Overlay from '../Overlay/Overlay';
import './SkillOverlay.css';



interface SkillOverlayProps
{
    skillId: number;
    children: React.ReactNode
    disabled?: boolean
}

/**
 * SkillOverlay
 * 
 * @param {SkillOverlayProps} props - The props for the SkillOverlay component
 * @returns {JSX.Element} - The rendered SkillOverlay component
 */
function SkillOverlay(props: SkillOverlayProps)
{
    const entity = useSelector((state: AppState) => state.skills.find(a => a.id === props.skillId))
    const permissions = useSelector((state: AppState) => state.permissions);
    const isAllowed = checkPermissions('Skills', 'Retrieve', permissions) || checkPermissions('Skills', 'RetrieveOwn', permissions)
    const disabled = !isAllowed || props.disabled
    return (
        <Overlay
            disabled={disabled}
            triggerItem={props.children}
            loaded={entity !== null}
            onFirstOpen={() =>
            {
                getSkill(props.skillId);
            }}>
            {entity && <SkillPage
                entity={entity}
                inDialog={true}
            />}
        </Overlay>
    )
}

export default SkillOverlay;