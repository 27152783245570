import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import getCompetence from '@src/APIs/graphQl/Competence/getCompetence';
import CompetencePage from '@src/App/NewLayout/Pages/Competences/CompetencePage/CompetencePage';

import Overlay from '../Overlay/Overlay';
import './CompetenceOverlay.css';


interface CompetenceOverlayProps
{
    competenceId: number;
    children: React.ReactNode;
    disabled?: boolean;
}

/**
 * CompetenceOverlay
 *
 * @param {CompetenceOverlayProps} props - The props being passed to the CompetenceOverlay component.
 * @returns {JSX.Element} The CompetenceOverlay component.
 */
function CompetenceOverlay(props: CompetenceOverlayProps)
{
    const competence = useSelector((state: AppState) => state.competences.find(c => c.id === props.competenceId));
    const permissions = useSelector((state: AppState) => state.permissions);
    const isAllowed = checkPermissions('Competencies', 'Retrieve', permissions) || checkPermissions('Competencies', 'RetrieveOwn', permissions);
    const disabled = !isAllowed || props.disabled;

    return (
        <Overlay
            disabled={disabled}
            triggerItem={props.children}
            loaded={competence !== null}
            onFirstOpen={() =>
            {
                getCompetence(props.competenceId);
            }}>
            {competence && <CompetencePage
                entity={competence}
                inDialog={true}
            />}
        </Overlay>
    );
}

export default CompetenceOverlay;
