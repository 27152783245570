import { IRestDocuTexts } from "@sharedInterfaces/Language/languageTexts/restDocu";
import { stageContext } from "@src/globals";

import { ApiEndpoint } from "../RestApiDocuPage";

export const endpointRequestGet = (langStrings: IRestDocuTexts): ApiEndpoint =>
({
    title: langStrings.endpointGetRequestTitle,
    endpoint: `${stageContext.apiGatewayRestDomain}/v1/request/<requestId>`,
    method: 'GET',
    description: langStrings.endpointGetRequestDescription,
    parameters: [
        {
            name: 'requestId',
            required: true,
            type: 'PATH-PARAMETER',
            description: langStrings.endpointGetRequestId,
        },
        {
            name: 'includeSuggestedEmployees',
            required: false,
            type: 'GET-PARAMETER',
            description: langStrings.endpointGetRequestOptionsIncludeSuggestedEmployees + " (true/false)",
        },
    ],
    response: {
        type: 'object',
        object: 'RequestResponse',
        description: langStrings.endpointGetRequestResponse,
    }
});