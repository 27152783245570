import React, { ChangeEvent, useRef, useState } from 'react';
import { Edit, Done, Upload, Translate } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { ELanguage, findMatchingLanguage, getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { CONST_DEFAULT_STARTPAGE, IStartPagesTexts } from '@sharedInterfaces/Language/languageTexts/startPages';
import { AppState } from '@store/store';
import { setEmployeeDescription, setEmployeeLang, setEmployeePictureId } from '@store/reducer/employeeReducer';
import './GeneralInfo.css';
import { setLanguage, setStartpage } from '@store/reducer/employeeSettingsReducer';
import Button from '@sharedReact/General/Button/Button';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import StartPageSelect from '@src/Components/formsControls/inputs/StartPageSelect/StartPageSelect';
import { uploadFile } from '@src/Components/Dialogs/AnalyseSkillsDialog/AnalyseCvDialog';
import updateMyDescription from '@src/APIs/graphQl/Employee/updateMyDescription';
import updateStartPage from '@src/APIs/graphQl/Employee/updateStartPage';
import getUploadURLProfilePicture from '@src/APIs/graphQl/Employee/getUploadURLProfilePicture';
import getEmployeePictureId from '@src/APIs/graphQl/Employee/getEmployeePictureId';
import LanguageSelect from '@src/Components/formsControls/inputs/LanguageSelect/LanguageSelect';
import updateUserLanguage from '@src/APIs/graphQl/Employee/updateUserLanguage';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
/**
 * Function to display general information.
 *
 * @returns {JSX.Element} - The JSX element for displaying general information.
 */
function GeneralInfo()
{
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;
    const startPage = useSelector((state: AppState) => state.employeeSettings.startPage);
    const savedLanguage = useSelector((state: AppState) => state.employeeSettings.language2);
    const offline = useSelector((state: AppState) => state.client.offline);
    const OUs = useSelector((state: AppState) => state.OUs);
    const employee = useSelector((state: AppState) => state.employee);
    const user = useSelector((state: AppState) => state.user);
    const [editing, setEditing] = React.useState(false as boolean);
    const [editDescription, setEditDescription] = React.useState("" as string);
    if (!OUs || !employee) return <span></span>
    const OU = OUs.find(o => o.employees.includes(employee.id));
    return (
        <div className="infoBox">
            <div className='label'>{langStrings.firstName}:</div><div className="info"> {employee.firstName}</div><div />
            <div className='label'>{langStrings.lastName}:</div><div className="info"> {employee.lastName}</div><div />
            <div className='label'>E-Mail:</div><div className="info mail"> {user.email}</div><div />
            <div className='label'>{langStrings.orgUnit}:</div><div className="info"> {OU ? OU.title : '-'}</div><div />

            {/* <div>
                    <div>Browser-Sprache:</div><div>{window.navigator.languages.join(', ')}</div>
                </div> */}
            {/* <div className="infoLine">
                    <div>EmployeeId:</div><div className="info"> {employee.id}</div>
                </div> */}
            <div className='label'>{langStrings.description}:</div>
            <div>
                {editing ? <FormatedTextInput value={editDescription} onChange={(val: string) =>
                {
                    setEditDescription(val);
                }} /> : <FormatedTextInput value={employee.description} />}
            </div>
            <div className="info button">
                <IconButton
                    disabled={!editing && offline}
                    onClick={async () =>
                    {
                        if (editing)
                        {
                            if (editDescription === employee.description)
                            {
                                return setEditing(false);
                            }
                            await updateMyDescription(editDescription)
                                .then((result) =>
                                {
                                    if (!result)
                                    {
                                        return;
                                    }
                                    dispatch(setEmployeeDescription(editDescription));
                                    setEditing(false);
                                })
                                .catch(ex =>
                                {
                                    showErrorDialog(ex);
                                })
                        } else
                        {
                            setEditDescription(employee.description)
                            setEditing(true);
                        }
                    }}>
                    {editing ? <Done /> : <Edit />}
                </IconButton></div>
            <div className='label'>{langStrings.defaultPage}:</div>
            <div className="info">
                <StartPageSelect
                    disabled={offline}
                    startPage={startPage ? startPage : CONST_DEFAULT_STARTPAGE}
                    setStartPage={(val: keyof IStartPagesTexts) =>
                    {
                        updateStartPage(val)
                            .then(() =>
                            {
                                dispatch(setStartpage(val));
                            })
                            .catch(er =>
                            {
                                showErrorDialog(er);
                            });
                    }
                    }
                />
            </div>
            <div />
            <div className='label' style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <Translate fontSize='small' />
                {langStrings.oftenUsed.language}
                :
            </div>
            <div className='info'>
                <LanguageSelect
                    allowBrowserDefault
                    language={savedLanguage ? savedLanguage : 'default' as ELanguage}
                    setLanguage={(val: ELanguage | 'default') =>
                    {
                        const realValue: ELanguage | null = val === 'default' ? null : val;
                        updateUserLanguage(realValue)
                            .then(() =>
                            {
                                dispatch(setLanguage(realValue));
                                if (realValue)
                                {
                                    dispatch(setEmployeeLang(realValue))
                                } else
                                {
                                    dispatch(setEmployeeLang(findMatchingLanguage(window.navigator.languages)))
                                }
                            })
                            .catch(er =>
                            {
                                showErrorDialog(er);
                            });
                    }} />
            </div>
            <div />
            <div className='label'>{langStrings.profilePicture}:</div><div className="info">
                <ImageUploadButton />
            </div><div />

        </div>
    );
}

export default GeneralInfo;


const ImageUploadButton = () =>
{
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;
    const employeeId = useSelector((state: AppState) => state.employee.id);
    const actualPictureId = useSelector((state: AppState) => state.employee.pictureId);
    const offline = useSelector((state: AppState) => state.client.offline);
    // const companyId = useSelector((state: AppState)=> state.company.companyId);
    const [loadingText, setLoadingText] = useState<string>("");
    const [loadingError, setLoadingError] = useState<string>("");

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () =>
    {
        if (!fileInputRef.current) return;
        fileInputRef.current.click();
    };

    const checkForUpdate = () =>
    {
        getEmployeePictureId(employeeId, pictureId =>
        {
            if (pictureId !== actualPictureId)
            {
                dispatch(setEmployeePictureId(pictureId))
                setLoadingText("");
            } else
            {
                setTimeout(checkForUpdate, 3000);
            }
        }).catch(ex =>
        {
            setLoadingText("");
            setLoadingError(ex.toString());
        })
    }

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) =>
    {
        if (!e.target.files) return;
        const file = e.target.files[0];
        setLoadingText(langStrings.uploading);
        await getUploadURLProfilePicture(async (post) =>
        {
            if (!post) return;
            await uploadFile(post, file)
                .catch(ex => showErrorDialog(ex));
            setLoadingText(langStrings.transforming);
            setTimeout(checkForUpdate, 3000);
        }).catch(ex =>
        {
            setLoadingText("");
            setLoadingError(ex.toString());
        });
    };

    return (
        <div style={{ marginTop: 5, maxWidth: 150 }}>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
            />
            <Button disabled={offline} icon={<Upload />} text={langStrings.upload} onClick={handleButtonClick} />
            {(loadingText && !offline) && <p>{loadingText}</p>}
            {loadingError && <p>{loadingError}</p>}
        </div>
    );
};
