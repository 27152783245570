import { useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import Opportunity from '@src/Objects/Opportunity';
import CardBox from '@src/Components/CardBox/CardBox';
import OpportunityDialog from '@src/Components/Dialogs/OpportunityDialog/OpportunityDialog';
import './OpportunityCard.css';

interface OpportunityCardProps
{
    opportunity: Opportunity
    selected?: boolean
    onSelectChange?: (state: boolean) => void
}

/**
 * OpportunityCard component.
 *
 * @param {OpportunityCardProps} props - The props for the OpportunityCard component.
 * @returns {JSX.Element} The rendered OpportunityCard element.
 */
function OpportunityCard(props: OpportunityCardProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).opportunities;
    const navigate = useNavigate();
    const opportunity = props.opportunity;
    return (
        <CardBox
            menu={
                <div>
                    <MenuItem
                        onClick={() =>
                        {
                            return openDialog(
                                <OpportunityDialog
                                    id="OpportunityDialog"
                                    opportunity={opportunity}
                                />);
                        }}
                    >
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText>{langStrings.edit}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                        {
                            opportunity.delete()
                        }}
                    >
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>{langStrings.delete}</ListItemText>
                    </MenuItem>
                </div>
            }
            onSelectChange={props.onSelectChange}
            selected={props.selected}
            title={opportunity.title}
            className={'opportunityCard'}
            onClick={() =>
            {
                navigate(entityTypeToLink(opportunity.id, EEntityType.OPPORTUNITY))
            }}
        >

            {opportunity.customer && <div className="information">{`${langStrings.customer}: ${opportunity.customer}`}</div>}
            {opportunity.partner && <div className="information">{`${langStrings.partner}: ${opportunity.partner}`}</div>}
            <div className="information">{`${langStrings.utilization}: ${Math.round(opportunity.utilization * 10) / 10}%`}</div>
            <div className="information">{`${langStrings.remote}: ${Math.round(opportunity.remote * 10) / 10}%`}</div>
            <div className="information">{`${langStrings.dayPrice}: ${opportunity.dayPrice.toLocaleString('de-DE')}€`}</div>
            {opportunity.projectStart && <div className="information">{`${langStrings.startDate}: ${opportunity.projectStart.toLocaleDateString()}`}</div>}
            {opportunity.projectEnd && <div className="information">{`${langStrings.endDate}: ${opportunity.projectEnd.toLocaleDateString()}`}</div>}
            {opportunity.headCount && <div className="information">{`${langStrings.employees}: ${opportunity.headCount}`}</div>}
            <div className="information">{`${langStrings.active}: ${opportunity.active ? langStrings.yes : langStrings.no}`}</div>
        </CardBox>
    );
}

export default OpportunityCard;
