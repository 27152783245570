import { EEntityType, EMailCycle } from "./globalEnums";
import { EEmailTransferOption } from "./ICompanySettings";


export interface ISearchSuggestions
{
    id: number,
    type: EEntityType,
    label: string,
    score?: number
}

export type TEntityVersions = {
    [K in EEntityType]: number;
}

export interface ICompanyEmailSettings
{
    exchangeMailbox: string;
    emailTransferOption: EEmailTransferOption;
    lastForecastOn: Date;
    forecastCycle: EMailCycle
    forecastText: string
    sendCertificateReminder: boolean
}

export interface IMicrosoftSettings
{
    tenantId: string | null;
    clientId: string | null;
    certificateThumbprint: string | null;
    certificateStatus: ECertificateStatus;
    connectionState: EMicrosoftConnectionState;
}

export enum EMicrosoftConnectionState
{
    Connected = "connected",
    Disconnected = "disconnected",
    Error = "error",
}
export enum ECertificateStatus
{
    None = "none",
    Valid = "valid",
    Expired = "expired",
    Invalid = "invalid",
}

export const initialMsSettings: IMicrosoftSettings = {
    tenantId: null,
    clientId: null,
    certificateThumbprint: null,
    certificateStatus: ECertificateStatus.None,
    connectionState: EMicrosoftConnectionState.Disconnected,
};
