import { Merge } from '@mui/icons-material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import ICertificate from '@sharedInterfaces/ICertificate';
import { AppState } from '@store/store';
import { setEmployeeCertificates } from '@store/reducer/employeeReducer';
import { setCompanyAllCertificates } from '@store/reducer/companyReducer';
import { setCertificates } from '@store/reducer/certificatesReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import Certificate from '@src/Objects/Certificate';
import mergeCertificates from '@src/APIs/graphQl/Certificate/mergeCertificates';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import ErrorBox from '../../ErrorBox/ErrorBox';

import './MergeCertificatesDialog.css';

interface MergeCertificatesDialogProps
{
    id: string
    certificates: Certificate[]
    resolve: () => void
    certificatesWithoutFilter: Certificate[]
}

/**
 * Component for rendering the MergeCertificatesDialog.
 *
 * @param {MergeCertificatesDialogProps} props - The props containing necessary data for rendering the dialog.
 * @returns {JSX.Element} - The rendered dialog JSX element.
 */
function MergeCertificatesDialog(props: MergeCertificatesDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).certificates;
    const dispatch = useDispatch();
    const employee = useSelector((state: AppState) => state.employee);
    const allCertificates = useSelector((state: AppState) => state.company.allCertificates);

    const certificates = props.certificates;
    const uniqueEmployees = {} as { [key: number]: { title: string, id: number, certificates: { title: string }[] } }
    certificates.forEach((ICertificate) =>
    {
        ICertificate.employees.forEach((employee) =>
        {
            if (uniqueEmployees[employee.id] === undefined)
                uniqueEmployees[employee.id] = {
                    title: employee.title,
                    id: employee.id,
                    certificates: [{ title: ICertificate.title }]
                };
            else
                uniqueEmployees[employee.id].certificates.push({ title: ICertificate.title });
        });
    });
    const employees = Object.values(uniqueEmployees);

    let suggestedCertificate: ICertificate = certificates[0]; // Assume the first certificate has the most employees and highest level

    certificates.forEach((certificate) =>
    {
        if (certificate.employees.length > suggestedCertificate.employees.length)
        {
            suggestedCertificate = certificate;
        }
    });

    const [newCertificateName, setNewCertificateName] = React.useState(suggestedCertificate.title as string);
    const [newDescription, setNewDescription] = React.useState(suggestedCertificate.description as string);
    const [errorText, setErrorText] = React.useState("" as string);
    const [saving, setSaving] = React.useState(false as boolean);

    return (
        <Dialog
            id={props.id}
            title={langStrings.mergeCertificates + ': ' + certificates.map(s => s.title).join(', ')} onClose={function (): void
            {
                props.resolve();
            }}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Merge />}
                        disabled={saving}
                        text={langStrings.merge}
                        size={'small'}
                        onClick={async function (): Promise<void>
                        {
                            setSaving(true);
                            await mergeCertificates(
                                {
                                    id: suggestedCertificate.id,
                                    title: newCertificateName,
                                    description: newDescription
                                },
                                certificates.map(s => s.id)
                            ).catch(ex =>
                            {
                                setErrorText(langStrings.error + ex);
                                setTimeout(() =>
                                {
                                    setSaving(false);
                                }, 2500);
                            }).then((result) =>
                            {
                                if (!result || !employee)
                                {
                                    setErrorText(langStrings.error);
                                    return;
                                }
                                setErrorText("");

                                setSaving(false);
                                const newAllCertificates = allCertificates
                                    .filter(cert => !result.removed.includes(cert.id))
                                    .map(cert => cert.id === result.newCertificate.id ? { ...cert, title: result.newCertificate.title, otherNames: result.otherNames } : cert);

                                // Ändern Sie newEmployeeCertificates
                                const newEmployeeCertificates = employee.certificates
                                    .filter(cert => !result.removed.includes(cert.id))
                                    .map(cert => cert.id === result.newCertificate.id ? { ...cert, title: result.newCertificate.title } : cert);

                                // Ändern Sie newCertificates
                                const newCertificates = props.certificatesWithoutFilter
                                    .filter(cert => !result.removed.includes(cert.id))
                                    .map(cert => cert.id === result.newCertificate.id ? { ...cert, title: result.newCertificate.title, description: result.newCertificate.description, employees: result.newCertificate.employees } : cert);

                                // Dispatch actions
                                dispatch(setCompanyAllCertificates(newAllCertificates));
                                dispatch(setEmployeeCertificates(newEmployeeCertificates));
                                dispatch(setCertificates(newCertificates.map(ns => new Certificate(ns))));
                                props.resolve();
                            })
                        }}
                    />
                </div>
            }
        >
            <div className="mergeBox">
                <span>{langStrings.certificatesSelectedForMerge}:</span>
                <ul>
                    {certificates.map(certificate => { return <li key={certificate.id}>{certificate.title}</li> })}
                </ul>

                <span>{langStrings.effectEmployeesWarning} {employees.length} {langStrings.employees}:</span>
                <ul>
                    {employees.map(emp =>
                    {
                        return <li key={emp.id}>{emp.title}
                            <ul>
                                {emp.certificates.map(certificate => <li key={certificate.title}>{certificate.title}</li>)}
                            </ul>
                        </li>
                    })}
                </ul>

                <h2>{langStrings.newCertificate}</h2>
                <div className="newCertificate">
                    <RowElement title={langStrings.certificate} alignTitle="left">
                        <TextInput value={newCertificateName} onChange={s => setNewCertificateName(s)} />
                    </RowElement>
                    <RowElement title={langStrings.description} alignTitle="left">
                        <TextInput value={newDescription} onChange={s => setNewDescription(s)} />
                    </RowElement>
                </div>

                {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
            </div>
        </Dialog>
    );
}
export default MergeCertificatesDialog;