import React from 'react';
import { useSelector } from 'react-redux';
import './EntityPage.css';
import Content from '@sharedReact/Layouts/LayoutElements/Content/Content';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import ContentHeader, { ITabConfig } from '@sharedReact/Layouts/LayoutElements/ContentHeader/ContentHeader';
import { IPermissionActions } from '@sharedInterfaces/IPermissions';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import { Entity } from '@sharedReact/Objects/Entity';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import { ShareButton } from '@src/Components/Buttons/ShareButton/ShareButton';
import { DraggableData, DraggableEvent } from 'react-draggable';

import { EntityPageMenu } from './EntityPageMenu';
import VersionInfoButton from './VersionInfoButton';

interface EnityPageProps
{
    inDialog?: boolean;
    entity?: Entity
    buttons?: JSX.Element
    children?: JSX.Element
    bigLeftBox?: JSX.Element
    informations?: (React.ReactElement<typeof EntityPageInformation> | undefined)[];
    loadingError: CustomGraphQLError | null;
    admin: boolean;
    permissions: IPermissionActions
    tabs?: ITabConfig[]
    menuItems?: (JSX.Element | undefined)[]
    onDrag?: (e: React.DragEvent<HTMLDivElement>) => void
    onDelete?: () => void
    openEditDialog?: () => void;
}

/**
 * EntityPage component
 * 
 * @param {EnityPageProps} props - The props for the EntityPage component
 * @returns {JSX.Element} The rendered EntityPage component
 */
function EntityPage(props: EnityPageProps)
{

    const clearedInformations: React.ReactElement<typeof EntityPageInformation>[] = (props.informations ?
        props.informations.filter(i => i !== undefined) as React.ReactElement<typeof EntityPageInformation>[] :
        [])
    const clonedInformations = clearedInformations.map((inf, index) =>
    {
        return React.cloneElement(inf, { key: index });
    });
    const permissions = props.permissions;

    const entity = props.entity;
    const loaded = entity && entity.id !== -1;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).entityPage;
    const error = props.loadingError;
    const errorMessage = error ?
        (
            error.statusCode === 401 ?
                langStrings.notAllowed :
                error.statusCode === 408 ?
                    langStrings.offline :
                    error.message
        ) :
        '';

    if (error) return <Content contentHeader={
        <ContentHeader
            title={errorMessage}
        // tabs={props.tabs}
        >
            {/* {errorMessage} */}
        </ContentHeader >
    }
    >
    </Content>
    const menuItems = [
        ...(props.menuItems ? props.menuItems : []),
        <VersionInfoButton
            key="version-button"
            entity={entity}
        />
    ];
    return <Content
        inDialog={props.inDialog}
        contentHeader={loaded && <ContentHeader
            inDialog={props.inDialog}
            title={entity.title}
            link={entity.link ? entity.link : undefined}
            tabs={props.tabs}
            bigLeftBox={props.bigLeftBox}
            onDrag={props.onDrag}
            buttons={<>
                <EntityPageMenu
                    openEditDialog={(!permissions.Edit && !props.admin) ? undefined : props.openEditDialog}
                    onDelete={entity.delete && (permissions.Delete || props.admin) ? () =>
                    {
                        if (entity.delete)
                            entity.delete().then(props.onDelete);
                    } : undefined}
                    menuItems={menuItems}
                />
                {props.buttons}
                <ShareButton entity={entity} />
            </>}
        >
            {clonedInformations}
        </ContentHeader >} >
        {loaded ?
            <div className='entityPage'>
                {props.children &&
                    <Container>
                        {props.children}
                    </Container>
                }
            </div>
            : <LoadingBox />}
    </Content>;
}

export default EntityPage;