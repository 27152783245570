
import './AnalyseSkillsTextButton.css';
import { Description } from '@mui/icons-material';
import React, { useContext, } from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import AnalyseCvDialog from '../../Dialogs/AnalyseSkillsDialog/AnalyseCvDialog';

/**
 * AnalyseSkillsTextButton component.
 *
 * @param {AnalyseSkillsTextButtonProps} props - The props object for the component.
 * @returns {JSX.Element} The rendered AnalyseSkillsTextButton component.
 */
function AnalyseSkillsTextButton()
{
    const { openDialog } = useContext(DialogManagerContext);

    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).profileSettings;
    const offline = useSelector((state: AppState) => state.client.offline);

    return (
        <React.Fragment>
            <div className="analyseSkillsTextButton">
                <Button
                    size="normal"
                    text={langStrings.analyseText}
                    icon={<Description />}
                    onClick={
                        async () =>
                        {
                            openDialog(<AnalyseCvDialog
                                id='AnalyseCvDialog'
                                initialLoadingText={""}
                                file={undefined}
                                resolve={() =>
                                {
                                    return;
                                }}
                            />);
                            (document.getElementById('fileButton') as HTMLInputElement).value = "";
                        }}
                    disabled={offline}
                />
            </div>
        </React.Fragment>
    );
}

export default AnalyseSkillsTextButton;
