import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import SkillDetail from '@src/Objects/SkillDetail';
import AddSkillButton from '@src/Components/Buttons/AddSkillButton/AddSkillButton';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import SkillCategorySelect from '@src/Components/formsControls/inputs/SkillCategorySelect/SkillCategorySelect';
import { SkillDialog } from '@src/Components/Dialogs/SkillDialog/SkillDialog';
import getSkill from '@src/APIs/graphQl/Skill/getSkill';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import EmployeesCard from '@src/App/NewLayout/Cards/Employees/EmployeesCard/EmployeesCard';
import ProductsCard from '@src/App/NewLayout/Cards/Products/ProductsCard/ProductsCard';
import RolesCard from '@src/App/NewLayout/Cards/Roles/RolesCard/RolesCard';

import EntityPage from '../../../../../sharedReact/Pages/EntityPage/EntityPage';

interface SkillPageProps
{
    entity?: SkillDetail;
    inDialog?: boolean;
}

const SkillPage: React.FC<SkillPageProps> = (props) =>
{
    const { openDialog } = useContext(DialogManagerContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const entityId = props.entity ? props.entity.id : id ? Number.parseInt(id) : -1;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;
    const entity = useSelector((state: AppState) => state.skills.find(a => a.id === entityId))

    const emp = useSelector((state: AppState) => state.employee);
    const permissions = useSelector((state: AppState) => state.permissions);
    const readRoles = checkPermissions('Roles', 'Retrieve', permissions);
    const skillCategories = useSelector((state: AppState) => state.company.skillCategories);

    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    useEffect(() =>
    {
        if (props.inDialog) return;
        getSkill(entityId).catch((ex) =>
        {
            setLoadingError(ex);
        });
    }, [props.inDialog, entityId]);

    const skillCategory = !entity ? null : skillCategories.find((cat) => cat.id === entity.categoryId);

    if (!emp) return null;
    const handleOpenEditDialog = async () =>
    {
        return openDialog(
            <SkillDialog
                id='SkillDialog'
                skill={entity}
            />);
    };
    return (
        <EntityPage
            inDialog={props.inDialog}
            loadingError={loadingError}
            entity={entity}
            admin={permissions.admin}
            permissions={permissions.entities.Skills}
            onDelete={() =>
            {
                if (!props.inDialog)
                    navigate(`/${ELinks.SKILLS}`);
            }}
            buttons={
                entity ?
                    <AddSkillButton
                        size="small"
                        title={entity.title}
                        id={entity.id}
                    />
                    : undefined
            }
            openEditDialog={handleOpenEditDialog}
            informations={
                entity ?
                    [
                        skillCategory ?
                            <EntityPageInformation title={langStrings.skillCategory} >
                                <SkillCategorySelect skillCategories={skillCategories} selectedCategory={skillCategory?.id} readonly={true} />
                            </EntityPageInformation> : undefined,
                        entity.skillType ?
                            <EntityPageInformation title={langStrings.skillType} >
                                {entity.skillType}
                            </EntityPageInformation> : undefined,
                        (entity.productInfo && entity.productInfo.productName) ?
                            <EntityPageInformation title={langStrings.productName} >
                                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <Typography variant='body2' className="rowElementContent">
                                        {entity.productInfo.productName}
                                    </Typography>
                                    {entity.productInfo.link &&
                                        <IconButton
                                            title={langStrings.oftenUsed.openInNewTab}
                                            size='small'
                                            style={{ padding: 0 }}
                                            onClick={() =>
                                            {
                                                window.open(entity.productInfo?.link, '_blank');
                                            }}
                                        >
                                            <OpenInNew
                                                fontSize='small'
                                            />
                                        </IconButton>
                                    }
                                </div>
                            </EntityPageInformation> : undefined,
                        (entity.productInfo && entity.productInfo.manufacturer) ?
                            <EntityPageInformation title={langStrings.manufacturer} >
                                {entity.productInfo.manufacturer}
                            </EntityPageInformation>
                            : undefined,
                        entity.description ? <EntityPageInformation title={langStrings.description} size={'full'}>
                            <ExpandableText>
                                <FormatedTextInput value={entity.description} readonly={true} />
                            </ExpandableText>
                        </EntityPageInformation> : undefined,
                        entity.acquisition ? <EntityPageInformation title={langStrings.acquisition} size={'full'}>
                            <ExpandableText>
                                <>
                                    <FormatedTextInput value={entity.acquisition} readonly={true} />
                                    {entity.acquisitionLink &&
                                        <div style={{ maxWidth: 200 }}>
                                            <Button size={'normal'} text={langStrings.howToLearn} icon={<OpenInNew />} onClick={function (): void
                                            {
                                                window.open(entity.acquisitionLink, '_blank');
                                            }} />
                                        </div>}
                                </>
                            </ExpandableText>
                        </EntityPageInformation> : undefined,
                    ]
                    : []
            }
        >
            {entity &&
                <>
                    {readRoles &&
                        <RolesCard roles={entity.roles} />
                    }
                    <EmployeesCard
                        title={langStrings.employeesWithSkill}
                        employees={entity.employees}
                        skillId={entity.id}
                    />
                    <ProductsCard products={entity.products} />
                </>
            }
        </EntityPage>
    );
};

export default SkillPage;
