import IEntity from "./IEntity";
import { IEmployeeCoverage } from "./IOpportunity"
import { IReference } from "./IReference";
import { ISkillReferenceDTO } from "./ISkill";
import { ELanguage } from "./Language/languageHelper";
import { IStartPagesTexts } from "./Language/languageTexts/startPages"

export default interface IEmployeeSettingsDTO extends ICreateEmployeeSettingsDTO, IEntity
{
    id: number
}

export interface ICreateEmployeeSettingsDTO
{
    lastForecastDate: Date
    lastSkillUpdate: Date
    startPage: keyof IStartPagesTexts
    hiddenProjects: number[]
    firstExperience: boolean
    competenceSettings: IEmployeeSettingsCompetenceSettings
    skillSettings: IEmployeeSettingsSkillSettings
    goals: IEmployeeSettingsGoal[];
    emailSettings: IEmployeeSettingsMailSettings;
    certificateSettings: IEmployeeSettingsCertificateSettings;
    language2: ELanguage
}

export interface IEmployeeSettingsMailSettings
{
    subscribeAll: boolean
    subscriptions: IEmployeeSettingsMailSettingsSubscription
}

export enum ECertificateSettingsNotifyState
{
    LAST_3_MONTH = 'last_3_month',
    INACTIVE = 'inactive',
}
export interface IEmployeeSettingsCertificateSettings
{
    notified: {
        [certId: number]: ECertificateSettingsNotifyState
    }
}
export interface IEmployeeSettingsMailSettingsSubscription
{
    newsletter: boolean
    updates: boolean
    skillSuggestion: boolean
    newClient: boolean
    forecast: boolean
}

export const mailSettingsDefaultSubscriptions: IEmployeeSettingsMailSettingsSubscription = {
    newsletter: true,
    updates: true,
    skillSuggestion: true,
    newClient: false,
    forecast: true,
}

export interface IEmployeeSettingsCompetenceSettings
{
    hiddenCompetences: number[]
    wantedCompetences: IEmployeeSettingsCompetenceSettingsWantedCompetence[]
}

export interface IEmployeeSettingsCompetenceSettingsWantedCompetence
{
    id: number
    level: number
    untilDate: Date
    createdAt: Date
    initialCompareState: IEmployeeCoverage
    state: 'done' | 'inProgress'
}

export interface IEmployeeSettingsSkillSettings
{
    hiddenSkillsSuggestions: IHiddenSkillSuggestion[]
}

export interface IHiddenSkillSuggestion
{
    id: number
    createdAt: Date
}

export interface IEmployeeSettingsGoal
{
    id: number
    title: string
    description: string
    skills: ISkillReferenceDTO[]
    certificates: IReference[]
    untilDate: Date
    createdAt: Date
    initialCompareState: IEmployeeCoverage
    state: 'done' | 'inProgress'
}