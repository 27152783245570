import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Add, TableChart, TableRows } from '@mui/icons-material';
import './OpportunitiesPage.css';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import Opportunity from '@src/Objects/Opportunity';
import { textContainsLowerCase } from '@src/Objects/Helper';
import OpportunityDialog from '@src/Components/Dialogs/OpportunityDialog/OpportunityDialog';
import OpportunityOverlay from '@src/Components/Overlays/OpportunityOverlay/OpportunityOverlay';
import EnhancedTable from '@src/App/NewLayout/Components/DataTable/DataTable';
import getOpportunities from '@src/APIs/graphQl/Opportunity/getOpportunities';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import OpportunityCard from '@src/App/NewLayout/Cards/Opportunities/OpportunityCard/OpportunityCard';

import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';
import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';


/**
 * OpportunitiesPage component.
 * 
 * @returns {JSX.Element} The rendered OpportunitiesPage component.
 */
function OpportunitiesPage()
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).opportunities;
    const permissions = useSelector((state: AppState) => state.permissions);
    const entities = useSelector((state: AppState) => state.opportunity)

    const emp = useSelector((state: AppState) => state.employee);
    const [selected, setSelected] = useState<number[]>([]);
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    useEffect(() =>
    {
        getOpportunities().catch((ex) =>
        {
            setLoadingError(ex);
        })
    }, []);

    const types: IViewType<Opportunity>[] = [
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            minWidth: 280,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        id={EEntityType.OPPORTUNITY}
                        dense={true}
                        noCheckbox={true}
                        selected={selected}
                        setSelected={setSelected}
                        fields={[{
                            id: 'id',
                            label: 'ID',
                            disablePadding: true,
                            align: 'center',
                            type: 'string',
                        },
                        {
                            id: 'title',
                            label: langStrings.opportunityName,
                            disablePadding: true,
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.SALES_OPPORTUNITIES}/`,
                        },
                        {
                            id: 'customer',
                            label: langStrings.customer,
                            disablePadding: true,
                            align: 'left',
                            type: 'string',
                            minWidth: 400,
                        },
                        {
                            id: 'employees',
                            label: langStrings.employees,
                            disablePadding: true,
                            align: 'center',
                            minWidth: 780,
                            type: 'string',
                        },
                        {
                            id: 'projectStart',
                            label: langStrings.startDate,
                            disablePadding: true,
                            align: 'center',
                            minWidth: 280,
                            type: 'date',
                        },
                        {
                            id: 'projectEnd',
                            label: langStrings.endDate,
                            disablePadding: true,
                            align: 'center',
                            minWidth: 340,
                            type: 'date',
                        },
                        {
                            id: 'skillCoverage',
                            label: langStrings.skillCoverage,
                            disablePadding: true,
                            align: 'left',
                            minWidth: 1300,
                            type: 'percent',
                        },
                        {
                            id: 'skillScore',
                            label: langStrings.skillScore,
                            disablePadding: true,
                            align: 'left',
                            minWidth: 1450,
                            type: 'percent',
                        },
                        {
                            id: 'utilization',
                            label: langStrings.utilization,
                            disablePadding: true,
                            align: 'left',
                            minWidth: 860,
                            type: 'percent',
                        },
                        {
                            id: 'remote',
                            label: langStrings.remote,
                            disablePadding: true,
                            align: 'center',
                            minWidth: 950,
                            type: 'percent',
                        },
                        {
                            id: 'dayPrice',
                            label: langStrings.dayPrice,
                            disablePadding: true,
                            align: 'center',
                            minWidth: 500,
                            type: 'currency',
                        },
                        {
                            id: 'active',
                            label: langStrings.active,
                            disablePadding: true,
                            align: 'center',
                            minWidth: 1000,
                            type: 'string',
                        },
                        {
                            id: 'topSkills',
                            label: langStrings.topSkills,
                            disablePadding: true,
                            align: 'center',
                            minWidth: 1120,
                            type: 'string',
                        },
                        ]}
                        rows={
                            innerEntries.map(entity =>
                            {
                                const skillCoverage = !entity.selectedEmployees.length ? 0 :
                                    Math.round(entity.selectedEmployees
                                        .reduce((prev, cur) => cur.skillCoverage + prev, 0) / entity.selectedEmployees.length * 100)
                                const skillScore = !entity.selectedEmployees.length ? 0 : Math.round(entity.selectedEmployees
                                    .reduce((prev, cur) => cur.score + prev, 0) / entity.selectedEmployees.length * 100)
                                return {
                                    id: entity.id,
                                    title: {
                                        value: <OpportunityOverlay
                                            opportunityId={entity.id}
                                        >
                                            {entity.title}
                                        </OpportunityOverlay>,
                                        orderKey: entity.title
                                    },

                                    customer: entity.customer,
                                    employees: entity.headCount,
                                    dayPrice: entity.dayPrice,
                                    projectStart: entity.projectStart,
                                    projectEnd: entity.projectEnd,
                                    utilization: entity.utilization,
                                    remote: entity.remote,
                                    topSkills: entity.skills.slice(0, 10).map(s => s.title).join(", "),
                                    active: `${entity.active ? langStrings.yes : langStrings.no}`,
                                    skillCoverage,
                                    skillScore,
                                }
                            }
                            )
                        } />

            ,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <OpportunityCard key={entity.id} opportunity={entity}
                        />)
                }
            </Container>,
        }
    ];

    const actions: IEntitiesPageAction[] = [
        {
            id: 'create',
            text: langStrings.newOpportunity,
            icon: <Add />,
            filter()
            {
                if (!checkPermissions('Opportunities', 'Add', permissions))
                    return false;
                return true;

            },
            action()
            {
                return openDialog(<OpportunityDialog id="OpportunityDialog" />);
            },
        }
    ];
    return (
        <EntitiesPage
            title={langStrings.opportunities}
            entities={entities}
            selected={selected}
            loadingError={loadingError}

            setSelected={setSelected}
            views={[
                {
                    id: 'active', title: 'Aktive Einträge',
                    filter(entity)
                    {
                        return entity.active === true;
                    },
                },
                {
                    id: 'all', title: langStrings.oftenUsed.allEntries,
                    filter()
                    {
                        return true;
                    },
                },
                {
                    id: 'my', title: langStrings.oftenUsed.myEntries,
                    filter(entity)
                    {
                        return entity.selectedEmployees.find(e => e.id === emp?.id) !== undefined
                    },
                },
            ]}
            types={types}
            actions={actions}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) &&
                (
                    textContainsLowerCase(searchText, entity.title)
                    || textContainsLowerCase(searchText, entity.customer)
                    || textContainsLowerCase(searchText, entity.id.toString())
                )
            }
        />
    );
}

export default OpportunitiesPage;