import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { Home } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ILayoutTexts } from '@sharedInterfaces/Language/languageTexts/layout';
import { AppState } from '@store/store';
import { ELinks } from '@sharedInterfaces/globalEnums';

/**
 * Represents the AutoBreadcrumb component.
 * This component does not take any parameters.
 * @returns {JSX.Element}
 */
export function AutoBreadcrumb()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).layout;
    const startPage = useSelector((state: AppState) => state.employeeSettings.startPage);

    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        pathnames.length > 1 ?
            <Breadcrumbs aria-label="breadcrumb" style={{
                marginLeft: 3,
                marginTop: 3,
                marginBottom: -8,
            }}>
                <Link to={`/${ELinks[startPage]}`} >{generateName("Home", langStrings)}</Link>
                {pathnames.slice(0, -1).map((value, index) =>
                {
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    return last ? (
                        <Typography color="textPrimary" key={to}>
                            {generateName(value, langStrings)}
                        </Typography>
                    ) : (
                        <Link to={to} key={to}>
                            <Typography variant='body2' key={to}>
                                {generateName(value, langStrings)}
                            </Typography>
                        </Link>
                    );
                })}
            </Breadcrumbs > :
            <></>
    );
}

/**
 * Generates a name.
 * 
 * @param path - The path parameter.
 * @param langStrings - The language strings parameter of type ILayoutTexts.
 * @returns The generated name of any type.
 */
function generateName(path: string, langStrings: ILayoutTexts)
{
    switch (path.toLocaleLowerCase())
    {
        case 'home':
            return <Home titleAccess={langStrings.pageHome} fontSize='small' />
        case 'skills':
            return langStrings.oftenUsed.skills;
        case 'certificates':
            return langStrings.oftenUsed.certificates;
        case 'skillcategories':
            return langStrings.oftenUsed.skillCategories;
        case 'competence':
            return langStrings.oftenUsed.competences;
        case 'employee':
            return langStrings.oftenUsed.employees;
        case 'projects':
            return langStrings.oftenUsed.projects;
        case 'products':
            return langStrings.oftenUsed.products;
        case 'orgunits':
            return langStrings.oftenUsed.orgUnits;
        case 'sales':
            return langStrings.sales;
        case 'opportunities':
            return langStrings.oftenUsed.opportunities;
        case 'customers':
            return langStrings.oftenUsed.businesses;
        case 'people-org':
            return langStrings.employeeAndOEsLong;
        case 'settings':
            return langStrings.settings;
        case 'profile':
            return langStrings.profile;
        case 'edit':
            return langStrings.edit;
        case 'roles':
            return langStrings.oftenUsed.roles;
        case 'restapi':
            return 'REST-API'
        case 'documentation':
            return langStrings.oftenUsed.documentation;
        case 'all':
            return '';
        case 'my':
            return '';
        default:
            return path;
    }
}