import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Close, DragIndicator, Save } from '@mui/icons-material';
import { FormGroup, Grid, Switch, Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from 'react-beautiful-dnd';
import { IDataTableField, IDataTableSettings } from '@src/App/NewLayout/Components/DataTable/DataTable';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import Dialog from '../Dialog/Dialog';

interface TableSettingsDialogProps
{
    id: string;
    tableId: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields: IDataTableField<any>[]
    tableSettings: IDataTableSettings;
    resolve: (result: IDataTableSettings) => void;
}

const TableSettingsDialog: React.FC<TableSettingsDialogProps> = ({ id, fields, tableSettings, resolve }) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).userDialog;

    const [isOpen, setIsOpen] = useState(true);
    const [settings, setSettings] = useState(tableSettings);


    const findFieldIndex = (fieldId: string, order: string[]) => order.indexOf(fieldId);

    // Sortieren der fields basierend auf der Reihenfolge in 'settings.order'
    const sortedFields = fields.sort(
        (a, b) => findFieldIndex(a.id, settings.order) - findFieldIndex(b.id, settings.order)
    );


    const onConfirm = () =>
    {
        resolve(settings);
        setIsOpen(false);
    };
    const onClose = () =>
    {
        resolve(tableSettings);
        setIsOpen(false);
    };
    const handleSwitchChange = (fieldId: string) =>
    {
        setSettings({
            ...settings,
            hiddenFields:
            {
                ...settings.hiddenFields,
                [fieldId]: !settings.hiddenFields[fieldId]
            }
        });
    };

    const onDragEnd: OnDragEndResponder = (result) =>
    {
        if (!result.destination) return;

        const newOrder = Array.from(settings.order);
        const [removed] = newOrder.splice(result.source.index, 1);
        newOrder.splice(result.destination.index, 0, removed);

        setSettings({
            ...settings,
            order: newOrder
        });
    };

    if (!isOpen) return null;

    return (
        <Dialog
            id={id}
            footer={
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                    <Button
                        text={langStrings.oftenUsed.close}
                        icon={<Close />}
                        onClick={onClose}
                    />
                    <Button
                        text={langStrings.oftenUsed.confirm}
                        icon={<Save />}
                        onClick={onConfirm}
                    />
                </div>
            }
            title={langStrings.titleTableSettings}
            onClose={onClose}
        >
            <div style={{ margin: 10 }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-fields">
                        {(provided,) => (
                            <FormGroup ref={provided.innerRef} {...provided.droppableProps}
                                style={{
                                    overflow: 'hidden', //Fix für D&D
                                }}
                            >
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <div style={{ width: 22 }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant='caption'>{langStrings.column}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='caption'>{langStrings.show}</Typography>
                                    </Grid>
                                </Grid>
                                {sortedFields.map((field, index) => (
                                    <Draggable key={field.id} draggableId={field.id} index={index}>
                                        {(provided,) => (
                                            <Grid container alignItems="center" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} spacing={1}>
                                                <Grid item>
                                                    <DragIndicator />
                                                </Grid>
                                                <Grid item xs>
                                                    {field.label}
                                                </Grid>
                                                <Grid item>
                                                    <Switch
                                                        checked={!settings.hiddenFields[field.id]}
                                                        onChange={() => handleSwitchChange(field.id)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </FormGroup>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            {/*<RenderJson data ={availabilityData}/> */}

            {/* <pre>
                {JSON.stringify(settings, null, 2)}
            </pre>
            <pre>
                {JSON.stringify(fields, null, 2)}
                {JSON.stringify(sortedFields, null, 2)}
            </pre> */}
        </Dialog>
    );
};


export function useTableSettingsDialog()
{
    const { openDialog } = useContext(DialogManagerContext);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const openTableSettingsDialog = (tableId: string, fields: IDataTableField<any>[], tableSettings: IDataTableSettings,) =>
    {
        return new Promise<IDataTableSettings>((resolve) =>
        {
            const dialog = (
                <TableSettingsDialog
                    id='TableSettingsDialog'
                    tableId={tableId}
                    fields={fields}
                    tableSettings={tableSettings}
                    resolve={(result) => resolve(result)}
                />
            );
            openDialog(dialog);
        });
    };

    return openTableSettingsDialog;
}