import { OperationVariables, gql } from "@apollo/client";
import { IEmployeeSettingsMailSettings, IEmployeeSettingsMailSettingsSubscription } from "@sharedInterfaces/IEmployeeSettings";
import store from "@store/store";
import { setEmailSettings } from "@store/reducer/employeeSettingsReducer";

import GraphQL from "../graphQL";

export const emailSubscriptions = `subscriptions
{
    newsletter
    updates
    skillSuggestion
    newClient
    forecast
}`;

const query = gql`
mutation setEmployeeEmailSettings($subscribeAll: Boolean!, $subscriptions: [SubscriptionInput!]!) {
  setEmployeeEmailSettings(subscribeAll: $subscribeAll, subscriptions: $subscriptions)
  {
    subscribeAll
    ${emailSubscriptions}
}
}
`;

export default async function setEmployeeEmailSettings(allEmails: boolean, subscriptions: { id: keyof IEmployeeSettingsMailSettingsSubscription; subscribed: boolean; }[])
{
    const variables: OperationVariables = {
        subscribeAll: allEmails,
        subscriptions
    };
    const result = await GraphQL.mutate<IEmployeeSettingsMailSettings>(query, variables);
    store.dispatch(setEmailSettings(result));
    return result;
}