import React, { useContext, useState } from 'react';
import { Fab, Zoom } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { EEntityType } from '@sharedInterfaces/globalEnums';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import { CompetenceDialog } from '@src/Components/Dialogs/CompetenceDialog/CompetenceDialog';
import { SkillDialog } from '@src/Components/Dialogs/SkillDialog/SkillDialog';
import { CertificateDialog } from '@src/Components/Dialogs/CertificateDialog/CertificateDialog';
import OpportunityDialog from '@src/Components/Dialogs/OpportunityDialog/OpportunityDialog';
import { entityTypeToIcon } from '@src/helper/tsxHelper';


/**
 * Represents a HoverFab component.
 *
 * @returns {JSX.Element}
 */
export function HoverFab(): JSX.Element
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang);
    const permissions = useSelector((state: AppState) => state.permissions);
    const offline = useSelector((state: AppState) => state.client.offline);

    const [isHover, setHover] = useState(false);
    let timer: NodeJS.Timeout;

    const handleMouseEnter = () =>
    {
        if (offline) return;
        clearTimeout(timer);
        setHover(true);
    };

    const handleMouseLeave = () =>
    {
        timer = setTimeout(() =>
        {
            setHover(false);
        }, 750);
    };
    return (
        <div
            style={{
                position: 'sticky',
                float: 'right',
                bottom: 10,
                right: 10,
                marginTop: -56,
                width: 56,
                alignSelf: 'flex-end',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {
                checkPermissions('Skills', 'Add', permissions) &&
                <Fab //TODO: Check Speeddial mui
                    title={langStrings.layout.createSkill}
                    disabled={offline}
                    color="primary"
                    style={{
                        position: 'sticky',
                        float: 'right',
                        bottom: 15,
                        right: 15,
                        transition: 'background-color 0.5s',
                    }}
                    onClick={() =>
                    {
                        if (!isHover) return;
                        return openDialog(
                            <SkillDialog
                                id="SkillDialog"
                            />);
                    }}
                >
                    {isHover ? entityTypeToIcon(EEntityType.SKILL) : <Add />}
                </Fab>
            }
            {
                checkPermissions('Competencies', 'Add', permissions) &&
                <Zoom in={isHover}>
                    <Fab
                        title={langStrings.layout.createCompetence}
                        color="primary"
                        disabled={offline}
                        style={{
                            position: 'absolute',
                            bottom: 68,
                            right: 0,
                            transition: 'background-color 0.5s',
                        }}
                        onClick={() =>
                        {
                            return openDialog(
                                <CompetenceDialog
                                    id="CompetenceDialog"
                                    resolve={() =>
                                    {

                                    }}
                                />);
                        }}
                    >
                        {entityTypeToIcon(EEntityType.COMPETENCE)}
                    </Fab>
                </Zoom>
            }
            {
                checkPermissions('Certificates', 'Add', permissions) &&
                <Zoom in={isHover}>
                    <Fab
                        title={langStrings.layout.createCertificate}
                        color="primary"
                        disabled={offline}
                        style={{
                            position: 'absolute',
                            bottom: 135,
                            right: 0,
                            transition: 'background-color 0.5s',
                        }}
                        onClick={() =>
                        {
                            return openDialog(
                                <CertificateDialog
                                    id="CertificateDialog"
                                    resolve={() =>
                                    {

                                    }}
                                />);
                        }}
                    >
                        {entityTypeToIcon(EEntityType.CERTIFICATE)}
                    </Fab>
                </Zoom>
            }
            {
                checkPermissions('Opportunities', 'Add', permissions) &&
                <Zoom in={isHover}>
                    <Fab
                        title={langStrings.layout.createOpportunity}
                        color="primary"
                        disabled={offline}
                        style={{
                            position: 'absolute',
                            bottom: 203,
                            right: 0,
                            transition: 'background-color 0.5s',
                        }}
                        onClick={() =>
                        {
                            return openDialog(
                                <OpportunityDialog
                                    id="OpportunityDialog"
                                    resolve={() =>
                                    {

                                    }}
                                />);
                        }}
                    >
                        {entityTypeToIcon(EEntityType.OPPORTUNITY)}
                    </Fab>
                </Zoom>
            }
        </div>
    );
}
