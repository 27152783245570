import './CompetencePage.css';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { entityTypeToLink, EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import { ICompetenceSuitableEmployees } from '@sharedInterfaces/ICompetence';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import Competence from '@src/Objects/Competence';
import { CompetenceLevelsInput } from '@src/Components/formsControls/inputs/CompetenceLevelsInput/CompetenceLevelsInput';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import { EmployeeSuggestions } from '@src/Components/Opportunities/EmployeeSuggestions/EmployeeSuggestions';
import CardBox from '@src/Components/CardBox/CardBox';
import { CompetenceDialog } from '@src/Components/Dialogs/CompetenceDialog/CompetenceDialog';
import getCompetence from '@src/APIs/graphQl/Competence/getCompetence';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import competenceGetSuggestedEmployees from '@src/APIs/graphQl/Competence/competenceGetSuggestedEmployees';
import ProductsCard from '@src/App/NewLayout/Cards/Products/ProductsCard/ProductsCard';
import OpportunitiesCard from '@src/App/NewLayout/Cards/Opportunities/OpportunitiesCard/OpportunitiesCard';
import EmployeesCard from '@src/App/NewLayout/Cards/Employees/EmployeesCard/EmployeesCard';
import RolesCard from '@src/App/NewLayout/Cards/Roles/RolesCard/RolesCard';

import EntityPage from '../../../../../sharedReact/Pages/EntityPage/EntityPage';

interface CompetencePageProps
{
    entity?: Competence;
    inDialog?: boolean;
}


/**
 * CompetencePage component.
 * 
 * @param {CompetencePageProps} entity - The competence page props.
 * @param {boolean} inDialog - Indicates whether the component is rendered in a dialog.
 * @param {function} onChange - Handler function for the onChange event.
 * @param {function} onDelete - Handler function for the onDelete event.
 * @returns {JSX.Element} The rendered competence page component.
 */
function CompetencePage({ entity, inDialog, }: CompetencePageProps)
{
    const navigate = useNavigate();
    const { openDialog } = useContext(DialogManagerContext);
    const { id, tab } = useParams();
    const entityId = entity ? entity.id : id ? Number.parseInt(id) : -1;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;
    const permissions = useSelector((state: AppState) => state.permissions);
    const readRoles = checkPermissions('Roles', 'Retrieve', permissions);
    const entityState = useSelector((state: AppState) => state.competences.find(a => a.id === entityId))


    const [suitableEmployees, setSuitableEmployees] = useState<ICompetenceSuitableEmployees>();
    const [loadingSuitableEmployees, setSuitableEmployeesLoading] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);


    const loadSuitableEmployees = () =>
    {
        if (loadingSuitableEmployees) return;
        setSuitableEmployeesLoading(true);
        competenceGetSuggestedEmployees(entityId, (details) =>
        {
            // console.log(details);
            details.levels.forEach(level => level.employees.sort((a, b) =>
            {
                return b.score - a.score
            }))
            setSuitableEmployees(details);
        }).catch((ex) =>
        {
            setLoadingError(ex);
        });
    }

    useEffect(() =>
    {
        if (inDialog) return;
        getCompetence(entityId).catch((ex) =>
        {
            setLoadingError(ex);
        });

    }, [entityId, inDialog]);

    useEffect(() =>
    {
        if (inDialog) return;
        if (entityId === -1) return;
        if (tab !== 'suitableEmployees') return
        if (suitableEmployees) return;
        loadSuitableEmployees();
    }, [entity, tab]);

    let content = undefined;
    if (entityState)
    {
        switch (tab)
        {
            case 'suitableEmployees':
                content =
                    <>
                        {
                            suitableEmployees?.levels.map(level =>
                            {
                                const levelName = entityState.levels[level.level - 1].title
                                return <CardBox key={level.level} title={levelName}>
                                    <EmployeeSuggestions suggestedEmployees={level.employees} id={level.level.toString()} />
                                </CardBox>
                            })
                        }
                    </>
                break
            default:
                content = <>
                    <EmployeesCard employees={entityState.employees} competenceLevels={entityState.levels} />
                    {readRoles &&
                        <RolesCard roles={entityState.roles} />
                    }
                    <ProductsCard products={entityState.products} />
                    <OpportunitiesCard opportunities={entityState.opportunities} />
                    <CompetenceLevelsInput levels={entityState.levels} />
                </>;
        }

    }

    const handleOpenEditDialog = async () =>
    {
        return openDialog(
            <CompetenceDialog
                id="CompetenceDialog"
                competence={entityState}
            />);
    };
    return (
        <EntityPage
            inDialog={inDialog}
            loadingError={loadingError}
            entity={entityState}
            admin={permissions.admin}
            permissions={permissions.entities.Competencies}
            tabs={inDialog ? undefined : [
                {
                    title: langStrings.oftenUsed.dashboard,
                    link: entityTypeToLink(entityId, EEntityType.COMPETENCE, 'dashboard'),
                },
                {
                    title: langStrings.suitableEmployees,
                    link: entityTypeToLink(entityId, EEntityType.COMPETENCE, 'suitableEmployees'),
                },
            ]}
            onDelete={() =>
            {
                if (!inDialog)
                    navigate(`/${ELinks.COMPETENCE}`);
            }}
            openEditDialog={handleOpenEditDialog}
            informations={
                !entityState ? [] :
                    [
                        entityState.description ?
                            <EntityPageInformation title={langStrings.description} size={'full'}>
                                <ExpandableText>
                                    <FormatedTextInput value={entityState.description} readonly={true} />
                                </ExpandableText>
                            </EntityPageInformation> :
                            undefined,
                    ]
            }
        >
            {content}
        </EntityPage>
    );
}

export default CompetencePage;