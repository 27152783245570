import React, { useEffect, useState } from 'react';
import { Done, ForwardToInbox, Mail, Save, Warning } from '@mui/icons-material';
import { Checkbox, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Typography } from '@mui/material';
import { EMicrosoftConnectionState, ICompanyEmailSettings } from '@sharedInterfaces/ICompany';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EMailCycle } from '@sharedInterfaces/globalEnums';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import Button from '@sharedReact/General/Button/Button';
import RowElement from '@sharedReact/General/Forms/RowElement/RowElement';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import companyEmailSettingsSendMail from '@src/APIs/graphQl/Company/companyEmailSettingsSendMail';
import editCompanyEmailSettings from '@src/APIs/graphQl/Company/editCompanyEmailSettings';
import ErrorBox from '@src/Components/ErrorBox/ErrorBox';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import Company from '@src/Objects/Company';
import { AppState } from '@store/store';
import { useSelector } from 'react-redux';
import { EEmailTransferOption } from '@sharedInterfaces/ICompanySettings';
import editEmailTransferOption from '@src/APIs/graphQl/Company/editEmailTransferOption';
import loadEmailMailboxes from '@src/APIs/graphQl/Company/loadEmailMailboxes';
import companyEmailSettingsSendMailExchange from '@src/APIs/graphQl/Company/companyEmailSettingsSendMailExchange';

interface EmailSettingsPageProps
{
    emailSettings: ICompanyEmailSettings,
    globalEmailSupport: boolean
    microsoftConnectionStatus: EMicrosoftConnectionState
    setCompany: (company: Company) => void;
}
export default function EmailSettingsPage({ emailSettings, globalEmailSupport, microsoftConnectionStatus, setCompany }: EmailSettingsPageProps)
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;

    const [emailOption, setEmailOption] = useState<EEmailTransferOption>(emailSettings.emailTransferOption);
    const [saving, setSaving] = useState<boolean>(false);
    const [loadingMailboxes, setLoadingMailboxes] = useState<boolean>(false);
    const [mailboxes, setMailboxes] = useState<string[] | null>(null);
    const [mailbox, setMailbox] = useState<string>(emailSettings.exchangeMailbox);
    const [savingMailbox, setSavingMailbox] = useState<boolean>(false);
    const [testingSuccessfull, setTestingSuccessfull] = useState<boolean | null>(null);
    const [savingSuccess, setSavingSuccess] = useState<boolean | null>(null);

    const canSend = emailSettings.emailTransferOption !== EEmailTransferOption.NoEmail
        && !(
            emailSettings.emailTransferOption === EEmailTransferOption.Global
            && !globalEmailSupport
        )
        && !saving

    useEffect(
        () =>
        {
            if (emailOption === EEmailTransferOption.Exchange)
            {
                loadMailboxes();
            }
        }
        , [])

    const handleEmailOptionChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value = event.target.value as EEmailTransferOption;
        //TODO: Filter ob Exchange, dann fragen nach Konto...

        setEmailOption(value);
        if (value === emailSettings.emailTransferOption)
            return;
        if (value === EEmailTransferOption.Exchange)
        {
            return loadMailboxes();
        }
        saveEmailTransferOption(value);
    };
    const forecastMail = <ForecastSettings disabled={!canSend} emailSettings={emailSettings} setCompany={setCompany} />;
    const certificateMail = <CertificateMailSettings disabled={!canSend} emailSettings={emailSettings} setCompany={setCompany} />
    return (
        <>
            <FormControl
                component="fieldset"
            >
                <Typography variant="h4">{langStrings.chooseEmailSendMethod}</Typography>
                <RowElement title={langStrings.emailSendMethod}>
                    <RadioGroup value={emailOption} onChange={handleEmailOptionChange}>
                        <FormControlLabel
                            value="noEmail"
                            control={<Radio />}
                            label={langStrings.noEmail}
                            disabled={saving}
                        />
                        <FormControlLabel
                            value="global"
                            control={<Radio />}
                            label={langStrings.sendThroughGlobal}
                            disabled={!globalEmailSupport || saving}
                        />
                        <FormControlLabel
                            value="exchange"
                            control={<Radio />}
                            label={langStrings.sendThroughExchange}
                            disabled={microsoftConnectionStatus !== EMicrosoftConnectionState.Connected || saving}
                        />
                    </RadioGroup>
                </RowElement>
            </FormControl>
            {emailOption === EEmailTransferOption.Exchange &&
                <RowElement title={langStrings.emailExchangeMailbox}>
                    <Select
                        value={mailbox}
                        disabled={saving || loadingMailboxes}
                        onChange={onChangeMailbox}
                    >
                        <MenuItem key={'-'} value={'-'}>- Nicht Ausgewählt -</MenuItem>
                        {mailboxes && mailboxes.map(mailbox =>
                            <MenuItem key={mailbox} value={mailbox}>{mailbox}</MenuItem>
                        )}
                    </Select>
                    {loadingMailboxes && <LoadingBox />}
                    <Button
                        style={{ maxWidth: 250, marginBottom: 10, marginTop: 10, }}
                        disabled={saving || loadingMailboxes || savingMailbox || mailbox === "-" || mailbox === ""}
                        size={'normal'}
                        text={langStrings.sendToMe}
                        icon={<Mail />}
                        onClick={function (): void
                        {
                            setTestingSuccessfull(null);
                            setSavingMailbox(true);
                            companyEmailSettingsSendMailExchange(mailbox)
                                .then(() => setTestingSuccessfull(true))
                                .catch(err =>
                                {
                                    showErrorDialog(err)
                                    setTestingSuccessfull(false)
                                })
                                .finally(() =>
                                {
                                    setSavingMailbox(false);
                                });
                        }}
                    />
                    {testingSuccessfull !== null &&
                        <div style={{ display: 'flex', alignItems: 'center', gap: 3 }} >
                            {
                                testingSuccessfull ?
                                    <>
                                        <Typography color='success' >
                                            {langStrings.sendingSuccessfull}
                                        </Typography>
                                        <Done color='success' />
                                    </>
                                    :
                                    <>
                                        <Typography color='error' >
                                            {langStrings.sendingNotSuccessfull}
                                        </Typography>
                                        <Warning color='error' />
                                    </>
                            }
                        </div>
                    }
                    {
                        emailSettings.exchangeMailbox !== mailbox &&
                        <Button
                            style={{ maxWidth: 250, marginTop: 10, }}
                            disabled={saving || loadingMailboxes || savingMailbox || mailbox === "-" || mailbox === "" || !testingSuccessfull}
                            size={'normal'}
                            text={langStrings.save}
                            icon={<Save />}
                            onClick={function (): void
                            {
                                setSavingMailbox(true);
                                setSavingSuccess(null);
                                editEmailTransferOption(emailOption, mailbox)
                                    .then((c) =>
                                    {
                                        setCompany(c);
                                        setSavingSuccess(true);
                                    })
                                    .catch(err =>
                                    {
                                        showErrorDialog(err)
                                        setSavingSuccess(false)
                                    })
                                    .finally(() =>
                                    {
                                        setSavingMailbox(false);
                                    });
                            }}
                        />
                    }
                    {savingSuccess !== null &&
                        <div style={{ display: 'flex', alignItems: 'center', gap: 3 }} >
                            {
                                savingSuccess ?
                                    <>
                                        <Typography color='success' >
                                            {langStrings.savingSuccessfull}
                                        </Typography>
                                        <Done color='success' />
                                    </>
                                    :
                                    <>
                                        <Typography color='error' >
                                            {langStrings.savingNotSuccessfull}
                                        </Typography>
                                        <Warning color='error' />
                                    </>
                            }
                        </div>
                    }
                </RowElement >
            }
            <Container
                style={{
                    borderTop: 'solid 1px black',
                    paddingTop: 12,
                    marginTop: 15,
                }}
            >
                {forecastMail}
                {certificateMail}
            </Container>
        </>
    )

    function onChangeMailbox(event: SelectChangeEvent<string>)
    {
        const mailbox = event.target.value as string;
        setMailbox(mailbox);
        setTestingSuccessfull(null);
    }

    function saveEmailTransferOption(value: EEmailTransferOption)
    {
        setSaving(true);
        editEmailTransferOption(value)
            .then(setCompany)
            .catch(err =>
            {
                showErrorDialog(err);
                setEmailOption(emailSettings.emailTransferOption);
            })
            .finally(() => setSaving(false));
    }

    function loadMailboxes()
    {
        setLoadingMailboxes(true);
        loadEmailMailboxes().then(mailboxes =>
        {
            // console.log(mailboxes);
            setMailboxes(mailboxes);
        })
            .catch(err =>
            {
                showErrorDialog(err);
                setMailboxes([]);

            })
            .finally(() => setLoadingMailboxes(false));
    }
}

interface CertificateMailSettingsProps
{
    emailSettings: ICompanyEmailSettings,
    disabled: boolean;
    setCompany: (company: Company) => void;
}
function CertificateMailSettings({ emailSettings, disabled, setCompany }: CertificateMailSettingsProps)
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [saving, setSaving] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(emailSettings.sendCertificateReminder ? true : false);

    useEffect(() =>
    {
        setChecked(emailSettings.sendCertificateReminder ? true : false);
    }, [emailSettings, emailSettings.sendCertificateReminder]);
    return <div>
        <Typography variant='h5'>
            {langStrings.certificateReminder}
        </Typography>
        <RowElement title={langStrings.activateCertificateReminder}>
            <Checkbox disabled={saving || disabled}
                checked={checked}
                onChange={saveChanges}
            />
            {saving && <LoadingBox />}
        </RowElement>
    </div>

    function saveChanges(e: React.ChangeEvent<HTMLInputElement>)
    {
        setSaving(true);
        editCompanyEmailSettings(emailSettings.forecastCycle, emailSettings.forecastText, e.target.checked)
            .then(setCompany)
            .catch(err =>
            {
                showErrorDialog(err);
                setChecked(emailSettings.sendCertificateReminder ? true : false);
            })
            .finally(() => setSaving(false));
    }
}


interface ForecastSettingsProps
{
    emailSettings: ICompanyEmailSettings,
    disabled: boolean;
    setCompany: (company: Company) => void;
}
function ForecastSettings({ emailSettings, disabled, setCompany }: ForecastSettingsProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;

    const [cycle, setCycle] = useState(emailSettings.forecastCycle);
    const [forecastText, setForecastText] = useState(emailSettings.forecastText);
    const [saveError, setSaveError] = useState<string | null>(null);
    const [saving, setSaving] = useState<boolean>(false);
    const [changed, setChanged] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);

    const sended = () =>
    {

    }
    const handleChange = (event: SelectChangeEvent<string>) =>
    {
        setCycle(event.target.value as EMailCycle);
        setChanged(true);
    };
    return <div>
        <Typography variant='h5'>
            {langStrings.forecastMail}
        </Typography>
        <RowElement title={langStrings.mailSendHowOften}>
            <Select
                labelId="sendHowOften"
                id="sendHowOften"
                value={cycle}
                label={langStrings.mailSendHowOften}
                disabled={disabled || saving}
                onChange={handleChange}
            >
                <MenuItem value={EMailCycle.NEVER}>{langStrings.oftenUsed.never}</MenuItem>
                <MenuItem value={EMailCycle.MONTLY}>{langStrings.oftenUsed.monthly}</MenuItem>
            </Select>
        </RowElement>
        <RowElement title={langStrings.mailForecastText}>
            <FormatedTextInput
                value={forecastText}
                disabled={disabled || saving}
                onChange={(text) => { setForecastText(text); setChanged(true); }}
            />
        </RowElement>
        {saveError && <ErrorBox close={setSaveError.bind(null, null)}>
            {saveError}
        </ErrorBox>}
        {sending && <Typography variant='body1'>{langStrings.oftenUsed.sending}</Typography>}
        {changed &&
            <Button
                style={{ maxWidth: 250, marginBottom: 10 }}
                disabled={saving || disabled}
                size={'normal'} text={langStrings.save} icon={<Done />} onClick={function (): void
                {
                    setSaving(true);
                    editCompanyEmailSettings(cycle, forecastText, emailSettings.sendCertificateReminder)
                        .then(setCompany).then(setChanged.bind(null, false))
                        .catch(err => setSaveError(err.toString()))
                        .finally(() => setSaving(false));
                }} />}

        <>
            <Button
                style={{ maxWidth: 250, marginBottom: 10 }}
                disabled={saving || disabled}
                size={'normal'} text={langStrings.sendToMe} icon={<Mail />} onClick={function (): void
                {
                    setSaving(true);
                    setSending(true);
                    companyEmailSettingsSendMail(true, forecastText)
                        .then(sended)
                        .catch(err => setSaveError(err.toString()))
                        .finally(() =>
                        {
                            setSaving(false);
                            setSending(false);
                        });
                }} />

            <Button
                style={{ maxWidth: 250 }}
                color='secondary'
                disabled={saving || changed || disabled}
                size={'normal'} text={langStrings.sendNow} icon={<ForwardToInbox />} onClick={function (): void
                {
                    const confirmed = confirm(langStrings.areYouSureForecastMail);
                    if (!confirmed) return;
                    setSaving(true);
                    setSending(true);
                    companyEmailSettingsSendMail(false)
                        .then(sended)
                        .catch(err => setSaveError(err.toString()))
                        .finally(() =>
                        {
                            setSaving(false);
                            setSending(false);
                        });
                }} />
            <Typography variant='subtitle2'>
                {langStrings.sendNowSubtitle}
            </Typography>
        </>
    </div>;
}