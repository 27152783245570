import { setDialogCount } from '@store/reducer/clientReducer';
import React, { createContext, ReactElement, ReactNode, useContext, useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

interface DialogUpdateState
{
    title?: string;
}

interface DialogManagerContextProps
{
    openDialog: (dialog: ReactElement<unknown>, initialState?: 'normal' | 'full' | 'minimized') => void;
    minimizeDialog: (index: string) => void;
    maximizeDialog: (index: string) => void;
    restoreDialog: (index: string) => void;
    focusDialog: (index: string) => void;
    closeDialog: (index: string) => void;
    getDialogState: (index: string) => DialogState | undefined;
    getDialogStates: () => DialogState[];
    setDialogState: (id: string, state: DialogUpdateState) => void;
}

export const DialogManagerContext = createContext<DialogManagerContextProps>({
    openDialog: () => { throw new Error('No DialogManager provider'); },
    minimizeDialog: () => { throw new Error('No DialogManager provider'); },
    maximizeDialog: () => { throw new Error('No DialogManager provider'); },
    restoreDialog: () => { throw new Error('No DialogManager provider'); },
    focusDialog: () => { throw new Error('No DialogManager provider'); },
    closeDialog: () => { throw new Error('No DialogManager provider'); },
    getDialogState: () => { throw new Error('No DialogManager provider'); },
    getDialogStates: () => { throw new Error('No DialogManager provider'); },
    setDialogState: () => { throw new Error('No DialogManager provider'); }
});

type TDialogWindowState = 'normal' | 'full' | 'minimized';

export interface DialogState
{
    id: string;
    title: string;
    component: ReactElement;
    windowState: TDialogWindowState;
    zIndex: number;
    resolve?: (value?: unknown) => void;
}

export const useDialog = () => useContext(DialogManagerContext);

interface DialogManagerProps
{
    children: ReactNode;
}

const DialogManagerComponent: React.FC<DialogManagerProps> = ({ children }) =>
{
    const dispatch = useDispatch();
    const dialogsRef = useRef<DialogState[]>([]);
    const [dialogs, setDialogs] = useState<DialogState[]>([]);

    dialogsRef.current = dialogs;

    // Update dialog count in Redux store
    useEffect(() =>
    {
        dispatch(setDialogCount(dialogs.length));
    }, [dialogs.length, dispatch]);

    const getDialogState = useCallback((dialogId: string): (DialogState | undefined) =>
    {
        return dialogsRef.current.find(d => d.id === dialogId);
    }, []);

    const getDialogStates = useCallback((): DialogState[] =>
    {
        return dialogsRef.current;
    }, []);

    const getNextZIndex = useCallback(() =>
    {
        return dialogsRef.current.reduce((max, dialog) => Math.max(max, dialog.zIndex), 1301) + 1;
    }, []);

    const setFocus = useCallback((dialogId: string) =>
    {
        setDialogs(currentDialogs =>
        {
            if (!currentDialogs.length) return currentDialogs;
            //Find highest zindex
            const actualFocus = currentDialogs.reduce((prev, curr) => curr.zIndex > prev.zIndex ? curr : prev, currentDialogs[0]);
            if (actualFocus?.id === dialogId) return currentDialogs
            const maxZIndex = currentDialogs.reduce((max, dialog) => Math.max(max, dialog.zIndex), 1301);
            return currentDialogs.map(dialog =>
            {
                if (dialog.id === dialogId)
                {
                    return { ...dialog, zIndex: maxZIndex };
                } else
                {
                    return { ...dialog, zIndex: dialog.windowState !== 'minimized' ? Math.max(1302, dialog.zIndex - 1) : 0 };
                }
            });
        });
    }, []);

    const closeDialog = useCallback((dialogId: string) =>
    {
        setDialogs(currentDialogs =>
        {
            return currentDialogs.filter(dialog => dialog.id !== dialogId);
        });
    }, []);

    const openDialog = useCallback((dialog: ReactElement<any>, initialState: TDialogWindowState = 'normal') =>
    {
        const id = uuidv4();
        const existingResolve = dialog.props.resolve;
        const highestZIndex = getNextZIndex();

        const newDialogState: DialogState = {
            id,
            zIndex: highestZIndex,
            title: "Loading",
            component: React.cloneElement(dialog, {
                id,
                resolve: (value: unknown) =>
                {
                    existingResolve && existingResolve(value);
                    closeDialog(id);
                },
            }),
            windowState: initialState,
        };
        setDialogs(currentDialogs => [...currentDialogs, newDialogState]);
    }, [getNextZIndex, closeDialog]);

    const minimizeDialog = useCallback((dialogId: string) =>
    {
        setDialogs(currentDialogs =>
        {
            return currentDialogs.map(dialog => dialog.id === dialogId ? { ...dialog, windowState: 'minimized', zIndex: 0 } : dialog);
        });
    }, []);

    const maximizeDialog = useCallback((dialogId: string) =>
    {
        const highestZIndex = getNextZIndex();
        setDialogs(currentDialogs =>
        {
            return currentDialogs.map(dialog => dialog.id === dialogId ? {
                ...dialog,
                windowState: 'full',
                zIndex: highestZIndex,
            } : dialog);
        });
    }, [getNextZIndex]);

    const restoreDialog = useCallback((dialogId: string) =>
    {
        const highestZIndex = getNextZIndex();
        setDialogs(currentDialogs =>
        {
            return currentDialogs.map(dialog => dialog.id === dialogId ? {
                ...dialog,
                windowState: 'normal',
                zIndex: highestZIndex,
            } : dialog);
        });
    }, [getNextZIndex]);

    const focusDialog = useCallback((dialogId: string) =>
    {
        setFocus(dialogId);
    }, [setFocus]);

    const setDialogState = useCallback((id: string, state: DialogUpdateState) =>
    {
        setDialogs(currentDialogs =>
        {
            return currentDialogs.map(dialog =>
                dialog.id === id ? { ...dialog, ...state } : dialog
            );
        });
    }, []);

    const visibleDialogs = useMemo(() => dialogs.map(dialogState => React.cloneElement(dialogState.component, {
        key: dialogState.id,
        dialogStateProps: {
            title: dialogState.title,
            key: dialogState.id,
            id: dialogState.id,
            windowState: dialogState.windowState,
        }
    })), [dialogs]);

    return (
        <DialogManagerContext.Provider value={{
            openDialog,
            minimizeDialog,
            maximizeDialog,
            restoreDialog,
            focusDialog,
            closeDialog,
            getDialogState,
            getDialogStates,
            setDialogState,
        }}>
            {children}
            {visibleDialogs}
        </DialogManagerContext.Provider>
    );
};

export const DialogManager = React.memo(DialogManagerComponent);
