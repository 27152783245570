import React from 'react';
import { OU } from '@src/Objects/Company';
import './OUSelect.css';

interface OUSelectProps
{
    OUs: OU[]
    selectedOU: number
    setSelectedOU: (val: OU) => void
}

function OUSelect(props: OUSelectProps)
{
    const OUs = props.OUs;
    OUs.sort((a, b) => a.title.localeCompare(b.title))
    return (
        <div className="oUSelect">
            <select
                value={props.selectedOU}
                onChange={(e) =>
                {
                    const ouId = parseInt(e.target.value);

                    props.setSelectedOU(props.OUs.find(o => o.id === ouId) as OU)
                }}
            >
                {props.OUs.map(ou => <option
                    key={ou.id}
                    value={ou.id}
                >
                    {ou.title}
                </option>)}
            </select>
        </div>
    );
}

export default OUSelect;


