import { IOU, ILevel, IStyleDTO } from "@sharedInterfaces/ICompanySettings";
import IWhoIAm, { ISmallCertificate, ISmallCompetence, ISmallSkill, ISmallEmployee, ISmallRole } from "@sharedInterfaces/IWhoIAm";
import { IPermissions } from "@sharedInterfaces/IPermissions";
import IEmployeeSettingsDTO from "@sharedInterfaces/IEmployeeSettings";
import { TEntityVersions } from "@sharedInterfaces/ICompany";
import { ELanguage } from "@sharedInterfaces/Language/languageHelper";

import IUser from "../interfaces/IUser";

import { SkillCategory } from "./SkillCategory";
import Employee from "./Employee";

export default class WhoIAm implements IWhoIAm
{
    companyTitle: string;
    user: IUser;
    employee: Employee;
    smallCompetences: ISmallCompetence[];
    smallSkills: ISmallSkill[];
    smallCertificates: ISmallCertificate[];
    skillCategories: SkillCategory[];
    smallEmployees: ISmallEmployee[];
    smallRoles: ISmallRole[];

    style: IStyleDTO;
    OUs: IOU[];
    level: ILevel[];
    employeeSettings: IEmployeeSettingsDTO;
    permissions: IPermissions;
    unknownSkillCount: number;
    versions: TEntityVersions;
    language: ELanguage | null;
    constructor(obj: IWhoIAm)
    {
        this.user = obj.user;
        this.employee = new Employee(obj.employee);
        this.smallSkills = obj.smallSkills//.map(i => ({ ...i, id: parseInt(<string><unknown>i.id) }));
        this.smallCertificates = obj.smallCertificates;
        this.skillCategories = obj.skillCategories.map(e => new SkillCategory({ ...e, employees: [] }));
        this.smallEmployees = obj.smallEmployees;
        this.smallRoles = obj.smallRoles;
        this.style = obj.style;
        this.companyTitle = obj.companyTitle;
        this.OUs = obj.OUs;
        this.level = obj.level;
        this.employeeSettings = obj.employeeSettings
        this.permissions = obj.permissions;
        this.smallCompetences = obj.smallCompetences;
        this.unknownSkillCount = obj.unknownSkillCount;
        this.language = obj.language
        this.versions = obj.versions;


    }

}