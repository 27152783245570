import React, { useContext } from 'react';
import './LevelDefinitionsDialog.css';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import EditLevelList from '../../Settings/EditLevelList/EditLevelList';


interface LevelDefinitionsDialogProps
{
    id: string
    resolve: () => void;
}

/**
 * Renders the LevelDefinitionsDialog component.
 * 
 * @param {() => void} close.close - The function to close the dialog.
 * 
 * @returns {JSX.Element} The rendered LevelDefinitionsDialog component.
 */
export default function LevelDefinitionsDialog({ id, resolve, }: LevelDefinitionsDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const levelDefinitions = useSelector((state: AppState) => state.levelDefinition);

    const onClose = () =>
    {
        resolve()
    }

    return (
        <React.Fragment>
            <Dialog
                id={id}
                title={langStrings.levelDefinitions}
                onClose={onClose}
                footer={
                    <Button onClick={onClose} text={langStrings.oftenUsed.close} size={'normal'} icon={<Close />} />
                }
            >
                <EditLevelList readOnly level={levelDefinitions} setCompany={() =>
                {

                }} />
            </Dialog>
        </React.Fragment>
    );
}

export function useLevelDefinitionsDialog()
{
    const { openDialog } = useContext(DialogManagerContext);

    const showLevelDefinitionsDialog = () =>
    {
        return new Promise<void>((resolve) =>
        {
            const dialog = (
                <LevelDefinitionsDialog
                    id="LevelDefinitionsDialog"
                    resolve={() => resolve()}
                />
            );
            openDialog(dialog);
        });
    };

    return showLevelDefinitionsDialog;
}