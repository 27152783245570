
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import './BusinessCard.css';
import Business from '@src/Objects/Business';

import ExpandableText from '../ExpandableText/ExpandableText';
import FormatedTextInput from '../formsControls/inputs/FormatedTextInput/FormatedTextInput';
import CardBox from '../CardBox/CardBox';
import { DialogManagerContext } from '../../sharedReact/Dialog/DialogManager';
import { BusinessDialog } from '../Dialogs/BusinessDialog/BusinessDialog';

interface BusinessCardProps
{
    business: Business
    selected?: boolean
    onSelectChange?: (state: boolean) => void
}

/**
 * Renders a BusinessBox component.
 *
 * @param {BusinessCardProps} props - The properties for the BusinessBox component.
 * @returns {JSX.Element} The rendered BusinessBox component.
 */
function BusinessCard(props: BusinessCardProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).businesses;
    const navigate = useNavigate();
    const business = props.business;
    return (
        <CardBox
            menu={
                <div>
                    <MenuItem
                        onClick={() =>
                        {
                            return openDialog(
                                <BusinessDialog
                                    id="BusinessDialog"
                                    business={business}
                                />);
                        }}
                    >
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText>{langStrings.edit}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                        {
                            business.delete()
                        }}
                    >
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>{langStrings.delete}</ListItemText>
                    </MenuItem>
                </div>
            }
            onSelectChange={props.onSelectChange}
            selected={props.selected}
            title={business.title}
            className={'businessBox'}
            onClick={() =>
            {
                navigate(entityTypeToLink(business.id, EEntityType.BUSINESS))
            }}
        >
            <ExpandableText>
                <>
                    {langStrings.description + ": "}
                    <FormatedTextInput value={business.description} readonly={true} />
                </>
            </ExpandableText>
        </CardBox>
    );
}

export default BusinessCard;
