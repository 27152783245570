import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Loop, PublishedWithChanges, Share } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICompetenceLevel } from '@sharedInterfaces/ICompetence';
import { AppState } from '@store/store';
import EmployeeOverlay from '@src/Components/Overlays/EmployeeOverlay/EmployeeOverlay';
import { useQuestionEmployeeSkillDialog } from '@src/Components/Dialogs/QuestionEmployeeSkill/QuestionEmployeeSkill';
import { ShareDialog } from '@src/Components/Buttons/ShareButton/ShareButton';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import Item from '../Item/Item';
import ItemBadge from '../../Components/ItemBadge/ItemBadge';
import { IItemAction } from '../Item/ItemAction';

interface EmployeeItemProps
{
    title: string;
    id?: number;
    level?: number;
    count?: number;
    match?: number;
    active?: { activeUntil?: Date }
    disableOverlay?: boolean
    percent?: number;
    skillId?: number;
    onClick?: string | (() => void)
    competenceLevels?: ICompetenceLevel[]
}

/**
 * Renders an employee item.
 *
 * @param {EmployeeItemProps} title - The title of the employee item.
 * @param {EmployeeItemProps} id - The id of the employee item.
 * @param {EmployeeItemProps} level - The level of the employee item.
 * @param {EmployeeItemProps} count - The count of the employee item.
 * @param {EmployeeItemProps} disableOverlay - Indicates if the overlay is disabled.
 * @param {EmployeeItemProps} onClick - The click event handler for the employee item.
 * @param {EmployeeItemProps} percent - The percent of the employee item.
 * @param {EmployeeItemProps} match - The match of the employee item.
 *
 * @returns {JSX.Element} The rendered employee item.
 */
function EmployeeItem({ title, id, level, count, disableOverlay, onClick, percent, match, competenceLevels, active, skillId }: EmployeeItemProps)
{
    const questionEmployeeSkillDialog = useQuestionEmployeeSkillDialog();
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employees;
    const langStringsSkill = getLanguageTexts(lang).skills;
    const allEmployees = useSelector((state: AppState) => state.company.allEmployees);

    const employee = useSelector((state: AppState) => state.employees.find(e => e.id === id));

    const itsMe = useSelector((state: AppState) => state.employee.id) === id;
    const [updating, setUpdating] = React.useState("");


    const thisAllEmployee = allEmployees.find(e => e.id === id);
    const availabilityObj = thisAllEmployee?.availability;
    const availabilityText = (!availabilityObj ||
        (
            availabilityObj.availability === 0 &&
            availabilityObj.workDayCount === 0
        )
    ) ? 'N/A' : Math.round(availabilityObj.availability) + '%';
    let titleLevel = '';
    let realPercent = percent;
    let newTitle = title
    if (level !== undefined)
    {
        if (competenceLevels)
        {
            if (level === 0)
            {
                titleLevel = langStrings.inPlaning;
                realPercent = 0;
                newTitle = `${title} - ${langStrings.inPlaningShort}`;
            } else
            {
                const levelTitle = competenceLevels[level - 1]?.title;
                titleLevel = `${langStrings.oftenUsed.level}: ${levelTitle}`;
                realPercent = level / competenceLevels.length * 100;
                newTitle = `${title} - ${levelTitle}`;
            }

        } else
        {
            const levelDefinitions = useSelector((state: AppState) => state.levelDefinition);
            titleLevel = `${langStrings.oftenUsed.level}: ${levelDefinitions[level ? level - 1 : 0]?.title}`
            realPercent = level / levelDefinitions.length * 100;
        }
    }
    if (match !== undefined)
    {
        realPercent = match;
    }
    let realActive: undefined | boolean = undefined;
    if (active)
    {
        realActive = true;
        if (active?.activeUntil?.getTime)
        {
            const now = new Date();
            const daysDifference = (active.activeUntil.getTime() - now.getTime()) / (24 * 60 * 60 * 1000); // Unterschied in Tagen
            if (daysDifference <= 0)
            {
                realActive = false;
                realPercent = 0;
            } else if (daysDifference >= 365)
            {
                realPercent = 75;
            } else
            {
                realPercent = 20 + 55 * (daysDifference / 365);
            }
        }

    }

    const laodingIcon = <Loop fontSize='small' />;
    const actions: IItemAction[] = []

    if (id && skillId !== undefined && level)
    {
        if (!itsMe)
        {
            actions.push({
                id: 'toQuestion',
                icon: updating === 'toQuestion' ? laodingIcon : <PublishedWithChanges fontSize='small' />,
                text: langStringsSkill.questionSkillTitle,
                helper: langStringsSkill.questionSkillHelper,
                onClick: () =>
                {
                    setUpdating('toQuestion');
                    questionEmployeeSkillDialog(id, skillId, level)
                        .finally(() =>
                        {
                            setUpdating('');
                        });
                }
            });
        }
        if (employee)
        {
            actions.push({
                id: 'share',
                icon: <Share fontSize='small' />,
                text: langStrings.oftenUsed.share,
                helper: langStrings.oftenUsed.share,
                onClick: () =>
                {
                    openDialog(
                        <ShareDialog
                            id={'ShareDialog'}
                            entity={employee}
                            resolve={() => { }}
                        />
                    );
                }
            })
        }
    }






    return <Item
        onClick={onClick}
        percent={realPercent}
        // order={level ? level * -1 : undefined}
        actions={actions}
        leftContent={
            <>
                {
                    <ItemBadge value={availabilityText} size='small' title={langStrings.oftenUsed.availabilityNext28Days} />
                }
            </>
        }
        rightContent={
            <>
                {
                    level !== undefined && <ItemBadge value={level} title={titleLevel} />
                }
                {
                    count !== undefined && <ItemBadge value={count} title={langStrings.count} />
                }
                {
                    match !== undefined && <ItemBadge value={`${match}%`} title={langStrings.oftenUsed.similarity} />
                }
                {
                    realActive !== undefined && <ItemBadge
                        value={
                            realActive ?
                                langStrings.oftenUsed.active :
                                langStrings.oftenUsed.inactive
                        } title={langStrings.oftenUsed.active + (active?.activeUntil?.toLocaleDateString ? `, ${langStrings.activeUntil}: ${active.activeUntil.toLocaleDateString()}` : '')}
                    />
                }
            </>
        }
    >
        {!id ? newTitle :
            <EmployeeOverlay key={id} disabled={disableOverlay} employeeId={id}>
                {newTitle}
            </EmployeeOverlay>
        }
    </Item>
}
export default EmployeeItem;