import IEmployeeSettingsDTO, { IEmployeeSettingsCompetenceSettings, IEmployeeSettingsCompetenceSettingsWantedCompetence, IEmployeeSettingsGoal, IEmployeeSettingsMailSettings, mailSettingsDefaultSubscriptions } from "@sharedInterfaces/IEmployeeSettings";
import { ELanguage } from "@sharedInterfaces/Language/languageHelper";
import { CONST_DEFAULT_STARTPAGE, IStartPagesTexts } from "@sharedInterfaces/Language/languageTexts/startPages";

export interface employeeSettingState 
{
    startPage: keyof IStartPagesTexts;
    hiddenProjects: number[];
    firstExperience: boolean;
    competenceSettings: IEmployeeSettingsCompetenceSettings
    lastForecastDate: Date;
    lastSkillUpdate: Date;
    goals: IEmployeeSettingsGoal[];
    emailSettings: IEmployeeSettingsMailSettings;
    language2: ELanguage | null;
}

export const initialEmployeeSettingsState: employeeSettingState = {
    hiddenProjects: [],
    firstExperience: false,
    competenceSettings: {
        hiddenCompetences: [],
        wantedCompetences: [],
    },
    startPage: CONST_DEFAULT_STARTPAGE,
    lastForecastDate: new Date(2023, 1, 1),
    lastSkillUpdate: new Date(2023, 1, 1),
    goals: [],
    emailSettings: {
        subscribeAll: true,
        subscriptions: mailSettingsDefaultSubscriptions,
    },
    language2: null,
};

export enum EmployeeSettingsActionTypes
{
    SET_EMAIL_SETTINGS = 'SET_EMAIL_SETTINGS',
    SET_HIDDEN_PROJECTS = 'SET_HIDDEN_PROJECTS',
    SET_FIRST_EXPERIENCE = 'SET_FIRST_EXPERIENCE',
    SET_EMPLOYEE_SETTINGS = 'SET_EMPLOYEE_SETTINGS',
    SET_HIDDEN_COMPETENCES = 'SET_HIDDEN_COMPETENCES',
    SET_STARTPAGE = 'SET_STARTPAGE',
    SET_WANTED_COMPETENCES = 'SET_WANTED_COMPETENCES',
    SET_LAST_FORECAST_DATE = 'SET_LAST_FORECAST_DATE',
    SET_LAST_SKILL_UPDATE = 'SET_LAST_SKILL_UPDATE',
    SET_GOALS = 'SET_GOALS',
    SET_LANGUAGE = 'SET_LANGUAGE',
}
interface SetEmailSettingsAction
{
    type: EmployeeSettingsActionTypes.SET_EMAIL_SETTINGS;
    payload: IEmployeeSettingsMailSettings;
}
interface SetHiddenProjectsAction
{
    type: EmployeeSettingsActionTypes.SET_HIDDEN_PROJECTS;
    payload: number[];
}

interface SetFirstExperienceAction
{
    type: EmployeeSettingsActionTypes.SET_FIRST_EXPERIENCE;
    payload: boolean;
}

interface SetEmployeeSettingsAction
{
    type: EmployeeSettingsActionTypes.SET_EMPLOYEE_SETTINGS;
    payload: IEmployeeSettingsDTO;
}

interface SetHiddenCompetencesAction
{
    type: EmployeeSettingsActionTypes.SET_HIDDEN_COMPETENCES;
    payload: number[];
}

interface SetStartpageAction
{
    type: EmployeeSettingsActionTypes.SET_STARTPAGE;
    payload: keyof IStartPagesTexts;
}

interface SetWantedCompetencesAction
{
    type: EmployeeSettingsActionTypes.SET_WANTED_COMPETENCES;
    payload: IEmployeeSettingsCompetenceSettingsWantedCompetence[];
}

interface SetLastForecastDateAction
{
    type: EmployeeSettingsActionTypes.SET_LAST_FORECAST_DATE;
    payload: Date;
}
interface SetLastSkillUpdateAction
{
    type: EmployeeSettingsActionTypes.SET_LAST_SKILL_UPDATE;
    payload: Date;
}

interface SetGoalsAction
{
    type: EmployeeSettingsActionTypes.SET_GOALS;
    payload: IEmployeeSettingsGoal[];
}

interface SetLanguageAction
{
    type: EmployeeSettingsActionTypes.SET_LANGUAGE;
    payload: ELanguage | null;
}

type EmployeeSettingsAction =
    SetHiddenProjectsAction |
    SetFirstExperienceAction |
    SetEmployeeSettingsAction |
    SetHiddenCompetencesAction |
    SetStartpageAction |
    SetWantedCompetencesAction |
    SetLastForecastDateAction |
    SetGoalsAction |
    SetEmailSettingsAction |
    SetLastSkillUpdateAction |
    SetLanguageAction;

export const employeeSettingsReducer = (
    state = initialEmployeeSettingsState,
    action: EmployeeSettingsAction
): employeeSettingState =>
{
    switch (action.type)
    {
        case EmployeeSettingsActionTypes.SET_HIDDEN_PROJECTS: {
            return {
                ...state,
                hiddenProjects: action.payload,
            };
        }
        case EmployeeSettingsActionTypes.SET_FIRST_EXPERIENCE: {
            return {
                ...state,
                firstExperience: action.payload,
            };
        }
        case EmployeeSettingsActionTypes.SET_EMPLOYEE_SETTINGS: {
            return {
                ...action.payload,
                goals: prepareGoal(action.payload.goals),
                competenceSettings: {
                    ...action.payload.competenceSettings,
                    wantedCompetences: prepareGoal(action.payload.competenceSettings.wantedCompetences),
                }
            };
        }
        case EmployeeSettingsActionTypes.SET_HIDDEN_COMPETENCES: {
            return {
                ...state,
                competenceSettings: {
                    ...state.competenceSettings,
                    hiddenCompetences: action.payload,
                },
            };
        }
        case EmployeeSettingsActionTypes.SET_STARTPAGE: {
            return {
                ...state,
                startPage: action.payload,
            };
        }
        case EmployeeSettingsActionTypes.SET_WANTED_COMPETENCES: {
            return {
                ...state,
                competenceSettings: {
                    ...state.competenceSettings,
                    wantedCompetences: prepareGoal(action.payload),
                },
            };
        }
        case EmployeeSettingsActionTypes.SET_LAST_FORECAST_DATE: {
            return {
                ...state,
                lastForecastDate: action.payload,
            };
        }
        case EmployeeSettingsActionTypes.SET_LAST_SKILL_UPDATE: {
            return {
                ...state,
                lastSkillUpdate: action.payload,
            };
        }
        case EmployeeSettingsActionTypes.SET_GOALS: {
            return {
                ...state,
                goals: prepareGoal(action.payload),
            };
        }
        case EmployeeSettingsActionTypes.SET_EMAIL_SETTINGS: {
            return {
                ...state,
                emailSettings: action.payload,
            };
        }
        case EmployeeSettingsActionTypes.SET_LANGUAGE: {
            return {
                ...state,
                language2: action.payload,
            };
        }
        default:
            return state;
    }
};


/**
 * Prepares the wanted competences.
 *
 * @param wantedCompetences - The array of wanted competences.
 * @returns An array of objects representing the prepared competences.
 */
export function prepareGoal<T extends { untilDate: Date, createdAt: Date }>(wantedCompetences: T[])
{
    const newWantedCompetences = wantedCompetences.map(wc => ({
        ...wc,
        untilDate: new Date(wc.untilDate),
        createdAt: new Date(wc.createdAt),
    }))
    newWantedCompetences.sort((a, b) => a.untilDate.getTime() - b.untilDate.getTime());
    return newWantedCompetences;
}


// Action Creator for setting hidden projects
export const setHiddenProjects = (hiddenProjects: number[]): SetHiddenProjectsAction => ({
    type: EmployeeSettingsActionTypes.SET_HIDDEN_PROJECTS,
    payload: hiddenProjects,
});

// Action Creator for setting the first experience
export const setFirstExperience = (firstExperience: boolean): SetFirstExperienceAction => ({
    type: EmployeeSettingsActionTypes.SET_FIRST_EXPERIENCE,
    payload: firstExperience,
});

// Action Creator for setting employee settings
export const setEmployeeSettings = (settings: IEmployeeSettingsDTO): SetEmployeeSettingsAction => ({
    type: EmployeeSettingsActionTypes.SET_EMPLOYEE_SETTINGS,
    payload: settings,
});

// Action Creator for setting hidden competences
export const setHiddenCompetences = (hiddenCompetences: number[]): SetHiddenCompetencesAction => ({
    type: EmployeeSettingsActionTypes.SET_HIDDEN_COMPETENCES,
    payload: hiddenCompetences,
});

// Action Creator for setting the start page
export const setStartpage = (startPage: keyof IStartPagesTexts): SetStartpageAction => ({
    type: EmployeeSettingsActionTypes.SET_STARTPAGE,
    payload: startPage,
});

// Action Creator for setting wanted competences
export const setWantedCompetences = (wantedCompetences: IEmployeeSettingsCompetenceSettingsWantedCompetence[]): SetWantedCompetencesAction => ({
    type: EmployeeSettingsActionTypes.SET_WANTED_COMPETENCES,
    payload: wantedCompetences,
});

// Action Creator for setting the last forecast date
export const setLastForecastDate = (lastForecastDate: Date): SetLastForecastDateAction => ({
    type: EmployeeSettingsActionTypes.SET_LAST_FORECAST_DATE,
    payload: lastForecastDate,
});

// Action Creator for setting the last skill update
export const setLastSkillUpdate = (lastSkillUpdate: Date): SetLastSkillUpdateAction => ({
    type: EmployeeSettingsActionTypes.SET_LAST_SKILL_UPDATE,
    payload: lastSkillUpdate,
});

// Action Creator for setting goals
export const setGoals = (goals: IEmployeeSettingsGoal[]): SetGoalsAction => ({
    type: EmployeeSettingsActionTypes.SET_GOALS,
    payload: goals,
});
// Action Creator for setting email settings
export const setEmailSettings = (emailSettings: IEmployeeSettingsMailSettings): SetEmailSettingsAction => ({
    type: EmployeeSettingsActionTypes.SET_EMAIL_SETTINGS,
    payload: emailSettings,
});

// Action Creator for setting language
export const setLanguage = (language: ELanguage | null): SetLanguageAction => ({
    type: EmployeeSettingsActionTypes.SET_LANGUAGE,
    payload: language,
});