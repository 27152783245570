import { OperationVariables, gql } from "@apollo/client";
import ICompanySettingsDTO from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";


export const entitiesGql = `
entities {
    Skills { 
        Add
        Edit
        Delete
        Retrieve
        RetrieveOwn
        RetrieveSalesData
    }
    Certificates { 
        Add
        Edit
        Delete
        Retrieve
        RetrieveOwn
        RetrieveSalesData
    }
    Competencies { 
        Add
        Edit
        Delete
        Retrieve
        RetrieveOwn
        RetrieveSalesData
    }
    Products { 
        Add
        Edit
        Delete
        Retrieve
        RetrieveOwn
        RetrieveSalesData
    }
    Projects { 
        Add
        Edit
        Delete
        Retrieve
        RetrieveOwn
        RetrieveSalesData
    }
    Companies { 
        Add
        Edit
        Delete
        Retrieve
        RetrieveOwn
        RetrieveSalesData
    }
    SkillCategories { 
        Add
        Edit
        Delete
        Retrieve
        RetrieveOwn
        RetrieveSalesData
    }
    Opportunities { 
        Add
        Edit
        Delete
        Retrieve
        RetrieveOwn
        RetrieveSalesData
    }
    OrgUnits {
        Add
        Edit
        Delete
        Retrieve
        RetrieveOwn
        RetrieveSalesData
    }
    Roles {
        Add
        Edit
        Delete
        Retrieve
        RetrieveOwn
        RetrieveSalesData
    }
}`

export const styleGql = `{
    primary
    onPrimary
    secondary
    onSecondary
}`

export const companySettingsGql = `
 {
    companyId,
    name,
    employees{
        id
        userName
        firstname
        lastname
        gender
        email
        permissions
        {
            admin
        }
        permissionGroup{
            id
            title
        }
    },
    style
    ${styleGql},
    OUs{
        id
        parentOU
        title
        leader
        employees
    },
    level{
        level
        title
        description
        helperText
    },
    permissionGroups{
        id
        title
        ${entitiesGql}
    }
    defaultPermissionGroup{
        id
        title
    }
    allowedMailEndings
    clockodoSync
    {
        sync
        email
    }
    clockodo{
        status
        lastUpdateProjects
        lastUpdateCustomer
        lastUpdateEmployeeAbsence
        lastUpdateEmployeeTimesLastYear
        lastUpdateEmployeeTimesThisYear
    }
    calculations
    {
        forecastMonthCount
        workedMonthCount
    }
    localisation
    {
        country
        subCountry
        language
    }
    industry
    holidaySettings
    {
        source
        holidays
        {
            year
            holidays
            {
                date
                name
                isFullDay
            }
        }
    }
    emailSettings{
        emailTransferOption
        exchangeMailbox
        forecastCycle
        forecastText
        sendCertificateReminder
    }
    apiKeys{
        id
        note
        createdAt
        createdBy
    }
    microsoftSettings{
        tenantId
        clientId
        certificateThumbprint
        certificateStatus
        connectionState
    }
    globalEmailSupport
}`
const q = gql`
query {
    companySettings
    ${companySettingsGql}
},
`;

/**
 * Retrieves the settings of a company.
 * 
 * @param {string} companyId - The ID of the company.
 * @param {(company: Company) => void} callback - The callback function to be executed with the company object.
 * 
 * @returns {Promise<{ companySettings: ICompanySettings }> - A promise that resolves with the company settings.
 */
export default function getCompanySettings(companyId: string, callback?: (company: Company) => void)
{
    const variables: OperationVariables = {};
    return GraphQL.query<{ companySettings: ICompanySettingsDTO }>(q, variables, (obj) =>
    {
        if (!obj) throw new Error("Error Load Company");

        const result = new Company(obj.companySettings);
        callback && callback(result)
        return result
    });
}