import React from 'react';
import { useSelector } from 'react-redux';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import getSkillCategory from '@src/APIs/graphQl/SkillCategory/getSkillCategory';
import SkillCategoryPage from '@src/App/NewLayout/Pages/SkillCategories/SkillCategoryPage/SkillCategoryPage';

import Overlay from '../Overlay/Overlay';
import './SkillCategoryOverlay.css';



interface SkillCategoryOverlayProps
{
    skillCategoryId: number;
    children: React.ReactNode;
    disabled?: boolean;
}

/**
 * SkillCategoryOverlay
 *
 * @param {SkillCategoryOverlayProps} props - The props for the SkillCategoryOverlay component.
 * @returns {JSX.Element} - The rendered SkillCategoryOverlay component.
 */
function SkillCategoryOverlay(props: SkillCategoryOverlayProps)
{
    const entity = useSelector((state: AppState) => state.skillCategories.find(a => a.id === props.skillCategoryId));
    const permissions = useSelector((state: AppState) => state.permissions);
    const isAllowed = checkPermissions('SkillCategories', 'Retrieve', permissions) || checkPermissions('SkillCategories', 'RetrieveOwn', permissions);
    const disabled = !isAllowed || props.disabled;

    return (
        <Overlay
            disabled={disabled}
            triggerItem={props.children}
            loaded={entity !== null}
            onFirstOpen={() =>
            {
                getSkillCategory(props.skillCategoryId);
            }}>
            {entity && <SkillCategoryPage
                entity={entity}
                inDialog={true}

            />}
        </Overlay>
    );
}

export default SkillCategoryOverlay;
