import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Checkbox, FormControlLabel, FormGroup, Paper, Typography } from '@mui/material';
import InnerLayout from '@sharedReact/Layouts/InnerLayout/InnerLayout';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeSettingsMailSettingsSubscription } from '@sharedInterfaces/IEmployeeSettings';
import { AppState } from '@store/store';
import setEmployeeEmailSettings from '@src/APIs/graphQl/Employee/setEmployeeEmailSettings';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';


interface SubscriptionType
{
    id: keyof IEmployeeSettingsMailSettingsSubscription;
    label: string;
    subscribed: boolean;
}

function EmailSettingsPage()
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).emailTexts;

    const emailSettings = useSelector((state: AppState) => state.employeeSettings.emailSettings);

    const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([]);

    const [allEmails, setAllEmails] = useState(emailSettings.subscribeAll);
    const [changed, setChanged] = useState(false);
    const [updating, setUpdating] = useState(false);

    useEffect(() =>
    {
        if (!emailSettings) return;
        setAllEmails(emailSettings.subscribeAll);
        if (!emailSettings.subscriptions) return;
        const subscriptions = emailSettings.subscriptions;
        setSubscriptions(Object.entries(subscriptions).map(([key, value]: [string, boolean]) => ({
            id: key as keyof IEmployeeSettingsMailSettingsSubscription,
            label: langStrings[key as keyof IEmployeeSettingsMailSettingsSubscription],
            subscribed: value
            // checked: subscriptions[key as keyof IEmployeeSettingsMailSettingsSubscription]
        })
        ));
    }, [emailSettings, emailSettings.subscribeAll, emailSettings?.subscriptions])

    const handleAllEmailsChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        setChanged(true);
        setAllEmails(event.target.checked);
    };

    const handleSubscriptionChange = (id: keyof IEmployeeSettingsMailSettingsSubscription) => (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        setChanged(true);
        setSubscriptions(subscriptions.map(sub => sub.id === id ? { ...sub, subscribed: event.target.checked } : sub));
    };
    const handleConfirm = () =>
    {
        setUpdating(true);

        setEmployeeEmailSettings(allEmails, subscriptions.map((s) => ({ id: s.id, subscribed: s.subscribed })))
            .catch(showErrorDialog)
            .finally(() =>
            {
                setUpdating(false);
                setChanged(false);
            })
    }
    return (
        <InnerLayout title={langStrings.emailSettingsTitle}>
            <div style={{ userSelect: 'none' }}>
                <Typography variant="h5" gutterBottom>
                    {langStrings.emailSettingsDescription}
                </Typography>
                <Typography paragraph>
                    {langStrings.emailSettingsInstructions}
                </Typography>


                <Paper variant="outlined" square style={{ padding: '20px', marginBottom: '20px' }}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allEmails}
                                    onChange={handleAllEmailsChange}
                                    color="primary"
                                />
                            }
                            label={langStrings.receiveEmails}
                        />
                        <FormGroup style={{ marginLeft: '20px' }}>
                            {subscriptions.map(subscription => (
                                <FormControlLabel
                                    key={subscription.id}
                                    control={
                                        <Checkbox
                                            checked={subscription.subscribed && allEmails}
                                            onChange={handleSubscriptionChange(subscription.id)}
                                            disabled={!allEmails}
                                            color="primary"
                                        />
                                    }
                                    label={subscription.label}
                                />
                            ))}
                        </FormGroup>
                        {changed && <Button
                            style={{ maxWidth: 300 }}
                            variant="contained"
                            color="primary"
                            disabled={updating}
                            onClick={handleConfirm}
                        >
                            {langStrings.oftenUsed.confirm}
                        </Button>
                        }
                    </FormGroup>
                </Paper>
            </div>
        </InnerLayout>
    );
}

export default EmailSettingsPage;
