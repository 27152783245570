

import React from 'react';
import { useSelector } from 'react-redux';
import { Select, MenuItem } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICountriesTexts } from '@sharedInterfaces/Language/languageTexts/countries';
import { AppState } from '@store/store';


interface CountrySelectProps
{
    country: keyof ICountriesTexts;
    setCountry: (val: keyof ICountriesTexts) => void;
}

/**
 * Renders a Material-UI select input for selecting a country.
 *
 * @param {CountrySelectProps} country - The currently selected country.
 * @param {function} setCountry - A function to set the selected country.
 * @returns {JSX.Element} - The Material-UI select input for selecting a country.
 */
function CountrySelect({ country, setCountry }: CountrySelectProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).countries;
    const countries: [string, string][] = Object.entries(langStrings);
    countries.sort((a, b) => a[1].localeCompare(b[1]));

    return (
        <Select
            labelId="country-select-label"
            value={country}
            onChange={(e) =>
            {
                setCountry(e.target.value as keyof ICountriesTexts);
            }}
            // style={{ zIndex: 1400 }}
            MenuProps={{
                style: { zIndex: 1400 }
            }}
        >
            {(country as string === "" || !country) && <MenuItem value=""><em>-</em></MenuItem>}
            {countries.map(([code, name]) => (
                <MenuItem key={code} value={code}>
                    {name}
                </MenuItem>
            ))}
        </Select>
    );
}

export default CountrySelect;
