import { DialogManagerContext, DialogState, } from '@sharedReact/Dialog/DialogManager';
import React, { useContext, } from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';

import styles from './DialogBar.module.css';

export function DialogBar()
{
    const { getDialogStates, minimizeDialog, restoreDialog, focusDialog, closeDialog } = useContext(DialogManagerContext)
    const dialogStates = getDialogStates();
    const style = useSelector((state: AppState) => state.company.style)
    const activeDialog: DialogState | null = dialogStates.reduce(
        (old, dialog) =>
            dialog.windowState !== 'minimized' && (!old || dialog.zIndex > old.zIndex)
                ? dialog
                : old
        , null as null | DialogState);

    const onClick = (dialogId: string) => () =>
    {
        const dialogState = dialogStates.find(x => x.id === dialogId);
        if (!dialogState) return;
        if (dialogState.windowState === 'minimized')
            restoreDialog(dialogId);
        else
        {
            if (!activeDialog) return;
            if (activeDialog.id === dialogId)
            {
                minimizeDialog(dialogId);

            } else
            {
                focusDialog(dialogId);
            }
        }
    };

    return <div
        className={styles.dialogBar}
    >
        {dialogStates.map(x =>
            <div
                key={x.id}
                className={[
                    styles.dialog,
                    activeDialog?.id === x.id ? styles.activeDialog : undefined]
                    .filter(d => d)
                    .join(' ')
                }
                onClick={onClick(x.id)}
            >
                {/* {x.id} */}
                {/* {x.zIndex} */}
                {x.title}
                <IconButton
                    size='small'
                    style={{ color: activeDialog?.id === x.id ? style.onPrimary : '#000' }}
                    onClick={closeDialog.bind(null, x.id)}
                >
                    <Close fontSize='small' />
                </IconButton>
            </div>)}
    </div>;
}
