
export interface IMessage
{
    role: 'user' | 'system' | 'assistant';
    content: string;
}
/**
 * Enum for specifying the GPT model to use.
 */
export enum EModel
{
    gpt_3_5_turbo = 'gpt-3.5-turbo',
    gpt_4_turbo = 'gpt-4-turbo',
    gpt_4o = 'gpt-4o',
}


/**
 * Returns the maximum number of tokens in the given context.
 * 
 * @param {EModel} model - The model object.
 * @returns {number} The maximum number of tokens.
 */
export function getMaxTokensContext(model: EModel): number
{
    switch (model)
    {
        case EModel.gpt_4_turbo:
            return 128000;
        case EModel.gpt_3_5_turbo:
            return 16385;
        case EModel.gpt_4o:
            return 128000;
        default:
            throw new Error('Unknown model');
    }
}


/**
 * Retrieves the maximum tokens output for the given model.
 *
 * @param {EModel} model - The model to retrieve the maximum tokens output for.
 * 
 * @returns {number} The maximum tokens output.
 */
export function getMaxTokensOutput(model: EModel): number
{
    switch (model)
    {
        case EModel.gpt_4_turbo:
            return 4096;
        case EModel.gpt_3_5_turbo:
            return 4096;
        case EModel.gpt_4o:
            return 128000;
        default:
            throw new Error('Unknown model');
    }
}


type TokenCost = {
    input: number;
    output: number;
};

/**
 * Get the cost per 1K tokens for a given model.
 * @param model - The model enum value.
 * @returns Cost per 1K tokens for input and output.
 */
export function getTokenCost(model: EModel): TokenCost
{
    switch (model)
    {
        case EModel.gpt_4_turbo:
            return { input: 0.01, output: 0.03 };
        case EModel.gpt_3_5_turbo:
            return { input: 0.0005, output: 0.0015 };
        case EModel.gpt_4o:
            return { input: 0.005, output: 0.015 };
        default:
            throw new Error('Unknown model');
    }
}

/**
 * Calculates the number of tokens in the given text.
 * 
 * @param {string} text - The text to calculate the tokens for.
 * @returns {number} The number of tokens in the given text.
 */
export function calculateTokens(text: string)
{
    return Math.ceil(text.length / 3)
}


/**
 * Calculates the price.
 *
 * @param {EModel} model - The model.
 * @param {number} textTokens - The number of text tokens.
 * @param {number} staticTextTokens - The number of static text tokens.
 * @param {number} calculationTokens - The number of calculation tokens.
 * @returns {number} The calculated price.
 */
export function calculatePrice(model: EModel, textTokens: number, staticTextTokens: number, calculationTokens: number)
{
    const cost = getTokenCost(model);
    return Math.ceil(
        (
            (Math.ceil((textTokens + staticTextTokens) / 1000) * cost.input) + (Math.ceil(calculationTokens / 1000) * cost.output)
        ) * 100
    ) / 100;
}



/**
 * Updates the given model with the provided token counts.
 * 
 * @param {EModel} model - The model to be checked and updated.
 * @param {number} textTokens - The number of text tokens.
 * @param {number} staticTextTokens - The number of static text tokens.
 * @param {number} calculationTokens - The number of calculation tokens.
 * 
 * @returns {EModel} - The updated model.
 */
export function checkAndUpdateModel(model: EModel, textTokens: number, staticTextTokens: number, calculationTokens: number): EModel
{
    const modelMaxTokens = getMaxTokensContext(model);
    const totalTokens = textTokens + staticTextTokens + calculationTokens + 500;

    if (totalTokens > modelMaxTokens)
    {
        // if (model === EModel.gpt_4)
        // {
        //     console.log(`Zu wenig Tokens im Model ${model} upgrate zu GPT_3_5_TURBO_1106`);
        //     model = EModel.gpt_3_5_turbo;
        //     // Uncomment the following lines if you want to upgrade to GPT-4-32K
        //     // console.log(`Zu wenig Tokens im Model ${model} upgrate zu GPT-4-32K`);
        //     // model = EModel.GPT_4_32K;
        // } else
        // {
        console.warn("Zu wenig Tokens, Upgrade nicht möglich, versuche die ausführung dennoch.");
        // }
    }
    return model;
}